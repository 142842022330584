import { Injectable } from '@angular/core';
import { CubejsConfig } from '@cubejs-client/ngx';
import { filter, map, Observable, take } from 'rxjs';
import { isNotNullOrUndefined } from 'src/app/services/utils/filter-typeguard.util';
import { UserSessionService } from '../../services/session/user-session.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthenCubeJsService {
  constructor(public userSessionService: UserSessionService) {}

  public config$: Observable<CubejsConfig> =
    this.userSessionService.authToken$.pipe(
      filter(isNotNullOrUndefined),
      take(1),
      map((authToken) => ({
        token: `${localStorage.getItem(environment.dataTokenTypeNameInSession)} ${authToken}`,
        options: {
          apiUrl: environment.cubeUrlApi,
        },
      }))
    );
}
