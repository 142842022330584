/* tslint:disable */

/**
 * Type rate unit of the charging profile
 */
type ChargePointChargingProfileScheduleRateUnitType =
  'W' |
  'A';
module ChargePointChargingProfileScheduleRateUnitType {
  export const W: ChargePointChargingProfileScheduleRateUnitType = 'W';
  export const A: ChargePointChargingProfileScheduleRateUnitType = 'A';
  export function values(): ChargePointChargingProfileScheduleRateUnitType[] {
    return [
      W,
      A
    ];
  }
}

export { ChargePointChargingProfileScheduleRateUnitType }