import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInCallBackComponent } from './components/logged-in-call-back/logged-in-call-back.component';

const routes: Routes = [
  { path: 'logged-in-callback', component: LoggedInCallBackComponent }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {})
  ],
  exports: [ RouterModule ],
  declarations: []
})
export class CommonBaseRoutingModule { }
