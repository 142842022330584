import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {isQueryPresent, ResultSet} from '@cubejs-client/core';
import {CubejsClient, TChartType} from '@cubejs-client/ngx';
import {BehaviorSubject, combineLatest, merge, Observable, of} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {ActiveElement, ChartData, ChartEvent, ChartOptions, ChartType} from 'chart.js';
import {flattenColumns, getDisplayedColumns} from './utils';
import {CubeJsService} from 'src/app/services/cubeJs.service';
import moment from 'moment';
import {GranularityCubeJs} from 'src/app/enums/cubejs/granularity';
import {TranslateService} from '@ngx-translate/core';
import {environment} from 'src/environments/environment';
import {GraphType} from 'src/app/enums/cubejs/graph-type';
import {ConvertValueModel} from 'src/app/models/cubejs/ConvertValueModel';
import {ConvertValueType} from 'src/app/enums/cubejs/convertValueType.enum';
import {GlobalUtil} from 'src/app/services/utils/global.util';
import {UiLoadingModel} from 'src/app/tinea-components/loading/models/UiLoadingModel';
import {MeasureKeyType} from '../../../enums/cubejs/measure-key.enum.ts';
import {CubeJsChartType} from '../../../enums/cubejs-chart-type';
import {valueOrDefault} from 'chart.js/helpers';
import {TransactionEnergyKeyType} from '../../../enums/cubejs/transaction-energy-key.enum';


export interface GlobalDisponibility {
    value: number,
    measure: MeasureKeyType
}

export function rand(min, max) {
    var _seed = Date.now();
    min = valueOrDefault(min, 0);
    max = valueOrDefault(max, 0);
    _seed = (_seed * 9301 + 49297) % 233280;
    return min + (_seed / 233280) * (max - min);
}

@Component({
    selector: 'app-query-renderer',
    templateUrl: './query-renderer.component.html',
    styleUrls: ['./query-renderer.component.scss']
})
export class QueryRendererComponent implements OnInit {

    @Output() hasError = new EventEmitter<boolean>();

    //event triggered when chart.js emit a click event
    @Output() clicked = new EventEmitter<any>();

    @Output() globalValuesEmitter = new EventEmitter<GlobalDisponibility[]>();
    @Input('cubeQuery')
    cubeQuery$: BehaviorSubject<any>;

    @Input('pivotConfig')
    pivotConfig$: Observable<any>;

    @Input('chartType')
    chartType$: Observable<any>;

    // custom : unit of label : tmp. must be done by backend
    @Input('unit')
    unit$: Observable<any>;

    @Input('graphType')
    graphType$: Observable<GraphType>;

    @Input('convertValue')
    convertValue$: Observable<ConvertValueModel[]>;

    @Input() clickable: boolean;

    //storage of resultSet.tablePivot(pivotConfig)
    pivotedResult: any[] = [];
    globalDispo: GlobalDisponibility[] = [];
    //contain the cursor:pointer css class if chart is hovered and clickable
    itemHovered: string = '';

    chartType: any = null;
    isQueryPresent = false;
    error: string | null = null;

    displayedColumns: string[] = [];
    tableData: any[] = [];
    columnTitles: string[] = [];
    chartData: any[] = [];
    chartCircularData: ChartData;
    chartLabels: any[] = [];
    chartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (event, array) => {
            this.onClickItem(event, array);
        },
        onHover: (event, array) => {
            this.onHoverItem(event, array);
        }
    };
    noFillChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            line: {
                fill: false,
            },
        },
    };
    numericValues: number[] = [];
    loadingModel: UiLoadingModel;
    loading = false;
    noData = false;
    data = {};
// Doughnut
    public doughnutChartLabels: string[] = [
        'Download Sales',
        'In-Store Sales',
        'Mail-Order Sales',
    ];
    public doughnutChartData: ChartData<'doughnut'> = {
        labels: this.doughnutChartLabels,
        datasets: [
            {data: [350, 450, 100]},
        ],
    };
    public doughnutChartType: ChartType = 'doughnut';

    constructor(private cubejsClient: CubejsClient,
                private cubeJsService: CubeJsService,
                private globalUtil: GlobalUtil,
                private translateService: TranslateService) {
    }

    ngOnInit() {
        let onQueryStart;
        let onQueryLoad;
        this.setDefaultLoading();

        try {
            const queryId = window.location.hash.replace(/#\\/, '').split('=')[1];
            const {forQuery} = window.parent.window['__cubejsPlayground'] || {};
            onQueryStart = forQuery(queryId).onQueryStart;
            onQueryLoad = forQuery(queryId).onQueryLoad;
        } catch (_) {
        }

        combineLatest([
            this.cubeQuery$.pipe(
                switchMap((cubeQuery) => {
                    return of(isQueryPresent(cubeQuery || {}));
                })
            ),
            this.cubeQuery$.pipe(
                switchMap((cubeQuery) => {
                    this.error = null;
                    if (!isQueryPresent(cubeQuery || {})) {
                        return of(null);
                    }
                    cubeQuery.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    this.loading = true;
                    this.loadingModel.isLoading = true;

                    if (typeof onQueryStart === 'function') {
                        onQueryStart();
                    }

                    return merge(
                        of(null),
                        this.cubejsClient.load(cubeQuery).pipe(
                            tap(response => {
                                this.hasError.next(false);
                            }),
                            catchError((error) => {
                                this.error = error.toString();
                                console.error(error);
                                this.hasError.next(true);
                                this.loadingModel.isLoading = false;
                                return of(null);
                            }),
                        )
                    );
                })
            ),
            this.pivotConfig$,
            this.chartType$,
            this.unit$,
            this.graphType$,
            this.convertValue$
        ]).subscribe(([isQueryPresentOk, resultSet, pivotConfig, chartType, units, graphType, convertValue]: [
                boolean,
                ResultSet,
                any,
                TChartType,
                string,
                GraphType,
                ConvertValueModel[]
            ]) => {
                this.chartType = chartType;
                this.isQueryPresent = isQueryPresentOk;

                if (resultSet != null) {
                    this.loading = false;
                    this.loadingModel.isLoading = false;
                }

                if (typeof onQueryLoad === 'function') {
                    onQueryLoad({
                        resultSet,
                        error: this.error,
                    });
                }

                if (resultSet) {
                    this.storePivotedResults(resultSet, pivotConfig);
                    if (this.chartType === 'table') {
                        this.updateTableData(resultSet, pivotConfig, convertValue);
                    } else if (this.chartType === 'number') {
                        this.updateNumericData(resultSet);
                    } else {
                        this.updateChartData(resultSet, pivotConfig, graphType, convertValue);
                    }
                }
            }
        );
    }

    numberTest(config) {
        var cfg = config || {};
        var min = valueOrDefault(cfg.min, 0);
        var max = valueOrDefault(cfg.max, 100);
        var from = valueOrDefault(cfg.from, []);
        var count = valueOrDefault(cfg.count, 8);
        var decimals = valueOrDefault(cfg.decimals, 8);
        var continuity = valueOrDefault(cfg.continuity, 1);
        var dfactor = Math.pow(10, decimals) || 0;
        var data = [];
        var i, value;

        for (i = 0; i < count; ++i) {
            value = (from[i] || 0) + rand(min, max);
            if (rand(null, null) <= continuity) {
                data.push(Math.round(dfactor * value) / dfactor);
            } else {
                data.push(null);
            }
        }

        return data;

    }

    setDefaultLoading(): void {
        const model = new UiLoadingModel();
        this.loadingModel = model;
    }

    updateChartData(resultSet, pivotConfig, graphType: GraphType, convertValue: ConvertValueModel[]) {
        this.globalDispo = [];
        let totalValue = 0;
        this.chartData = resultSet.series(pivotConfig).map((item) => {
            // series contains all values
            let dispoValue = 0;
            const formattedChartData = item.series.map((serie) => {
                convertValue?.forEach(cv => {
                    serie.value = this.formatValue(serie.value, cv);
                });
                dispoValue += serie.value;
                return serie.value;
            });
            this.globalDispo.push({
                value: parseFloat((dispoValue / resultSet.backwardCompatibleData[0].length).toFixed(2)),
                measure: item.key.substring(item.key.lastIndexOf('.') + 1)
            });
            const chartDataSets: any = {
                label: graphType === GraphType.CONNECTIVITY_STATION ? item.shortTitle : item.title,
                data: formattedChartData,
                stack: 'a',
            };
            if (this.cubeJsService.hasDefinedColors(graphType)) {
                if (graphType === GraphType.STATION_AVAILABILITY) {
                    chartDataSets.backgroundColor = this.cubeJsService.getColorLabelByGraphType(graphType, item.key);
                } else if (graphType === GraphType.TRANSACTION) {
                    chartDataSets.backgroundColor = this.cubeJsService.getColorLabelByGraphType(graphType, null, item.key);
                } else if (graphType === GraphType.CONNECTIVITY_STATION || graphType === GraphType.CONNECTIVITY_FTP) {
                    chartDataSets.backgroundColor = this.cubeJsService.getColorLabelByGraphType(graphType, null, item.key);
                    chartDataSets.borderColor = chartDataSets.backgroundColor;
                    chartDataSets.pointBackgroundColor = chartDataSets.backgroundColor;
                }

            }
            if (this.cubeJsService.hasDefinedHoverColors(graphType)) {
                if (graphType === GraphType.STATION_AVAILABILITY) {
                    chartDataSets.hoverBackgroundColor = this.cubeJsService.getHoverColorLabelByGraphType(graphType, item.key);
                } else if (graphType === GraphType.TRANSACTION) {
                    chartDataSets.hoverBackgroundColor = this.cubeJsService.getHoverColorLabelByGraphType(graphType, null, item.key);
                } else if (graphType === GraphType.CONNECTIVITY_STATION) {
                    chartDataSets.hoverBackgroundColor = this.cubeJsService.getHoverColorLabelByGraphType(graphType, null, item.key);
                }
            }
            return chartDataSets;
        });
        this.globalDispo.map((value) => totalValue += value.value);
        if (this.globalDispo.length > 0) {
            if (totalValue > 100) {
                this.globalDispo[this.globalDispo.length - 1].value = this.globalDispo[this.globalDispo.length - 1].value - (totalValue - 100.00);
                this.globalDispo[this.globalDispo.length - 1].value = parseFloat((this.globalDispo[this.globalDispo.length - 1].value).toFixed(2));
            } else if (totalValue < 100) {
                this.globalDispo[this.globalDispo.length - 1].value = this.globalDispo[this.globalDispo.length - 1].value + (100 - totalValue);
                this.globalDispo[this.globalDispo.length - 1].value = parseFloat((this.globalDispo[this.globalDispo.length - 1].value).toFixed(2));
            }
        }
        totalValue = 0;
        this.globalValuesEmitter.emit(this.globalDispo);
        this.chartLabels = resultSet.chartPivot(pivotConfig).map((row) => {
            return this.getFormattedXLabel(resultSet, row.x);
        });
        if (this.chartType === CubeJsChartType.DOUGHNUT || this.chartType === CubeJsChartType.PIE) {
            this.setupTransactionDoughnutGraph(graphType);
        } else if (this.chartType === CubeJsChartType.LINE) {
            this.setupConnectivityStationLineGraph(graphType);
        }
        this.noData = this.chartData.length === 0 ? true : false;
    }

    setupTransactionDoughnutGraph(graphType: GraphType) {
        if (this.chartLabels && graphType === GraphType.TRANSACTION) {
            let otherTotal = 0;
            let gncTotal = 0;
            let biogncTotal = 0;
            let newData = [];
            let newLabels = [];
            let transactionViewType = Object.values(TransactionEnergyKeyType).map((item) => item.toLowerCase());
            for (var i = 0; i < this.chartLabels.length; i++) {
                if (!transactionViewType.includes(this.chartLabels[i].toLowerCase())) {
                    if (this.chartLabels[i].toLowerCase() === 'ecognc') {
                        gncTotal += this.chartData[0].data[i];
                    } else if (this.chartLabels[i].toLowerCase() === 'bio gnc') {
                        biogncTotal += this.chartData[0].data[i];
                    } else {
                        otherTotal += this.chartData[0].data[i];
                    }
                } else {
                    if (this.chartLabels[i].toLowerCase() === TransactionEnergyKeyType.GNC) {
                        gncTotal += this.chartData[0].data[i];
                    } else if (this.chartLabels[i].toLowerCase() === TransactionEnergyKeyType.BIOGNC) {
                        biogncTotal += this.chartData[0].data[i];
                    } else {
                        newData.push(this.chartData[0].data[i]);
                        newLabels.push(this.chartLabels[i]);
                    }
                }
            }
            newLabels.push(TransactionEnergyKeyType.GNC.toUpperCase());
            newData.push(gncTotal);
            newLabels.push(TransactionEnergyKeyType.BIOGNC.toUpperCase());
            newData.push(biogncTotal);
            newLabels.push(TransactionEnergyKeyType.OTHER.toUpperCase());
            newData.push(otherTotal);
            this.chartCircularData = {
                labels: newLabels,
                datasets: [
                    {
                        data: newData,
                        backgroundColor: this.cubeJsService.getColorLabelByCircularGraphType(newLabels),
                        hoverBackgroundColor: this.cubeJsService.getColorHoverLabelByCircularGraphType(newLabels)
                    },
                ],
            };
        } else {
            this.chartCircularData = {
                labels: this.chartLabels ?? [],
                datasets: [
                    {
                        data: this.chartData[0]?.data ?? [],
                        backgroundColor: this.cubeJsService.getColorLabelByCircularGraphType(this.chartLabels ?? []),
                        hoverBackgroundColor: this.cubeJsService.getColorHoverLabelByCircularGraphType(this.chartLabels ?? [])
                    },
                ],
            };
        }
    }

    setupConnectivityStationLineGraph(graphType: GraphType) {
        if (graphType === GraphType.CONNECTIVITY_STATION) {
            this.chartData;
        }
    }

    formatValue(data, cv: ConvertValueModel) {
        if (cv.type === ConvertValueType.SECONDS) {
            data = this.convertSecondsToFormat(data);
        } else if (cv.type === ConvertValueType.FLOAT) {
            data = this.convertFloatFormat(data, cv);
        }
        return data;
    }

    updateTableData(resultSet, pivotConfig, convertValue: ConvertValueModel[]) {
        const tableData = resultSet.tablePivot(pivotConfig);
        this.tableData = tableData.map(data => {
            convertValue?.forEach(cv => {
                data[cv.measure] = this.formatValue(data[cv.measure], cv);
            });
            return data;
        });
        this.noData = this.tableData.length === 0 ? true : false;

        this.displayedColumns = getDisplayedColumns(
            resultSet.tableColumns(pivotConfig)
        );
        this.columnTitles = flattenColumns(resultSet.tableColumns(pivotConfig));
    }

    updateNumericData(resultSet) {
        this.numericValues = resultSet
            .seriesNames()
            .map((s) => resultSet.totalRow()[s.key]);
    }

    getFormattedXLabel(resultSet: ResultSet<any>, x: string): string {
        const granularity: GranularityCubeJs = resultSet.query()?.timeDimensions[0]?.granularity as GranularityCubeJs;

        const xDate = moment(x);
        if (xDate.isValid()) {
            return this.getFormattedXLabelDate(granularity, xDate.utc(true));
        } else {
            return x;
        }
    }

    getFormattedXLabelDate(granularity: GranularityCubeJs, utcDate: moment.Moment): string {
        let label = '';
        switch (granularity) {
            case GranularityCubeJs.SECOND:
                label = utcDate.local().locale(this.translateService.currentLang).format(environment.core.datetimeFormat.datetime);
                break;
            case GranularityCubeJs.MINUTE:
                label = utcDate.local().locale(this.translateService.currentLang).format(environment.core.datetimeFormat.dateHourMinute);
                break;
            case GranularityCubeJs.HOUR:
                label = utcDate.locale(this.translateService.currentLang).format(environment.core.datetimeFormat.dateHour);
                break;
            case GranularityCubeJs.DAY:
                label = utcDate.locale(this.translateService.currentLang).format(environment.core.datetimeFormat.dayDate);
                break;
            case GranularityCubeJs.WEEK:
                label = utcDate.format('WW');
                break;
            case GranularityCubeJs.MONTH:
                label = utcDate.locale(this.translateService.currentLang).format(environment.core.datetimeFormat.shortMonth);
                break;
            case GranularityCubeJs.QUARTER:
                console.warn('pas traité !');
                break;
            case GranularityCubeJs.YEAR:
                label = utcDate.format(environment.core.datetimeFormat.year);
                break;
            default:
                console.error('getFormattedXLabelDate: unknown');
                break;
        }

        return label;
    }

    convertSecondsToFormat(secondsInput: number) {
        const seconds = Number(secondsInput);
        const d = Math.floor(seconds / (3600 * 24));
        const h = Math.floor(seconds % (3600 * 24) / 3600);
        const m = Math.floor(seconds % 3600 / 60);
        const s = Math.floor(seconds % 60);

        const sString = s < 10 ? '0' + s : s;
        const mString = m < 10 ? '0' + m : m;
        const hString = h < 10 ? '0' + h : h;

        const dDisplay = d > 0 ? (d + 'j ') : '';
        const hDisplay = h > 0 ? (hString + ':') : '00:';
        const mDisplay = m > 0 ? (mString + ':') : '00:';
        const sDisplay = s > 0 ? (sString + '') : '00';

        return dDisplay + hDisplay + mDisplay + sDisplay;

    }

    convertFloatFormat(floatInput: string, convertValue: ConvertValueModel) {
        return this.globalUtil.convertNumberWithDecimal(floatInput, convertValue.format as number);
    }

    storePivotedResults(resultSet: ResultSet, pivotConfig: any) {
        this.pivotedResult = resultSet.tablePivot(pivotConfig);
    }

    //emit the data related to the graph item clicked
    onClickItem(event: ChartEvent, array: ActiveElement[]) {
        const i = array.length > 0 ? array[0].index : null;
        if (i !== null && this.storePivotedResults.length > 0) {
            this.clicked.emit(this.pivotedResult[i]);
        }
    }

    //set cursor-pointer if the chart is define as clickable and a chart item is hovered
    onHoverItem(event: ChartEvent, array: ActiveElement[]) {
        if (this.clickable) {
            this.itemHovered = array.length > 0 ? 'item-hovered' : '';
        }
    }
}
