/* tslint:disable */

/**
 * The charge point state
 */
type ChargePointState =
  'AVAILABLE' |
  'PARTIALLY_AVAILABLE' |
  'UNAVAILABLE' |
  'OUT_OF_ORDER' |
  'IN_MAINTENANCE' |
  'UNKNOWN';
module ChargePointState {
  export const AVAILABLE: ChargePointState = 'AVAILABLE';
  export const PARTIALLY_AVAILABLE: ChargePointState = 'PARTIALLY_AVAILABLE';
  export const UNAVAILABLE: ChargePointState = 'UNAVAILABLE';
  export const OUT_OF_ORDER: ChargePointState = 'OUT_OF_ORDER';
  export const IN_MAINTENANCE: ChargePointState = 'IN_MAINTENANCE';
  export const UNKNOWN: ChargePointState = 'UNKNOWN';
  export function values(): ChargePointState[] {
    return [
      AVAILABLE,
      PARTIALLY_AVAILABLE,
      UNAVAILABLE,
      OUT_OF_ORDER,
      IN_MAINTENANCE,
      UNKNOWN
    ];
  }
}

export { ChargePointState }