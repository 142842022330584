/* tslint:disable */

/**
 * field to filter for diffusion list search:
 *   * NAME : the diffusion list's name
 *   * TYPE : the diffusion list's type
 *   * STATION : the diffusion list's station's uid
 */
type DiffusionListSearchFilter =
  'NAME' |
  'TYPE' |
  'STATION';
module DiffusionListSearchFilter {
  export const NAME: DiffusionListSearchFilter = 'NAME';
  export const TYPE: DiffusionListSearchFilter = 'TYPE';
  export const STATION: DiffusionListSearchFilter = 'STATION';
  export function values(): DiffusionListSearchFilter[] {
    return [
      NAME,
      TYPE,
      STATION
    ];
  }
}

export { DiffusionListSearchFilter }