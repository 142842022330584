import {ContentId} from 'src/app/enums/env/content-id.enum';
import {ContentEnv} from 'src/app/interfaces/env/ContentEnv';
import {EnergyType} from 'src/swagger-gen/web-secured/models';

const energyType = EnergyType.values()
    .filter(e => e !== EnergyType.UNDEFINED && e !== EnergyType.BIOGNC && e !== EnergyType.GNC && e !== EnergyType.WATER);
energyType.unshift(EnergyType.GNC);
const energyTypeFilter = [...energyType.map(e => ({
    id: e,
    name: 'stations.energy.' + e,
    value: e
}))];
export const dashboardEnvironment: ContentEnv = {

    // collapse sections
    sectionWhiteList: [
        {
            id: ContentId.PRECONFIGURED_SECTION,
            collapse: false
        },
        {
            id: ContentId.AVAILABILITY_SECTION,

            collapse: false
        },

        {
            id: ContentId.CONNECTIVITY_N_AVAILABILITY_SECTION,

            collapse: false
        },
        {
            id: ContentId.ALARM_SECTION,
            collapse: true
        },
        {
            id: ContentId.TRANSACTION_SECTION,

            collapse: true
        },
        {
            id: ContentId.TICKET_SECTION,

            collapse: true
        },
        {
            id: ContentId.TRANSACTION_STATION_CONNECTIVITY,

            collapse: true
        },
        {
            id: ContentId.TRANSACTION_FTP_CONNECTIVITY,

            collapse: true
        },
        {
            id: ContentId.TRANSACTION_ACQUISITION,

            collapse: true
        }
    ],


    // collapse
    graphWhiteList: [
        ContentId.PRECONFIGURED_GRAPH,
        ContentId.AVAILABILITY_GRAPH,
        ContentId.CONNECTIVITY_GRAPH,
        ContentId.ALARM_GRAPH,
        ContentId.ALARM_TOGGLE_GRAPH,
        ContentId.TRANSACTION_COUNT_GRAPH,
        ContentId.TRANSACTION_VOLUME_GRAPH,
        ContentId.TICKET_GRAPH,
        ContentId.TICKET_COUNT_GRAPH
    ],

    // dashboards conf
    graphGeneralEnergyListFilter: energyTypeFilter
};

