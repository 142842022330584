/* tslint:disable */

/**
 * The station payment types:
 *   * "SMARTPHONE"
 *   * "BANK_CARD"
 *   * "BADGE"
 *   * "LICENSE_PLATE"
 *   * "DKV"
 *   * "ENGIE_CARD"
 *   * "UTA"
 *   * "AS24"
 *   * "AXEGAZ"
 *   * "ROMAC"
 *   * "OTHER"
 */
type PaymentType =
  'SMARTPHONE' |
  'BANK_CARD' |
  'BADGE' |
  'LICENSE_PLATE' |
  'DKV' |
  'ENGIE_CARD' |
  'UTA' |
  'AS24' |
  'AXEGAZ' |
  'ROMAC' |
  'OTHER';
module PaymentType {
  export const SMARTPHONE: PaymentType = 'SMARTPHONE';
  export const BANK_CARD: PaymentType = 'BANK_CARD';
  export const BADGE: PaymentType = 'BADGE';
  export const LICENSE_PLATE: PaymentType = 'LICENSE_PLATE';
  export const DKV: PaymentType = 'DKV';
  export const ENGIE_CARD: PaymentType = 'ENGIE_CARD';
  export const UTA: PaymentType = 'UTA';
  export const AS24: PaymentType = 'AS24';
  export const AXEGAZ: PaymentType = 'AXEGAZ';
  export const ROMAC: PaymentType = 'ROMAC';
  export const OTHER: PaymentType = 'OTHER';
  export function values(): PaymentType[] {
    return [
      SMARTPHONE,
      BANK_CARD,
      BADGE,
      LICENSE_PLATE,
      DKV,
      ENGIE_CARD,
      UTA,
      AS24,
      AXEGAZ,
      ROMAC,
      OTHER
    ];
  }
}

export { PaymentType }