import {NgModule} from '@angular/core';
import {FileDownloadComponent} from './file-download.component';
import {LoadingModule} from '../loading/loading.module';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [FileDownloadComponent],
  imports: [
    LoadingModule,
    TranslateModule,
    CommonModule
  ],
  exports: [FileDownloadComponent]
})
export class FileDownloadModule { }
