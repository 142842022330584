import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnActionListComponent } from './components/btn-action-list/btn-action-list.component';
import { BtnActionComponent } from './components/btn-action/btn-action.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { SearchModule } from '../search/search.module';



@NgModule({
  declarations: [
    BtnActionListComponent,
    BtnActionComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    BtnActionListComponent,
    BtnActionComponent,
  ] 
})
export class BtnActionModule { }
