export enum DateRangeCubeJs {
    CUSTOM = 'CUSTOM', // [2021-12-20, 2021-12-30]
    TODAY = 'Today',
    YESTERDAY = 'Yesterday',
    LAST_7_DAYS = 'Last 7 days',
    LAST_30_DAYS = 'Last 30 days',
    THIS_WEEK = 'This week',
    THIS_MONTH = 'This month',
    THIS_QUARTER = 'This quarter',
    THIS_YEAR = 'This year',
    LAST_WEEK = 'Last week',
    LAST_MONTH = 'Last month',
    LAST_QUARTER = 'Last quarter',
    LAST_YEAR = 'Last year'
}
