import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCardModule} from '@angular/material/card';

import {RouterModule} from '@angular/router';

import {CubejsClient} from '@cubejs-client/ngx';
import {TranslateModule} from '@ngx-translate/core';
import * as echarts from 'echarts';
import {NgChartsModule} from 'ng2-charts';
import {DpDatePickerModule} from 'ng2-date-picker';
import {NgxEchartsModule} from 'ngx-echarts';
import {NgxSpinnerModule} from 'ngx-spinner';

import {DragDropModule} from '@angular/cdk/drag-drop';
import {HeaderModule} from 'src/app/shared/components/header/header.module';
import {ChangeRouterlinkDirective} from '../directives/changeLink/change-routerlink.directive';
import {ReadWriteDirective} from '../directives/readWrite/read-write.directive';
import {SearchAlarmListComponent} from '../modules/alarms/search-alarm-list/search-alarm-list.component';
import {BtnActionModule} from '../modules/btn-action/btn-action.module';
import {BtnActionListComponent} from '../modules/btn-action/components/btn-action-list/btn-action-list.component';
import {BtnActionComponent} from '../modules/btn-action/components/btn-action/btn-action.component';
import {KpiDashboardComponent} from '../modules/dashboard/kpi-dashboard/kpi-dashboard.component';
import {PlainCubeComponent} from '../modules/dashboard/plain-cube/plain-cube.component';
import {QueryRendererComponent} from '../modules/dashboard/query-renderer/query-renderer.component';
import {EchartsRendererComponent} from '../modules/ems/component/echarts/echarts-renderer/echarts-renderer.component';
import {SearchComponent} from '../modules/search/components/search-component/search.component';
import {SearchModule} from '../modules/search/search.module';
import {getTicketListComponent} from '../modules/tickets/search-ticket-list/search-ticket-list.component';
import {NumberFormatPipe} from '../pipes/number-format/NumberFormat.pipe';
import {CardComponent} from '../tinea-components/card/card.component';
import {DateTimePickerStaticModule} from '../tinea-components/dateTime-picker/dateTime-picker.module';
import {DisplayErrorsMessageModule} from '../tinea-components/display-errors-message/display-errors-message.module';
import {FileDownloadModule} from '../tinea-components/file-download/file-download.module';
import {FileUploadModule} from '../tinea-components/file-upload/file-upload.module';
import {LoadingModule} from '../tinea-components/loading/loading.module';
import {PagingModule} from '../tinea-components/paging/paging.module';
import {TableModule} from '../tinea-components/table/table.module';
import {TineaTableModule} from '../tinea-components/tinea-table/tinea-table.module';
import {AcquisitionLogListComponent} from './components/acquisition-log-list/acquisition-log-list.component';
import {AlarmsListComponent} from './components/alarms-list/alarms-list.component';
import {CallLogListComponent} from './components/call-log-list/call-log-list.component';
import {CollapseComponent} from './components/collapse/collapse.component';
import {ConfigurationStationGirComponent} from './components/configuration-station-gir/configuration-station-gir.component';
import {ConfigurationStationLafonComponent} from './components/configuration-station-lafon/configuration-station-lafon.component';
import {ConfigurationStationListComponent} from './components/configuration-station/configuration-station-list/configuration-station-list.component';
import {ConfigurationOpcuaStationComponent} from './components/configuration-station/configuration-station/configuration-opcua-station.component';
import {ContentTabListComponent} from './components/content-tab-list/content-tab-list.component';
import {ContentTabComponent} from './components/content-tab/content-tab.component';
import {DateRangeFilterComponent} from './components/date-range-filter/date-range-filter.component';
import {DetailStationComponent} from './components/detail-station/detail-station.component';
import {ModalDeleteStationComponent} from './components/detail-station/modal-delete-station/modal-delete-station.component';
import {ErrorComponent} from './components/error/error.component';
import {FiltersTableModule} from './components/filters-table/filters-table.module';
import {FindDiffusionListComponent} from './components/find-diffusion-list/find-diffusion-list.component';
import {GnlListComponent} from './components/gnl-list/gnl-list.component';
import {FiltersGraphComponent} from './components/graph/filters-graph/filters-graph.component';
import {ModalExportRequestComponent} from './components/graph/filters-graph/modal-export-request/modal-export-request.component';
import {FiltersNGraphEchartComponent} from './components/graph/filters-n-graph-echart/filters-n-graph-echart.component';
import {GraphEchartComponent} from './components/graph/graph-echart/graph-echart.component';
import {ModalReferenceLineGraphComponent} from './components/graph/modal-reference-line-graph/modal-reference-line-graph.component';
import {SelectionTagComponent} from './components/graph/selection-tag/selection-tag.component';
import {JumpingDotsComponent} from './components/jumping-dots/jumping-dots.component';
import {ModalAstreinteTeamTicketComponent} from './components/modal-astreinte-team-ticket/modal-astreinte-team-ticket.component';
import {ModalBadgeAssignmentComponent} from './components/modal-badge-assignment/modal-badge-assignment.component';
import {ModalBadgeInfoComponent} from './components/modal-badge-info/modal-badge-info.component';
import {ModalBadgesUnassignComponent} from './components/modal-badges-unassign/modal-badges-unassign.component';
import {ModalBadgesComponent} from './components/modal-badges/modal-badges.component';
import {ModalColorTagGraphComponent} from './components/modal-color-tag-graph/modal-color-tag-graph.component';
import {ModalCommentsTicketComponent} from './components/modal-comments-ticket/modal-comments-ticket.component';
import {ModalDeleteBadgeComponent} from './components/modal-delete-badge/modal-delete-badge.component';
import {ModalDeleteDiffusionListComponent} from './components/modal-delete-diffsion-list/modal-delete-diffsion-list.component';
import {ModalDeletePushNotificationComponent} from './components/modal-delete-push-notification/modal-delete-push-notification.component';
import {ModalDeleteRoleComponent} from './components/modal-delete-role/modal-delete-role.component';
import {ModalDeleteStationConfigComponent} from './components/modal-delete-station-config/modal-delete-station-config.component';
import {ModalDeleteSubscriptionComponent} from './components/modal-delete-subscription/modal-delete-subscription.component';
import {ModalDeleteSvgComponent} from './components/modal-delete-svg/modal-delete-svg.component';
import {ModalDeleteUserComponent} from './components/modal-delete-user/modal-delete-user.component';
import {ModalDeleteVehicleComponent} from './components/modal-delete-vehicle/modal-delete-vehicle.component';
import {ModalDeviantTagsComponent} from './components/modal-deviant-tags/modal-deviant-tags.component';
import {ModalEditSubplanItemComponent} from './components/modal-edit-subplan-item/modal-edit-subplan-item.component';
import {SubPlanAssociationsComponent} from './components/modal-edit-subplan-item/sub-plan-associations/sub-plan-associations.component';
import {ModalEstimateInterventionComponent} from './components/modal-estimate-intervention/modal-estimate-intervention.component';
import {ModalExportStationsTransactionsComponent} from './components/modal-export-stations-transactions/modal-export-stations-transactions.component';
import {ModalNewExportComponent} from './components/modal-new-export/modal-new-export.component';
import {ModalOrganizeConfiguredGraphsComponent} from './components/modal-organize-configured-graphs.component/modal-organize-configured-graphs.component';
import {ModalRemoveConfiguredGraphComponent} from './components/modal-remove-configured-graph/modal-remove-configured-graph.component';
import {ModalStationAvailabiltyFormulaComponent} from './components/modal-station-availabilty-formula/modal-station-availabilty-formula.component';
import {ModalSubscriptionAssignmentComponent} from './components/modal-subscription-assignment/modal-subscription-assignment.component';
import {ModalSubscriptionInfoComponent} from './components/modal-subscription-info/modal-subscription-info.component';
import {ModalSubscriptionPlanDetailComponent} from './components/modal-subscription-plan-detail/modal-subscription-plan-detail.component';
import {ModalTransferTicketComponent} from './components/modal-transfer-ticket/modal-transfer-ticket.component';
import {ModalUnitAcquisitionParametersComponent} from './components/modal-unit-acquisition-parameters/modal-unit-acquisition-parameters.component';
import {ModalUsersComponent} from './components/modal-users/modal-users.component';
import {ModalVehiclesComponent} from './components/modal-vehicles/modal-vehicles.component';
import {AlertComponent} from './components/notifications/alert/alert.component';
import {PlanningDiffusionListComponent} from './components/planning-diffusion-list/planning-diffusion-list.component';
import {PopupBtnActionComponent} from './components/popup-btn-action/popup-btn-action.component';
import {SelectComponent} from './components/select/select.component';
import {StationOccupancyComponent} from './components/station-occupancy/station-occupancy.component';
import {SwitchGraphBarPieComponent} from './components/switch-graph-bar-pie/switch-graph-bar-pie.component';
import {TicketsTableComponent} from './components/tickets-table/tickets-table.component';
import {TogglePasswordModule} from './directives/toggle-password.module';
import {ModalStationsComponent} from './modal-stations/modal-stations.component';
import {ConfigurationStationMadicComponent} from './components/configuration-station-madic/configuration-station-madic.component';
import {LetModule} from '@rx-angular/template/let';
import {TicketBodyContentComponent} from './components/ticket-body-content/ticket-body-content.component';
import {MatMenuModule} from '@angular/material/menu';
import {AuthenCubeJsService} from '../tinea-components/okta/auth/services/authen-cubejs.service';
import {ConfigurationStationEnedisComponent} from './components/configuration-station-enedis/configuration-station-enedis.component';

const cubejsClientFactory = (authService: AuthenCubeJsService) => {
    return new CubejsClient(authService.config$);
};

@NgModule({
    declarations: [
        AlertComponent,
        DetailStationComponent,
        JumpingDotsComponent,
        ModalDeleteStationComponent,
        ModalDeleteSubscriptionComponent,
        ModalDeleteBadgeComponent,
        ModalDeleteUserComponent,
        ModalDeleteVehicleComponent,
        ErrorComponent,
        SelectionTagComponent,
        FiltersGraphComponent,
        GraphEchartComponent,
        ModalBadgeInfoComponent,
        ModalSubscriptionInfoComponent,
        ModalBadgeAssignmentComponent,
        ModalSubscriptionAssignmentComponent,
        ModalBadgesUnassignComponent,
        ConfigurationOpcuaStationComponent,
        ConfigurationStationListComponent,
        ModalStationsComponent,
        ModalBadgesComponent,
        ModalSubscriptionPlanDetailComponent,
        ModalUsersComponent,
        ModalVehiclesComponent,
        ModalDeleteStationConfigComponent,
        QueryRendererComponent,
        ModalDeleteSvgComponent,
        ConfigurationStationGirComponent,
        ConfigurationStationLafonComponent,
        ConfigurationStationMadicComponent,
        ConfigurationStationEnedisComponent,
        ModalEditSubplanItemComponent,
        SubPlanAssociationsComponent,
        PlainCubeComponent,
        KpiDashboardComponent,
        ModalDeleteDiffusionListComponent,
        ModalNewExportComponent,
        ModalExportStationsTransactionsComponent,
        CollapseComponent,
        AlarmsListComponent,
        AcquisitionLogListComponent,
        GnlListComponent,
        ModalDeleteRoleComponent,
        ReadWriteDirective,
        FiltersNGraphEchartComponent,
        ModalStationAvailabiltyFormulaComponent,
        EchartsRendererComponent,
        NumberFormatPipe,
        SwitchGraphBarPieComponent,
        SelectComponent,
        DateRangeFilterComponent,
        ModalDeletePushNotificationComponent,
        CallLogListComponent,
        PlanningDiffusionListComponent,
        ModalExportRequestComponent,
        StationOccupancyComponent,
        ContentTabComponent,
        ContentTabListComponent,
        ModalEstimateInterventionComponent,
        ModalTransferTicketComponent,
        ModalCommentsTicketComponent,
        ModalAstreinteTeamTicketComponent,
        ChangeRouterlinkDirective,
        PopupBtnActionComponent,
        SearchAlarmListComponent,
        getTicketListComponent,
        CardComponent,
        ModalOrganizeConfiguredGraphsComponent,
        ModalRemoveConfiguredGraphComponent,
        ModalColorTagGraphComponent,
        ModalReferenceLineGraphComponent,
        ModalDeviantTagsComponent,
        ModalUnitAcquisitionParametersComponent,
        TicketsTableComponent,
        FindDiffusionListComponent,
        TicketBodyContentComponent,
    ],
    imports: [
        CommonModule,
        HeaderModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatTableModule,
        MatSlideToggleModule,
        MatChipsModule,
        RouterModule,
        LoadingModule,
        TranslateModule,
        FormsModule,
        DragDropModule,
        MatDialogModule,
        ReactiveFormsModule,
        DpDatePickerModule,
        NgxEchartsModule.forRoot({echarts}),
        DateTimePickerStaticModule,
        DisplayErrorsMessageModule,
        MatTooltipModule,
        TableModule,
        TineaTableModule,
        PagingModule,
        NgChartsModule,
        NgxSpinnerModule,
        MatSelectModule,
        BtnActionModule,
        SearchModule,
        MatCardModule,
        FileUploadModule,
        FileDownloadModule,
        FiltersTableModule,
        TogglePasswordModule,
        LetModule,
        MatMenuModule,
    ],
    exports: [
        HeaderModule,
        AlertComponent,
        DetailStationComponent,
        ConfigurationOpcuaStationComponent,
        JumpingDotsComponent,
        ErrorComponent,
        SelectionTagComponent,
        FiltersGraphComponent,
        GraphEchartComponent,
        ModalBadgeInfoComponent,
        ModalSubscriptionInfoComponent,
        ConfigurationStationListComponent,
        QueryRendererComponent,
        SubPlanAssociationsComponent,
        PlainCubeComponent,
        KpiDashboardComponent,
        CollapseComponent,
        AlarmsListComponent,
        AcquisitionLogListComponent,
        CallLogListComponent,
        GnlListComponent,
        ReadWriteDirective,
        FiltersNGraphEchartComponent,
        EchartsRendererComponent,
        NumberFormatPipe,
        SwitchGraphBarPieComponent,
        SelectComponent,
        DateRangeFilterComponent,
        PlanningDiffusionListComponent,
        StationOccupancyComponent,
        ChangeRouterlinkDirective,
        PopupBtnActionComponent,
        BtnActionComponent,
        BtnActionListComponent,
        SearchAlarmListComponent,
        getTicketListComponent,
        SearchComponent,
        CardComponent,
        ModalDeviantTagsComponent,
        ModalUnitAcquisitionParametersComponent,
        TicketsTableComponent,
        TicketBodyContentComponent,
    ],
    providers: [
        AuthenCubeJsService,
        {
            provide: CubejsClient,
            useFactory: cubejsClientFactory,
            deps: [AuthenCubeJsService],
        },
    ],
})
export class SharedModule {
}
