import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iconClass } from 'src/app/enums/icon-class.enum';
import { MainRoute } from 'src/app/models/MainRoute';
import { RoutingService } from 'src/app/services/Routing.service';
import { slideInLeftToRightAnimation } from 'src/app/tinea-components/animations/slideInLeftToRight';
import { AuthService } from 'src/app/tinea-components/okta/auth/auth.service';
import { AppGuardType } from 'src/app/tinea-components/okta/enum/AppGuardType';
import { FullyLoggedInStateType } from 'src/app/tinea-components/okta/enum/FullyLoggedInStateType';
import { LoggedInService } from 'src/app/tinea-components/okta/services/loggedIn.service';
import { UserSessionService } from 'src/app/tinea-components/okta/services/session/user-session.service';
import { coreEnvironment } from 'src/environments/core/core.environment';
import { environment } from 'src/environments/environment';

@Component({
  selector: '.app-left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrls: ['./left-navigation.component.scss'],
  animations: [slideInLeftToRightAnimation],
})
export class LeftNavigationComponent implements OnInit {
  readonly VERSION = environment.VERSION;
  @Input() fullSidebar: boolean;
  userName = '';
  iconClass = iconClass;

  logo = environment.core.logoPath;
  logoInline = environment.core.logoInline;
  routes: MainRoute[] = [];
  autAppGuard = environment.authAppGuard;
  publicSiteDisplay = coreEnvironment.publicSiteDisplay;
  NotAuthenticatedJustLoggedIn = AppGuardType.NOT_AUTHENTICATED_JUST_LOGGEDIN;
  urlPublicWwebsite: string = null;

  constructor(
    // private alarmService: AlarmService,
    // private siteservice: SiteService
    public router: Router,
    public authService: AuthService,
    public loggedInService: LoggedInService,
    private userSessionService: UserSessionService,
    private routingService: RoutingService,
  ) {

  }

  async ngOnInit() {
      this.subscription();

  }

  /* ************************************ USER START *********************** */
  logout() {
    this.authService.logout();
  }

  setMenu() {
    const user = this.userSessionService.getUser();
    this.userName = user.firstName + ' ' + user.lastName;
    this.urlPublicWwebsite = environment.urlPublicWwebsite;
    this.routes = [...this.routingService.getRoutesLeftMenu()];
  }

  subscription() {
    if(environment.authAppGuard === AppGuardType.NOT_AUTHENTICATED_JUST_LOGGEDIN){
      // subscribe to observer
     this.loggedInService.fullyLoggedInState$.subscribe(
      (isFullyLoggedIn: FullyLoggedInStateType) => {
        if (isFullyLoggedIn === FullyLoggedInStateType.SUCCESS) {
          this.setMenu();
        }
      }
    );
    }else {
      this.setMenu();
    }
  } 
}
