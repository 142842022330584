import { EChartOption } from 'echarts/lib/echarts';
import { GranularityCubeJs } from 'src/app/enums/cubejs/granularity';
import { NumberRank } from 'src/app/enums/number/number-rank.enum';
import { StyleNumber } from 'src/app/enums/number/StyleNumber.enum';
import { ApplicationFeature, EnergyType, TicketState } from 'src/swagger-gen/web-secured/models';
import { routeEnvironment } from './route.environment';

const chartTitleFontStyle: EChartOption.BaseTextStyle['fontStyle'] = 'normal';
const chartTitleFontWeight: EChartOption.BaseTextStyle['fontWeight'] = 'normal';
const font = 'Open Sans';

export const coreEnvironment = {
  // maintenance
  maintenance: false,
  noData: '--',
  // main
  title: 'DOGESTAT',
  logoPath: 'assets/images/gnvert-style/logo-gnvert.svg', // shiva-style: logoPath: 'assets/images/SHIVA-LOGO-PLAIN.svg',
  logoInline: 'assets/images/gnvert-style/logo-gnvert.svg', // shiva-style: logoInline: 'assets/images/SHIVA-LOGO-INLINE.svg',
  
  // Whitelist Auhtorized Features
  whitelistAuthorizedFeatures: [ApplicationFeature.SITE_OPERATION, ApplicationFeature.SITE_MANAGEMENT, ApplicationFeature.PRICE, ApplicationFeature.SUBSCRIPTION, ApplicationFeature.TICKET, ApplicationFeature.TRANSACTION, ApplicationFeature.VEHICLE, ApplicationFeature.OCCUPANCY, ApplicationFeature.AUTH_SUPPORT],
  whitelistAuthorizedFeaturesForClient: [ApplicationFeature.SUBSCRIPTION, ApplicationFeature.TRANSACTION, ApplicationFeature.AUTH_SUPPORT, ApplicationFeature.TICKET, ApplicationFeature.SITE_MANAGEMENT],
  // Features Usage
  useSubscriptionsFeature: true,
  actionOnAlarms: false,
  formulaAvailability: true,
  // public site
  publicSiteDisplay: true,
  showSymbol : true,
  // localstorage
  languageNameInSession: 'language',

  // routes : relative url, name, icon (class name)
  routes: routeEnvironment,

  // font
  mainFont: font,

  // ====================================
  //              TABLE
  // ====================================
  TABLE_NO_DATA_MESSAGE: 'Aucun résultat',

  // ====================================
  //              CHART
  // ====================================
  // title
  CHART_TITLE_BACKGROUND_COLOR: 'transparent',
  CHART_TITLE_HORIZONTAL_ALIGN: 'center',
  CHART_TITLE_BORDER_COLOR: 'transparent',
  CHART_TITLE_BORDER_WIDTH: 1,
  CHART_TITLE_BORDER_RADIUS: 5,
  CHART_TITLE_PADDING: 0,
  CHART_TITLE_FONT_STYLE: chartTitleFontStyle,
  CHART_TITLE_FONT_WEIGHT: chartTitleFontWeight,
  CHART_TITLE_FONT_FAMILY: font,
  CHART_TITLE_FONT_SIZE: 18,
  // dataZoom slider
  CHART_ZOOM_BORDER_COLOR: 'transparent',
  CHART_ZOOM_BACKGROUND_COLOR: '#d5dbe4',
  CHART_ZOOM_HANDLE_THICKNESS: 30,
  CHART_ZOOM_HANDLE_SIZE: 30,
  CHART_ZOOM_HANDLE_STYLE_SHADOW_BLUR: 0,
  CHART_ZOOM_HANDLE_STYLE_SHADOW_X: 0,
  CHART_ZOOM_HANDLE_STYLE_SHADOW_Y: 0,
  CHART_ZOOM_HANDLE_STYLE_SHADOW_COLOR: 'transparent',
  CHART_ZOOM_HANDLE_STYLE_OPACITY: 1,
  // pie chart
  PIE_CHART_SIZE: 75,
  PIE_CHART_LEGEND_BREAKPOINT: 1500,

  // cube js + other env
  stationGranularityBlackList: [GranularityCubeJs.SECOND, GranularityCubeJs.MINUTE,  GranularityCubeJs.QUARTER],
  granularityBlackList: {
    customRange: [GranularityCubeJs.SECOND, GranularityCubeJs.MINUTE],
    dayRange: [GranularityCubeJs.SECOND, GranularityCubeJs.MINUTE, GranularityCubeJs.DAY, GranularityCubeJs.MONTH, GranularityCubeJs.QUARTER, GranularityCubeJs.WEEK, GranularityCubeJs.YEAR],
    weekRange: [GranularityCubeJs.SECOND, GranularityCubeJs.MINUTE, GranularityCubeJs.WEEK, GranularityCubeJs.MONTH, GranularityCubeJs.QUARTER, GranularityCubeJs.YEAR],
    monthRange: [GranularityCubeJs.SECOND, GranularityCubeJs.MINUTE, GranularityCubeJs.HOUR, GranularityCubeJs.MONTH, GranularityCubeJs.QUARTER, GranularityCubeJs.YEAR],
    quarterRange: [GranularityCubeJs.SECOND, GranularityCubeJs.MINUTE, GranularityCubeJs.HOUR, GranularityCubeJs.QUARTER, GranularityCubeJs.YEAR],
    yearRange: [GranularityCubeJs.SECOND, GranularityCubeJs.MINUTE, GranularityCubeJs.HOUR, GranularityCubeJs.QUARTER, GranularityCubeJs.YEAR]
  },

  //WeatherIcons
  weatherIconPath: {
    THUNDERSTORM: '/assets/images/weather-icons/11d@2x.png',
    DRIZZLE: '/assets/images/weather-icons/09d@2x.png',
    RAIN: '/assets/images/weather-icons/10d@2x.png',
    SNOW: '/assets/images/weather-icons/13d@2x.png',
    SMOKE: '/assets/images/weather-icons/50d@2x.png',
    HAZE: '/assets/images/weather-icons/50d@2x.png',
    DUST: '/assets/images/weather-icons/50d@2x.png',
    FOG: '/assets/images/weather-icons/50d@2x.png',
    SAND: '/assets/images/weather-icons/50d@2x.png',
    ASH: '/assets/images/weather-icons/50d@2x.png',
    SQUALL: '/assets/images/weather-icons/50d@2x.png',
    TORNADO: '/assets/images/weather-icons/50d@2x.png',
    CLEAR: '/assets/images/weather-icons/01d@2x.png',
    CLOUDS : '/assets/images/weather-icons/03d@2x.png',
  },

  // dates times format (moment)
  datetimeFormat: {
    datetime: 'L LTS',
    dateHourMinute: 'L LT',
    dateHour: 'L HH[h]',
    dayDate: 'ddd L',
    shortMonth: 'MMM',
    year: 'YYYY'
  },
  // for date picker library : date (2021-12-31)
  datePickerFormat: {
    date: 'DD/MM/YYYY',
    dateTime: 'DD/MM/YYYY HH:mm:ss'
  },
  stations: {
    timeRefreshIndicators: 60000 // milliseconds
  },
  images: {
    badge: '/assets/images/badge.svg'
  },
  data: {
    defaultStyle: StyleNumber.DECIMAL,
    defaultRank: NumberRank.UNIT,
    fixedPercent: 1,
    fixedCurrency: 0,
    fixedDecimal: 2,
    fixedDuration: 2
  },
  regexp: {
    decimals: /^(\d+((\.|,)\d+)?)$/,
    numbers: /^\d+$/
  },
  mapEnergyTypeWithEnergyCube: [
    { cubeJsKey: 'DisponibiliteELEC', energyType: EnergyType.ELECTRICITY },
    { cubeJsKey: 'DisponibiliteGNL', energyType: EnergyType.GNL },
    { cubeJsKey: 'DisponibiliteGNC', energyType: EnergyType.GNC },
    { cubeJsKey: 'DisponibiliteBIOGNC', energyType: EnergyType.BIOGNC },
    { cubeJsKey: 'DisponibiliteGNCL', energyType: EnergyType.GNCL },
    { cubeJsKey: 'DisponibiliteH2', energyType: EnergyType.HYDROGEN }
  ]
};
