/* tslint:disable */

/**
 * The value origin:
 *   * ACQUIRED: acquired (OPCUA...)
 *   * ADDED: injected
 */
type ValueOrigin =
  'ACQUIRED' |
  'ADDED';
module ValueOrigin {
  export const ACQUIRED: ValueOrigin = 'ACQUIRED';
  export const ADDED: ValueOrigin = 'ADDED';
  export function values(): ValueOrigin[] {
    return [
      ACQUIRED,
      ADDED
    ];
  }
}

export { ValueOrigin }