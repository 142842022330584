import {Component, Input} from '@angular/core';
import {KpiModel} from '../../tickets/models/KpiModel';

@Component({
  selector: 'app-kpi-dashboard',
  templateUrl: './kpi-dashboard.component.html',
  styleUrls: ['./kpi-dashboard.component.scss']
})
export class KpiDashboardComponent {
  @Input() kpiModels: KpiModel[] = [];
  @Input() customContent: boolean = false;
}
