/* tslint:disable */

/**
 * field to filter for call notification search:
 *   * ALL : All fields
 *   * TYPE : the call type
 *   * STATION_CODE : the station code
 *   * INTERVENANT_CODE : the intervenant code
 *   * STATUS : the call status
 *   * PANNE : the panne string
 */
type CallNotificationSearchFilter =
  'ALL' |
  'TYPE' |
  'STATION_CODE' |
  'INTERVENANT_CODE' |
  'STATUS' |
  'PANNE';
module CallNotificationSearchFilter {
  export const ALL: CallNotificationSearchFilter = 'ALL';
  export const TYPE: CallNotificationSearchFilter = 'TYPE';
  export const STATION_CODE: CallNotificationSearchFilter = 'STATION_CODE';
  export const INTERVENANT_CODE: CallNotificationSearchFilter = 'INTERVENANT_CODE';
  export const STATUS: CallNotificationSearchFilter = 'STATUS';
  export const PANNE: CallNotificationSearchFilter = 'PANNE';
  export function values(): CallNotificationSearchFilter[] {
    return [
      ALL,
      TYPE,
      STATION_CODE,
      INTERVENANT_CODE,
      STATUS,
      PANNE
    ];
  }
}

export { CallNotificationSearchFilter }