<!-- GRAPH -->
<span class="ui-loading" *ngIf="loadingModel.isLoading; else graph" [model]="loadingModel"></span>
<ng-template #graph>
    <div class="station-dashboard-graph">
        <!-- TITLE -->
        <div class="station-dashboard-graph-header" style="display: flex; align-items: center">
            <p class="station-dashboard-graph-title">{{ Ititle | translate }}</p>
            <form>
                <div class="transac-graph-view" *ngIf="infoViewTypes.length > 0">
                    <div class="d-inline-block" *ngFor="let infoView of infoViewTypes; let isFirst = first">
                        <input type="radio" class="form-control" [value]="infoView.value"
                               [id]="Ititle + infoView.value" [checked]="isFirst" name="infoView" (click)="changedView($event)">
                        <label [htmlFor]="Ititle + infoView.value">
                            {{infoView.label}}
                        </label>
                    </div>
                </div>
            </form>
            <app-kpi-dashboard *ngIf="showGlobal" [kpiModels]="kpiModels">
            </app-kpi-dashboard>
        </div>
        <div class="station-dashboard-graph-body">
            <!-- body -->
            <!-- MENU -->
            <div class="cubejs-actions">

                <div class="cubejs-form-block row" *ngIf="userWantsCustomDateRange">
                    <!-- START DATE -->
                    <div class="form-group start-date">
                        <dp-date-picker
                                [(ngModel)]="startDateFilter"
                                [config]="startDatePickerConfig"
                                mode="day"
                                required
                                (onChange)="onChangeCustomDate($event)">
                        </dp-date-picker>
                    </div>
                    <!-- END DATE -->
                    <div class="form-group end-date">
                        <dp-date-picker
                                [(ngModel)]="endDateFilter"
                                [config]="endDatePickerConfig"
                                mode="day"
                                required
                                (onChange)="onChangeCustomDate($event)">
                        </dp-date-picker>
                        <div *ngIf="!isValidDateRange" class="alert alert-danger">
                            {{ 'error.endDateBeforeStartDate' | translate }}
                        </div>
                    </div>
                </div>

                <!-- X AXIS FILTER -->
                <div *ngIf="IhasXAxisChanged" class="cubejs-form-block">
                    <span class="cubejs-form-block-label">{{ 'timeGroup.by' | translate }}</span>
                    <mat-form-field appearance="fill" class="cubejs-dropdown">
                        <mat-select [(value)]="currentXAxis" (selectionChange)="changedGraphView($event.value)">
                            <mat-option *ngFor="let xAxis of xAxisLabelList" [value]="xAxis">
                                {{xAxis }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- DATE RANGE -->
                <div class="cubejs-form-block">
                    <span class="cubejs-form-block-label">{{ 'timeGroup.for' | translate }}</span>
                    <mat-form-field appearance="fill" class="cubejs-dropdown">
                        <mat-select [(value)]="currentDateRange" (selectionChange)="onChangeDateRange($event.value)">
                            <mat-option *ngFor="let dateRange of dateRangeList" [value]="dateRange.value">
                                {{ dateRange.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- GRANULARITY -->
                <div *ngIf="IhasGranularity" class="cubejs-form-block">
                    <span class="cubejs-form-block-label">{{ 'timeGroup.by' | translate }}</span>
                    <mat-form-field appearance="fill" class="cubejs-dropdown">
                        <mat-select [(value)]="currentGranularity"
                                    (selectionChange)="onChangeGranularity($event.value)">
                            <mat-option *ngFor="let granularity of granularityList" [value]="granularity.value">
                                {{ granularity.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="!IhasGranularity" class="cubejs-form-block empty">&nbsp;</div>

                <!-- ITEMISATION -->
                <div *ngFor="let filter of Iitemisation" class="cubejs-form-block">
                    <span class="cubejs-form-block-label">{{ filter.label | translate }}</span>
                    <mat-form-field appearance="fill" class="cubejs-dropdown">
                        <mat-select [(value)]="filter.current"
                                    (selectionChange)="onChangeItemisation($event.value, filter.member, filter.operator, filter.type)">
                            <mat-option *ngFor="let item of filter.values" [value]="item.value">
                                {{ item.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="!Iitemisation.active" class="cubejs-form-block empty">&nbsp;</div>
            </div>

            <!-- error -->
            <div *ngIf="hasError; else graph"
                 class="station-dashboard-error alert-danger">{{ 'graphStation.errorMsg' | translate }}
            </div>
            <!-- GRAPH -->
            <ng-template #graph>
                <app-query-renderer [chartType]="chartType.asObservable()"
                                    [cubeQuery]="cubeQuery.asObservable()"
                                    [pivotConfig]="pivotConfig.asObservable()"
                                    [unit]="unit.asObservable()"
                                    [graphType]="graphType.asObservable()"
                                    [convertValue]="convertValue"
                                    [clickable]="clickable"
                                    (hasError)="onHasError($event)"
                                    (clicked)="transferEvent($event)"
                                    (globalValuesEmitter)="receiverGlobalValues($event)"
                ></app-query-renderer>
            </ng-template>
        </div>
    </div>
</ng-template>
