import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { User } from "src/app/shared/models/user/user";
import { environment } from "src/environments/environment";
import { UserProfileSummary } from "src/swagger-gen/web-secured/models";
import { AuthService } from "../auth/auth.service";
import { FullyLoggedInStateType } from "../enum/FullyLoggedInStateType";
import { ConsumeDataService } from "./consume-data.service";
import { UserSessionService } from "./session/user-session.service";

export abstract class LoggedInService {
  fullyLoggedInState$: Subject<FullyLoggedInStateType> =
    new Subject<FullyLoggedInStateType>();
  public resultHandlers: boolean[];
  private resultIsLoggedInList: boolean[];

  constructor(
    public userSessionService: UserSessionService,
    public authService: AuthService,
    public consumeDataService: ConsumeDataService,
    public router: Router
  ) {}

  /**
   * Check if User is already completely logged
   */
  public abstract isLoggedIn(): Promise<boolean>;

  public abstract loggedIn(): void;

  protected abstract getUser(): Promise<UserProfileSummary>;

  public setAuthTokenInSession(authToken: string): void {
    this.userSessionService.storeAuthToken(authToken);
  }

  public setAuthExpireAtInSession(expireAt: number) {
    this.userSessionService.storeAuthExpireAt(expireAt);
  }

  public setDataTokenTypeInSession(dataTokenType: string): void {
    this.userSessionService.storeDataTokenType(dataTokenType);
  }

  public setDataTokenInSession(dataToken: string): void {
    this.userSessionService.storeDataToken(dataToken);
  }

  public setUserInSession(user: UserProfileSummary): void {
    try {
      const userModel = new User();
      userModel.initFromApi(user);
      const userInString = JSON.stringify(userModel);
      this.userSessionService.storeUser(userInString);
    } catch (error) {
      console.error("User non set en session");
    }
  }

  // ----------------- TOKEN ----------------- //
  protected async authTokenHandler(): Promise<boolean> {
    const authToken = await this.generateAuthToken();
    // console.log('DEBUG: authTokenHandler()', authToken); // DEBUG
    if (authToken) {
      this.setAuthTokenInSession(authToken);
      return true;
    } else {
      return false;
    }
  }

  protected async generateAuthToken(): Promise<string> {
    const accessToken = await this.authService.getAuthToken();
    if (accessToken) {
      return accessToken;
    } else {
      return null;
    }
  }

  // ----------------- TOKEN ----------------- //
  public async authTokenExpireAtHandler(): Promise<boolean> {
    const authToken = await this.generateAuthTokenExpireAt();
    // console.log('DEBUG: authTokenExpireAtHandler()', authToken); // DEBUG
    if (authToken) {
      this.setAuthExpireAtInSession(authToken);
      return true;
    } else {
      return false;
    }
  }

  protected async generateAuthTokenExpireAt(): Promise<number> {
    const expireAt = await this.authService.getExpireAt();
    if (expireAt) {
      return expireAt;
    } else {
      return null;
    }
  }

  protected getResultHandlers(): boolean[] {
    return this.resultHandlers;
  }

  protected addResultHandler(value: boolean) {
    if (!this.resultHandlers) {
      this.resultHandlers = [];
    }
    this.resultHandlers.push(value);
  }

  protected isValidResultHandlers(): boolean {
    const isValid = this.resultHandlers.includes(false) ? false : true;
    // console.log('isValidResultHandlers(): ', isValid, 'resultHandlers: ', this.resultHandlers); // DEBUG
    return isValid;
  }

  protected getResultIsLoggedInList(): boolean[] {
    return this.resultIsLoggedInList;
  }

  protected addResultIsLoggedIn(value: any): void {
    if (!this.resultIsLoggedInList) {
      this.resultIsLoggedInList = [];
    }
    const isValid = value === null ? false : true;
    this.resultIsLoggedInList.push(isValid);
  }

  protected isValidResultIsLoggedIn(): boolean {
    return this.resultIsLoggedInList.includes(false) ? false : true;
  }

  protected clearResultIsLoggedInList() {
    this.resultIsLoggedInList = [];
  }

  // --------------------------------------------------- //
  //                       Handlers                      //
  // --------------------------------------------------- //
  // ----------------- Data Token Type ----------------- //
  protected async dataTokenTypeHandler(): Promise<boolean> {
    const tokenType = this.consumeDataService.getDataTokenTypeFromEnv();
    if (tokenType || tokenType === "") {
      this.setDataTokenTypeInSession(tokenType);
      return true;
    } else {
      return null;
    }
  }

  // ----------------- Data Token ----------------- //
  protected async dataTokenHandler(): Promise<boolean> {
    const token = await this.consumeDataService.getDataToken();
    if (token || token === "") {
      this.setDataTokenInSession(token);
      return true;
    } else {
      return null;
    }
  }

  // ----------------- USER ----------------- //
  public async authUserHandler(): Promise<boolean> {
    const user = await this.getUser();
    if (user) {
      this.setUserInSession(user);
      return true;
    }
    return false;
  }

  /**
   * Get User then set him in session
   */
  public async refreshUser() {
    const isLoggedIn = await this.isLoggedIn();
    if (isLoggedIn) {
      return await this.authUserHandler();
    }
    return false;
  }

  public redirectAfterFullyLoggedIn(): void {
    const url = this.userSessionService.getRedirectTo();

    if (environment.authRedirectToPreviousUrl && url) {
      this.router.navigate([url]);
    } else {
      this.router.navigate([environment.authPathRedirectAfterLogin]);
    }
  }
}
