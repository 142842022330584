import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';

import {CubeJsChartType} from 'src/app/enums/cubejs-chart-type';
import {DateRangeCubeJs} from 'src/app/enums/cubejs/date-range';
import {GranularityCubeJs} from 'src/app/enums/cubejs/granularity';
import {GraphType} from 'src/app/enums/cubejs/graph-type';
import {BaseCubeJsGraphModel} from 'src/app/models/cubejs/baseCubeJsGraphModel';
import {ConvertValueModel} from 'src/app/models/cubejs/ConvertValueModel';
import {AlertService} from 'src/app/services';
import {CubeJsService} from 'src/app/services/cubeJs.service';
import {GlobalDisponibility} from '../query-renderer/query-renderer.component';
import {KpiModel} from '../../tickets/models/KpiModel';
import {iconClass} from '../../../enums/icon-class.enum';
import {XAxisLabelEnum} from '../../../enums/cubejs/x-axis-label.enum';


export interface InfoViewInterface {
    label: string,
    value: any
}

@Component({
    selector: 'app-plain-cube',
    templateUrl: './plain-cube.component.html',
    styleUrls: ['./plain-cube.component.scss']
})
export class PlainCubeComponent extends BaseCubeJsGraphModel implements OnInit, OnChanges {

    @Input() Ititle: string;
    @Input() Iquery: any;
    @Input() IchartType: CubeJsChartType;
    @Input() IcurrentGranularity: GranularityCubeJs;
    @Input() IDateRangeCubeJs: DateRangeCubeJs;
    @Input() IgraphType: GraphType;
    @Input() IconvertValue: ConvertValueModel[];
    @Input() Iunit: any;
    @Input() IhasGranularity: boolean;
    @Input() IhasXAxisChanged: boolean = false;
    @Input() IpivotConfig: any;
    @Input() Iitemisation: object;
    @Input() clickable: boolean;
    @Input() showGlobal: boolean = false;
    @Input() infoViewTypes: InfoViewInterface[] = [];
    @Output() clickedChart = new EventEmitter<any>();
    @Output() clickedChangeInfoView = new EventEmitter<any>();
    @Output() clickedChangeXAxisLabelValue = new EventEmitter<XAxisLabelEnum>();
    kpiModels: KpiModel[] = [];
    protected readonly iconClass = iconClass;

    constructor(private router: Router,
                private translate: TranslateService,
                private alertService: AlertService,
                protected cubeJsService: CubeJsService
    ) {
        super(cubeJsService);
    }

    ngOnInit(): void {
        this.initDateRange();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.Iquery && changes.Iquery.currentValue) {
            this.hasGranularity = this.IhasGranularity;
            this.DEFAULT_CHART_TYPE = this.IchartType;
            this.currentGranularity = this.IcurrentGranularity;
            this.currentDateRange = this.IDateRangeCubeJs;
            this.graphType = new BehaviorSubject(this.IgraphType);
            this.convertValue = new BehaviorSubject<ConvertValueModel[]>(this.IconvertValue);
            this.unit = new BehaviorSubject(this.Iunit);

            this.setDefaultQuery();
            this.userWantsCustomDateRangeHandler(this.currentDateRange);

            this.setDefaultLoading();
            this.setDefaultFilter();
            this.setGranularityListByDateRange(this.currentDateRange as DateRangeCubeJs);
            this.dateRangeList = this.cubeJsService.getDateRangeList();
            this.xAxisLabelList = this.cubeJsService.getXAxisLabeList();
            this.initQuery();
        }
    }

    setDefaultQuery(): void {
        this.defaultQuery = this.Iquery;
    }

    setDefaultFilter(): void {
        this.defaultFilter = this.Iquery.filters;
    }

    changedView($event: any) {
        let sendInfoView: InfoViewInterface = {
            value: $event.target.value,
            label: this.Ititle
        };
        this.clickedChangeInfoView.emit(sendInfoView);
        this.initQuery();
    }

    changedGraphView($event: XAxisLabelEnum) {
        this.clickedChangeXAxisLabelValue.emit($event);
        this.setDefaultQuery();
    }

    initQuery(): void {
        this.cubeQuery.next(this.defaultQuery);
        this.chartType.next(this.DEFAULT_CHART_TYPE);
        this.pivotConfig.next(this.IpivotConfig);
    }

    //plain cube just transfert the click event
    transferEvent(event: any) {
        this.clickedChart.emit(event);
    }

    receiverGlobalValues(event: GlobalDisponibility[]) {
        this.kpiModels = [];
        event.forEach((val) => {
            let kpiModel = new KpiModel(val.value, '%', '', null, false, false, val.measure.valueOf());
            this.kpiModels.push(kpiModel);
        });
    }
}
