/* tslint:disable */

/**
 * field to filter for ticket search:
 *   * ALL : All fields
 *   * NUMBER_TICKET
 *   * STATION_NAME
 *   * TYPE_PANNE
 *   * STATE
 */
type TicketSearchFilter =
  'ALL' |
  'NUMBER_TICKET' |
  'STATION_NAME' |
  'TYPE_PANNE' |
  'STATE';
module TicketSearchFilter {
  export const ALL: TicketSearchFilter = 'ALL';
  export const NUMBER_TICKET: TicketSearchFilter = 'NUMBER_TICKET';
  export const STATION_NAME: TicketSearchFilter = 'STATION_NAME';
  export const TYPE_PANNE: TicketSearchFilter = 'TYPE_PANNE';
  export const STATE: TicketSearchFilter = 'STATE';
  export function values(): TicketSearchFilter[] {
    return [
      ALL,
      NUMBER_TICKET,
      STATION_NAME,
      TYPE_PANNE,
      STATE
    ];
  }
}

export { TicketSearchFilter }