import {iconClass} from 'src/app/enums/icon-class.enum';
import {RouteId} from 'src/app/enums/env/routes-id-enum';
import {RouteEnv} from 'src/app/interfaces/env/RouteEnv';
import {TicketState} from 'src/swagger-gen/web-secured/models';

const ticketsParams = {states: TicketState.CLOSED};

export const routeEnvironment: RouteEnv[] = [
    {
        id: RouteId.DASHBOARD,
        route: 'dashboard',
        name: 'menu.dashboard',
        icon: iconClass.DASHBOARD,
        detailBackLink: null,
        enabled: true,
        tabs: [
            {
                id: RouteId.DASHDEFAULT,
                route: 'default',
                name: 'menu.dashboard_principal',
                icon: iconClass.DASHBOARD,
                detailBackLink: RouteId.DASHBOARD,
                enabled: true,
                order: 1
            },
            {
                id: RouteId.DRAWGRAPHS,
                route: 'drawngraphs',
                name: 'menu.dashboardDrownGraphs',
                icon: iconClass.DASHBOARD,
                detailBackLink: RouteId.DASHBOARD,
                enabled: false,
                order: 2
            },
            {
                id: RouteId.DASHBOARD_GNL,
                route: 'dashboard-gnl',
                name: 'menu.dashboard_gnl',
                icon: iconClass.DASHBOARD,
                detailBackLink: RouteId.DASHBOARD,
                enabled: true,
                order: 3
            },
            {
                id: RouteId.DASHBOARD_CONNECTIONS,
                route: 'dashboard-connections',
                name: 'menu.dashboard_connections',
                icon: iconClass.DASHBOARD,
                detailBackLink: RouteId.DASHBOARD,
                enabled: true,
                order: 4
            },
        ]
    },
    {
        id: RouteId.EMS,
        route: 'diagnostic',
        name: 'menu.ems',
        icon: iconClass.EMS,
        detailBackLink: RouteId.EMS,
        enabled: false
    },
    {
        id: RouteId.MAP,
        route: 'map',
        name: 'menu.map',
        icon: iconClass.MAP,
        detailBackLink: null,
        enabled: true
    },
    {
        id: RouteId.REGIONS,
        route: 'regions',
        name: 'menu.regions',
        icon: iconClass.REGIONS,
        detailBackLink: RouteId.REGIONS,
        enabled: false
    },
    {
        id: RouteId.ALARMS,
        route: 'alarms',
        name: 'menu.alarms',
        // params: { states: AlarmState.RAISED },
        icon: iconClass.ALARM,
        detailBackLink: RouteId.ALARMS,
        enabled: true
    },
    {
        id: RouteId.TICKETS_ROOT,
        route: 'tickets',
        name: 'menu.tickets',
        icon: iconClass.TICKETS,
        detailBackLink: RouteId.TICKETS,
        enabled: true,
        tabs: [
            {
                id: RouteId.TICKETS_DASHBOARD,
                route: 'dashboard',
                name: 'menu.ticketDashboard',
                icon: iconClass.TICKETS,
                detailBackLink: RouteId.TICKETS_ROOT,
                enabled: true
            },
            {
                id: RouteId.TICKETS,
                route: 'tickets',
                name: 'menu.records',
                params: ticketsParams,
                icon: iconClass.TICKETS,
                detailBackLink: RouteId.TICKETS,
                enabled: true
            }
        ]
    },
    {
        id: RouteId.STATIONS,
        route: 'stations',
        name: 'menu.stations',
        icon: iconClass.STATIONS,
        detailBackLink: RouteId.REGIONS,
        enabled: true,
        tabs: [
            {
                id: RouteId.STATION_DASHBOARD,
                route: 'dashboard',
                name: 'stations.tab.dashboard',
                enabled: true,
                order: 1
            },
            {
                id: RouteId.STATION_DETAIL,
                route: 'details',
                name: 'stations.tab.detail',
                enabled: true,
                order: 2
            },
            {
                id: RouteId.STATION_CONFIGURATION,
                route: 'configuration',
                name: 'stations.tab.configuration',
                enabled: true,
                order: 3
            },
            {
                id: RouteId.STATION_TRANSACTIONS,
                route: 'transactions',
                name: 'stations.tab.charge',
                enabled: true,
                order: 4
            },
            {
                id: RouteId.STATION_TICKETS,
                route: 'tickets',
                name: 'stations.tab.tickets',
                enabled: true,
                order: 5
            },
            {
                id: RouteId.STATION_ALARMES,
                route: 'alarms',
                name: 'stations.tab.alarm',
                enabled: true,
                order: 6
            },
            {
                id: RouteId.STATION_CONSIGNATION_ETAT,
                route: 'events',
                name: 'stations.tab.state_logging',
                enabled: true,
                order: 7
            },
            {
                id: RouteId.STATION_ACQUISITION_LOG,
                route: 'acquisition-log',
                name: 'menu.acquisitionLog',
                enabled: false,
                order: 8
            },
            {
                id: RouteId.CALL_LOG,
                route: 'call-log',
                name: 'menu.callLog',
                enabled: true,
                order: 9,
            },
            {
                id: RouteId.STATION_COURBES,
                route: 'graph',
                name: 'stations.tab.graph',
                enabled: true,
                order: 10
            },
            {
                id: RouteId.STATION_SYNOPTIC,
                route: 'synoptics',
                name: 'stations.tab.synoptics',
                enabled: true,
                order: 11
            },
            {
                id: RouteId.FLOORPLAN,
                route: 'floorplan',
                name: 'stations.tab.floorplan',
                enabled: false,
                order: 12
            },
            {
                id: RouteId.STATION_DRIVE,
                route: 'drive',
                name: 'stations.tab.drive',
                enabled: false,
                order: 13
            },
        ]
    },
    {
        id: RouteId.PRICE_UPDATE_REQUESTS,
        route: 'price-update-requests',
        name: '',
        detailBackLink: RouteId.STATIONS,
        enabled: true
    },
    {
        id: RouteId.GRAPHS,
        route: 'graphs',
        name: 'menu.graphs',
        icon: iconClass.GRAPHS,
        detailBackLink: null,
        enabled: true
    },
    {
        id: RouteId.CUSTOMER_ACCOUNT,
        route: ':id',
        name: 'menu.customerAccounts',
        icon: iconClass.CUSTOMER_ACCOUNTS,
        backLinks: [RouteId.CUSTOMER_ACCOUNTS],
        enabled: true,
        tabs: [
            {
                id: RouteId.CUSTOMER_ACCOUNTS_DETAIL,
                route: 'details',
                name: 'subscriptions.tab.detail',
                enabled: true,
                order: 1
            },
            {
                id: RouteId.CUSTOMER_ACCOUNTS_CONTACTS,
                route: 'contacts',
                name: 'subscriptions.tab.contacts',
                enabled: true,
                order: 2
            },
            {
                id: RouteId.CUSTOMER_ACCOUNTS_STATIONS,
                route: 'stations',
                name: 'subscriptions.tab.stations',
                enabled: true,
                order: 3
            },
            {
                id: RouteId.CUSTOMER_ACCOUNTS_BADGES,
                route: 'badges',
                name: 'subscriptions.tab.badges',
                enabled: true,
                order: 4,
                feature: 'AUTH_SUPPORT'
            },
            {
                id: RouteId.CUSTOMER_ACCOUNTS_BADGES_REQUESTS,
                route: 'badges-requests',
                name: 'subscriptions.tab.badgesRequests',
                enabled: true,
                order: 5,
                feature: 'AUTH_SUPPORT'
            },
            {
                id: RouteId.CUSTOMER_ACCOUNTS_TRANSACTIONS,
                route: 'transactions',
                name: 'subscriptions.tab.transactions',
                enabled: true,
                order: 6,
                feature: 'TRANSACTION'
            },
            {
                id: RouteId.CUSTOMER_ACCOUNTS_HISTORY,
                route: 'history',
                name: 'subscriptions.tab.history',
                enabled: true,
                order: 7
            },
            {
                id: RouteId.CUSTOMER_ACCOUNTS_VEHICLES,
                route: 'vehicles',
                name: 'subscriptions.tab.vehicles',
                enabled: false,
                order: 8
            },
            {
                id: RouteId.CUSTOMER_ACCOUNTS_EXPORTS,
                route: 'export',
                name: 'subscriptions.tab.export',
                enabled: true,
                order: 9
            },
        ]
    },
    {
        id: RouteId.CUSTOMER_ACCOUNTS,
        route: 'customer-accounts',
        name: 'menu.customerAccounts',
        icon: iconClass.CUSTOMER_ACCOUNTS,
        enabled: true,
        tabs: [
            {
                id: RouteId.CUSTOMER_ACCOUNTS_NEW,
                route: 'new',
                name: 'menu.customerAccounts',
                icon: iconClass.CUSTOMER_ACCOUNTS,
                enabled: false,
                backLinks: [RouteId.CUSTOMER_ACCOUNTS],
            },
        ]
    },
    {
        id: RouteId.BADGES,
        route: 'badges',
        name: 'menu.badges',
        icon: iconClass.BADGES,
        enabled: true,
        tabs: [
            {
                id: RouteId.BADGES_LIST,
                route: 'list',
                name: 'badges.tab.list',
                enabled: true,
                order: 1
            },
            {
                id: RouteId.BADGES_STOCK,
                route: 'stock',
                name: 'badges.tab.stock',
                enabled: true,
                order: 2
            },
            {
                id: RouteId.BADGES_REQUESTS,
                route: 'requests',
                name: 'badges.tab.requests',
                enabled: true,
                order: 3,
            },
            {
                id: RouteId.BADGES_NEW,
                route: 'new',
                name: 'new',
                backLinks: [RouteId.BADGES, RouteId.BADGES_LIST],
                enabled: false,
            },
            {
                id: RouteId.BADGES_REQUESTS_NEW,
                route: 'new',
                name: 'request-new',
                backLinks: [RouteId.BADGES, RouteId.BADGES_REQUESTS],
                enabled: false,
            },
        ]
    },
    {
        id: RouteId.BADGE,
        route: ':id',
        name: 'menu.badges',
        icon: iconClass.BADGES,
        backLinks: [RouteId.BADGES, RouteId.BADGES_LIST],
        enabled: false,
        tabs: [
            {
                id: RouteId.BADGE_DETAILS,
                route: 'details',
                name: 'badges.tab.details',
                enabled: true,
                order: 1,
            },
            {
                id: RouteId.BADGE_TRANSACTIONS,
                route: 'transactions',
                name: 'badges.tab.transactions',
                enabled: true,
                order: 2,
            },
            {
                id: RouteId.BADGE_HISTORY,
                route: 'history',
                name: 'badges.tab.history',
                enabled: true,
                order: 3,
            }
        ]
    },
    {
        id: RouteId.BADGE_REQUESTS,
        route: ':id',
        name: 'menu.badges',
        icon: iconClass.BADGES,
        backLinks: [RouteId.BADGES, RouteId.BADGES_REQUESTS],
        enabled: false,
        tabs: [
            {
                id: RouteId.BADGES_REQUESTS_DETAILS,
                route: 'details',
                name: 'badges.tab.details',
                enabled: true,
                order: 1,
            },
        ]
    },
    {
        id: RouteId.VEHICLES,
        route: 'vehicles',
        name: 'menu.vehicles',
        icon: iconClass.VEHICLES,
        detailBackLink: RouteId.VEHICLES,
        enabled: true
    },
    {
        id: RouteId.EXPORT,
        route: 'export',
        name: 'menu.export',
        detailBackLink: RouteId.EXPORT,
        enabled: false
    },
    {
        id: RouteId.NOTIFICATIONS,
        route: 'notifications',
        name: 'menu.notifications',
        icon: iconClass.NOTIFICATIONS,
        detailBackLink: RouteId.NOTIFICATIONS,
        enabled: true,
        tabs: [
            {

                id: RouteId.NOTIFICATIONS_HISTORY,
                route: 'history',
                name: 'menu.notificationsHistory',
                enabled: true,
                order: 1,
                tabs: [
                    {
                        id: RouteId.NOTIFICATIONS_HISTORY_DETAILS,
                        route: ':id',
                        name: 'menu.notificationsHistoryDetails',
                        enabled: true,
                        order: 1,
                    },
                ]
            },
            {

                id: RouteId.NOTIFICATIONS_NEWS,
                route: 'news',
                name: 'menu.notificationsNews',
                enabled: true,
                order: 2,
                tabs: [
                    {
                        id: RouteId.NOTIFICATIONS_NEWS_DETAILS,
                        route: ':id',
                        name: 'menu.notificationsNewsDetails',
                        enabled: true,
                    },
                    {
                        id: RouteId.NOTIFICATIONS_NEWS_NEW,
                        route: 'new',
                        name: 'menu.notificationsNewsNew',
                        enabled: true,
                    },
                ]
            },
            {
                id: RouteId.NOTIFICATIONS_CONFIG,
                route: 'configuration',
                name: 'menu.notificationsConfig',
                enabled: true,
                order: 3,
                tabs: [
                    {
                        id: RouteId.NOTIFICATIONS_CONFIG_SYSTEM_DETAILS,
                        route: 'system/:id',
                        name: 'menu.notificationsConfigSystemDetails',
                        enabled: true,
                    },
                    {
                        id: RouteId.NOTIFICATIONS_CONFIG_NOTE_DETAILS,
                        route: 'note/:id',
                        name: 'menu.notificationsConfigNoteDetails',
                        enabled: true,
                    },
                    {
                        id: RouteId.NOTIFICATIONS_CONFIG_NEW,
                        route: 'new',
                        name: 'menu.notificationsConfigNew',
                        enabled: true,
                    },
                ]
            },
        ]
    },
    {
        id: RouteId.ADMINISTRATION,
        route: 'admin',
        name: 'menu.admin',
        icon: iconClass.ADMINISTRATION,
        detailBackLink: RouteId.ADMINISTRATION,
        enabled: true,
        tabs: [
            {
                id: RouteId.USERS,
                route: 'users',
                name: 'menu.users',
                enabled: true,
                order: 1
            },
            {
                id: RouteId.ROLES,
                route: 'roles',
                name: 'menu.roles',
                enabled: true,
                order: 2
            },
            {
                id: RouteId.DIFFUSION_LIST,
                route: 'table',
                name: 'menu.diffusionlists',
                enabled: true,
                order: 3
            },
            {
                id: RouteId.ACQUISITION_LOG,
                route: 'acquisition-log',
                name: 'menu.acquisitionLog',
                enabled: true,
                order: 4,
            },
            {
                id: RouteId.CALL_LOG,
                route: 'call-log',
                name: 'menu.callLog',
                enabled: true,
                order: 5,
            },
            {
                id: RouteId.PUSH_NOTIFICATION,
                route: 'push-notification',
                name: 'menu.pushNotification',
                enabled: false,
                order: 5,
            },
            {
                id: RouteId.ACQUISITION_UNIT,
                route: 'acquisition',
                name: 'menu.acquisition',
                enabled: true,
                order: 7
            },
        ]
    },
    {
        id: RouteId.ADMINISTRATION_DETAILS,
        route: 'admin',
        name: 'menu.admin',
        icon: iconClass.CUSTOMER_ACCOUNTS,
        detailBackLink: RouteId.ADMINISTRATION_DETAILS,
        enabled: false,
        tabs: [
            {
                id: RouteId.DETAILS,
                route: 'diffusion-list',
                name: 'menu.details',
                enabled: true,
                order: 1
            },
        ]
    }
];
