/* tslint:disable */

/**
 * field to filter for transaction search:
 *   * ALL : All fields
 *   * AUTH_SUPPORT : transaction auth support uid
 *   * EXTERNAL_ID : the external id of the transaction
 */
type TransactionSearchFilter =
  'ALL' |
  'AUTH_SUPPORT' |
  'EXTERNAL_ID';
module TransactionSearchFilter {
  export const ALL: TransactionSearchFilter = 'ALL';
  export const AUTH_SUPPORT: TransactionSearchFilter = 'AUTH_SUPPORT';
  export const EXTERNAL_ID: TransactionSearchFilter = 'EXTERNAL_ID';
  export function values(): TransactionSearchFilter[] {
    return [
      ALL,
      AUTH_SUPPORT,
      EXTERNAL_ID
    ];
  }
}

export { TransactionSearchFilter }