/* tslint:disable */

/**
 * The charge type
 */
type ChargeType =
  'NORMAL' |
  'FAST' |
  'RAPID';
module ChargeType {
  export const NORMAL: ChargeType = 'NORMAL';
  export const FAST: ChargeType = 'FAST';
  export const RAPID: ChargeType = 'RAPID';
  export function values(): ChargeType[] {
    return [
      NORMAL,
      FAST,
      RAPID
    ];
  }
}

export { ChargeType }