/* tslint:disable */

/**
 * The vehicle type:
 *   * LIGHT:
 *   * LDV:
 *   * HEAVY:
 *   * HEAVY_WITH_TRAILER:
 *   * BIKE:
 *   * MOTORBIKE:
 *   * GARBAGE_TRUCK:
 *   * BUS:
 *   * BOAT_LINER
 *   * BOAT_TOURISTIC
 *   * UNDEFINED
 */
type VehicleType =
  'LIGHT' |
  'LDV' |
  'HEAVY' |
  'HEAVY_WITH_TRAILER' |
  'BIKE' |
  'MOTORBIKE' |
  'GARBAGE_TRUCK' |
  'BUS' |
  'BOAT_LINER' |
  'BOAT_TOURISTIC' |
  'UNDEFINED';
module VehicleType {
  export const LIGHT: VehicleType = 'LIGHT';
  export const LDV: VehicleType = 'LDV';
  export const HEAVY: VehicleType = 'HEAVY';
  export const HEAVY_WITH_TRAILER: VehicleType = 'HEAVY_WITH_TRAILER';
  export const BIKE: VehicleType = 'BIKE';
  export const MOTORBIKE: VehicleType = 'MOTORBIKE';
  export const GARBAGE_TRUCK: VehicleType = 'GARBAGE_TRUCK';
  export const BUS: VehicleType = 'BUS';
  export const BOAT_LINER: VehicleType = 'BOAT_LINER';
  export const BOAT_TOURISTIC: VehicleType = 'BOAT_TOURISTIC';
  export const UNDEFINED: VehicleType = 'UNDEFINED';
  export function values(): VehicleType[] {
    return [
      LIGHT,
      LDV,
      HEAVY,
      HEAVY_WITH_TRAILER,
      BIKE,
      MOTORBIKE,
      GARBAGE_TRUCK,
      BUS,
      BOAT_LINER,
      BOAT_TOURISTIC,
      UNDEFINED
    ];
  }
}

export { VehicleType }