/* tslint:disable */

/**
 * The synchronization state of the auth support:
 *   * IN_PROGRESS
 *   * MANAGED
 *   * SENT
 *   * RECEIVED
 */
type AuthSupportsRequestState =
  'IN_PROGRESS' |
  'MANAGED' |
  'SENT' |
  'RECEIVED';
module AuthSupportsRequestState {
  export const IN_PROGRESS: AuthSupportsRequestState = 'IN_PROGRESS';
  export const MANAGED: AuthSupportsRequestState = 'MANAGED';
  export const SENT: AuthSupportsRequestState = 'SENT';
  export const RECEIVED: AuthSupportsRequestState = 'RECEIVED';
  export function values(): AuthSupportsRequestState[] {
    return [
      IN_PROGRESS,
      MANAGED,
      SENT,
      RECEIVED
    ];
  }
}

export { AuthSupportsRequestState }