import { environment } from 'src/environments/environment';
import { TableDisplayMode } from '../../enum/table-display-mode';


export class TableConfiguration {

    readonly NO_DATA_ROW_MSG_DEFAULT = environment.core.TABLE_NO_DATA_MESSAGE;

    displayMode: TableDisplayMode;
    cssClass: string;
    dateFormat: string;
    nullValueDisplay: string;
    // no data
    whatShowIfNoData: 'HIDE_TABLE' | 'HEADER';
    displayMsgIfNoDataRow: boolean;
    positionMsgIfNoDataRow: 'BEFORE' | 'AFTER' | 'IN_BODY' | null;
    noDataRowMsg: string;
    headPosition: 'none' | 'top';
    selectRowMode: 'NONE' | 'UNIQUE' | 'MULTI'; // no row selectable / one row selectable / multi row selectable

    constructor() {
        this.displayMode = TableDisplayMode.FLEX_MODE;
        this.cssClass = null;
        this.dateFormat = 'dd/MM/yyyy HH:mm:ss';
        this.nullValueDisplay = 'N/A';
        this.whatShowIfNoData = 'HEADER';
        this.displayMsgIfNoDataRow = true;
        this.positionMsgIfNoDataRow = 'IN_BODY';
        this.noDataRowMsg = this.NO_DATA_ROW_MSG_DEFAULT;
        this.headPosition = 'top';
        this.selectRowMode = 'NONE';
    }

    public isDisplayedBefore(): boolean {
        return (this.positionMsgIfNoDataRow === 'BEFORE') ? true : false;
    }

    public isDisplayedAfter(): boolean {
        return (this.positionMsgIfNoDataRow === 'AFTER') ? true : false;
    }

    public isDisplayedInBody(): boolean {
        return (this.positionMsgIfNoDataRow === 'IN_BODY') ? true : false;
    }
}
