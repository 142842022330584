/* tslint:disable */

/**
 * Calculation function to be applied on a set of values:
 * * RAW: Return the raw value
 * * MIN: Return the minimum value in a given set of values
 * * MAX: Return the maximum value in a given set of values
 * * AVERAGE: Return the average of a set of values
 */
type CalculationFunctionType =
  'RAW' |
  'MIN' |
  'MAX' |
  'AVERAGE';
module CalculationFunctionType {
  export const RAW: CalculationFunctionType = 'RAW';
  export const MIN: CalculationFunctionType = 'MIN';
  export const MAX: CalculationFunctionType = 'MAX';
  export const AVERAGE: CalculationFunctionType = 'AVERAGE';
  export function values(): CalculationFunctionType[] {
    return [
      RAW,
      MIN,
      MAX,
      AVERAGE
    ];
  }
}

export { CalculationFunctionType }