export enum RouteId {
    // root
    DASHBOARD,
    EMS,
    DASHBOARD_GNL,
    DASHBOARD_CONNECTIONS,
    MAP,
    REGIONS,
    STATIONS,
    PRICE_UPDATE_REQUESTS,
    GRAPHS,
    CUSTOMER_ACCOUNTS,
    CUSTOMER_ACCOUNT,
    BADGES,
    BADGE,
    BADGE_REQUESTS,
    USERS,
    VEHICLES,
    NOTIFICATIONS,
    NOTIFICATIONS_HISTORY,
    NOTIFICATIONS_HISTORY_DETAILS,
    NOTIFICATIONS_NEWS,
    NOTIFICATIONS_NEWS_NEW,
    NOTIFICATIONS_NEWS_DETAILS,
    NOTIFICATIONS_CONFIG,
    NOTIFICATIONS_CONFIG_SYSTEM_DETAILS,
    NOTIFICATIONS_CONFIG_NOTE_DETAILS,
    NOTIFICATIONS_CONFIG_NEW,
    ADMINISTRATION,
    ADMINISTRATION_DETAILS,
    DETAILS,
    DIFFUSION_LIST,
    ROLES,
    ACQUISITION_UNIT,
    EXPORT,
    TICKETS_ROOT,
    TICKETS,
    TICKETS_DASHBOARD,
    DASHDEFAULT,
    DRAWGRAPHS,
    ALARMS,
    ALARMS_V2_PRIMENG,
    ACQUISITION_LOG,
    PUSH_NOTIFICATION,
    CALL_LOG,

    // station IDs
    STATION_DASHBOARD,
    STATION_DETAIL,
    STATION_CONFIGURATION,
    STATION_TRANSACTIONS,
    STATION_ALARMES,
    STATION_ACQUISITION_LOG,
    STATION_COURBES,
    STATION_CONSIGNATION_ETAT,
    STATION_SYNOPTIC,
    STATION_TICKETS,
    FLOORPLAN,
    CHARGES,
    STATION_DRIVE,

    // customer accounts IDs
    CUSTOMER_ACCOUNTS_NEW,
    CUSTOMER_ACCOUNTS_DETAIL,
    CUSTOMER_ACCOUNTS_STATIONS,
    CUSTOMER_ACCOUNTS_CONTACTS,
    CUSTOMER_ACCOUNTS_BADGES,
    CUSTOMER_ACCOUNTS_BADGES_REQUESTS,
    CUSTOMER_ACCOUNTS_TRANSACTIONS,
    CUSTOMER_ACCOUNTS_HISTORY,
    CUSTOMER_ACCOUNTS_VEHICLES,
    CUSTOMER_ACCOUNTS_EXPORTS,

    // badges IDs
    BADGES_LIST,
    BADGES_STOCK,
    BADGES_REQUESTS,
    BADGES_REQUESTS_NEW,
    BADGES_REQUESTS_DETAILS,
    BADGES_NEW,
    BADGE_DETAILS,
    BADGE_TRANSACTIONS,
    BADGE_HISTORY,
}
