import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep} from 'lodash';
import moment from 'moment';
import {ImmerComponentStore} from 'ngrx-immer/component-store';
import {combineLatestWith, debounceTime, delay, filter, map, Observable, of, switchMap, take, tap, withLatestFrom,} from 'rxjs';
import {isNotNullOrUndefined} from 'src/app/services/utils/filter-typeguard.util';
import {SortDirection} from '../../../../models/sortDirection';
import {isNullOrUndefined} from '../../../../services/utils/filter-typeguard.util';
import {TableCell} from '../../models/input/table-cell';
import {TableConfiguration} from '../../models/input/table-configuration';
import {TableHeader} from '../../models/input/table-header';
import {TableModel} from '../../models/input/table-model';
import {TableRows} from '../../models/input/table-rows';
import {TableTh} from '../../models/input/table-th';
import {ValueCell} from '../../models/input/value-cell';
import {TableTemplateModel} from '../../models/template-model/table-template-model';
import {DateValueModel} from '../filter-date/filter-date.component';

const loadingStateIdle = 'idle';
const loadingStateLoaded = 'loaded';
const loadingState = [loadingStateIdle, loadingStateLoaded] as const;
type LoadingStateType = (typeof loadingState)[number];

interface LoadingStateItem {
    loadingState: LoadingStateType;
}

/**
 * Define State
 * loadingStateItem: Local storage saving purpose
 * loading: template loading purpose
 * params: input params passed from Parent Component
 * table: converted table template from params
 * dataRow: decoupled from table in order to be updated continuously without changing the rest
 * sort: sorting purpose
 * filters: filtering purpose
 * showFilters: boolean
 * offset: for pagination purpose
 */
export type State = {
    loadingStateItem: LoadingStateItem;
    loading: boolean;
    params: TableModel | undefined;
    table: TableTemplateModel | undefined;
    dataRow: TableRows[] | undefined;
    sort: Sort | undefined;
    filters: Filter[] | undefined;
    showFilters: boolean;
    offset: Offset | undefined;
};

/**
 * Default state when component's just been created
 */
const defaultState: State = {
    loadingStateItem: {loadingState: loadingStateIdle},
    loading: false,
    params: undefined,
    table: undefined,
    dataRow: undefined,
    sort: undefined,
    filters: undefined,
    showFilters: false,
    offset: undefined,
};

/**
 * All needed data for HTML template rendering
 */
export type ViewConfig = {
    loading: boolean;
    configuration: TableConfiguration;
    header: TableHeader;
    dataRow: TableRows[];
    allDataRow: TableRows[];
    columnFilters: Filter[];
    globalFilter: Filter;
    sort: Sort | undefined;
    showFilters: boolean;
    showFiltersLabel: string;
    isFiltered: boolean;
    paginationEnabled: boolean;
    totalItems: number;
    mustDisplayNoDataMsgBefore: boolean;
    mustDisplayNoDataMsgAfter: boolean;
    getNbColumn: number;
};

export type Offset = {
    low: number;
    high: number;
};

export type Sort = {
    id: string;
    direction: SortDirection;
};

export type Filter = {
    type: FilterType;
    dataType: ValueCell['dataType'];
    id: string;
    query: string;
    isFilterable: boolean;
    columnId?: string;
    selectableValues?: any;
};

enum FilterType {
    COLUMN = 'COLUMN',
    GLOBAL = 'GLOBAL',
}

@Injectable()
export class TableStore extends ImmerComponentStore<State> {
    readonly table$ = this.select((state) => state.table);
    /**
     * Updaters
     * We don't do any side effects here, only for updating states
     */
    readonly updateParams = this.updater<TableModel>((state, value) => {
        state.params = value;
    });
    readonly updateLoading = this.updater<boolean>((state, value) => {
        state.loading = value;
    });
    readonly updateOffset = this.updater<Offset>((state, value) => {
        state.offset = value;
    });
    readonly updateTable = this.updater<TableTemplateModel>((state, value) => {
        state.table = value;
    });
    readonly updateDataRow = this.updater<TableRows[]>((state, value) => {
        state.dataRow = value;
    });
    readonly updateFilters = this.updater<Filter[]>((state, value) => {
        state.filters = value;
    });
    readonly updateSort = this.updater<Sort>((state, value) => {
        state.sort = value;
    });
    readonly updateShowFilters = this.updater<boolean>((state, value) => {
        state.showFilters = value;
    });
    readonly updateLoadingStateItem = this.updater<LoadingStateItem>(
        (state, value) => {
            state.loadingStateItem = value;
        }
    );
    /**
     * Effects
     * Actions that change states
     */
    readonly updateParamsEffect = this.effect<TableModel>(($) =>
        $.pipe(
            debounceTime(500),
            tap((table: TableModel) => {
                this.updateParams(table);
            })
        )
    );
    readonly unselectAllRowsEffect = this.effect(($) =>
        $.pipe(
            withLatestFrom(this.table$.pipe(filter(isNotNullOrUndefined))),
            tap(([, table]) => {
                table.unselectAllRows();
                this.updateDataRow(table.dataRow);
            })
        )
    );
    readonly updateOffsetEffect = this.effect<Offset>(($) =>
        $.pipe(
            tap((offset: Offset) => {
                this.updateLoading(false);
                this.updateOffset(offset);
            })
        )
    );
    readonly setOffsetIfPaginationDisabled = this.effect<TableModel>(($) =>
        $.pipe(
            delay(500), // needed time for store to be stabled
            tap((table: TableModel) => {
                if (table.paginationEnabled === false) {
                    this.updateLoading(false);
                    this.updateOffset({low: 0, high: 50});
                }
            })
        )
    );
    /**
     * Selectors (observables) that can be subscribed
     */
    private readonly params$ = this.select((state) => state.params);
    private readonly loadingStateItem$ = this.select(
        (state) => state.loadingStateItem
    );
    private readonly loadingState$ = this.select(
        this.loadingStateItem$,
        (state) => state.loadingState
    );
    private readonly dataRows$ = this.select((state) => state.dataRow);
    private readonly filters$ = this.select((state) => state.filters);
    readonly updateFilterEffect = this.effect<Partial<Filter>>(($) =>
        $.pipe(
            debounceTime(500),
            tap(() => this.updateLoading(true)),
            withLatestFrom(this.filters$),
            tap(([filter, filters]) => {
                const newFilters = (filters ?? [])
                    .map((f) =>
                        f.id === filter.id && isNotNullOrUndefined(filter.query)
                            ? {...f, query: filter.query.toLowerCase()}
                            : f
                    )
                    .filter(isNotNullOrUndefined);
                this.updateFilters(newFilters);
            }),
            delay(800), // needed delay to get stable viewConfig
            tap(() => this.updateLoading(false))
        )
    );
    readonly removeFiltersEffect = this.effect(($) =>
        $.pipe(
            tap(() => this.updateLoading(true)),
            withLatestFrom(this.filters$.pipe(filter(isNotNullOrUndefined))),
            tap(([, filters]) => {
                const newFilters = filters.map((f) => ({...f, query: ''}));
                this.updateFilters(newFilters);
            }),
            delay(800), // needed delay to get stable viewConfig
            tap(() => this.updateLoading(false))
        )
    );
    private readonly offset$ = this.select((state) => state.offset);
    private readonly sort$ = this.select((state) => state.sort);
    private readonly showFilters$ = this.select((state) => state.showFilters);
    readonly toggleShowFilterEffect = this.effect(($) =>
        $.pipe(
            withLatestFrom(this.showFilters$),
            tap(([, showFilters]) => {
                this.updateShowFilters(!showFilters);
            })
        )
    );
    private readonly urlPath$ = of(window.location.pathname);
    // We create a tableId based on current url and header columnId for filters and sorts saving purpose
    private readonly tableId$ = this.select(
        this.params$.pipe(filter(isNotNullOrUndefined)),
        this.urlPath$,
        (params, path) => {
            const id = `${TABLE_ID_PREFIX}::${path}::${params.header.cells
                .map((cell) => cell.columnId ?? '')
                .join('::')}`;
            return id;
        }
    );
    private readonly ready$ = this.loadingState$.pipe(
        map((loadingState) => loadingState === loadingStateLoaded)
    );
    // We need to wait for filtering and sorting to be get from local storage first before setting the Table
    private readonly setTableTriggers$ = this.select(
        this.params$.pipe(filter(isNotNullOrUndefined)),
        this.ready$.pipe(filter((ready) => ready)),
        (table, ready) => table
    );
    private readonly loading$ = this.select((state) => state.loading);
    readonly loaded$ = this.select(this.loading$, (loading) => !loading);
    private readonly setTableEffect = this.effect<TableModel>(($) =>
        $.pipe(
            tap((params: TableModel) => {
                const table = this.tableMapping(params);
                this.updateTable(table);
            })
        )
    );
    private readonly setDataRowEffect = this.effect<TableTemplateModel>(($) =>
        $.pipe(
            tap((table: TableTemplateModel) => {
                this.updateDataRow(table.dataRow);
            })
        )
    );
    // Get saved filters and sorts if there are
    private readonly loadStateEffect = this.effect<State>((state$) =>
        state$.pipe(
            filter(
                ({loadingStateItem}) =>
                    loadingStateItem.loadingState === loadingStateIdle
            ),
            combineLatestWith(this.tableId$.pipe(take(1))),
            switchMap(([, id]) =>
                of(window.localStorage.getItem(id)).pipe(
                    combineLatestWith(this.params$.pipe(filter(isNotNullOrUndefined))),
                    tap(([props, params]) => {
                        if (isNotNullOrUndefined(props)) {
                            const parsedProps = JSON.parse(props);
                            this.setState({
                                ...defaultState,
                                ...parsedProps,
                                params,
                                loadingStateItem: {loadingState: loadingStateLoaded},
                            });
                        } else {
                            this.patchState({
                                loadingStateItem: {loadingState: loadingStateLoaded},
                            });
                        }
                    })
                )
            )
        )
    );
    // Save continuously filters and sorts related when state changes
    private readonly saveStateEffect = this.effect<State>((state$) =>
        state$.pipe(
            filter(
                ({loadingStateItem}) =>
                    loadingStateItem.loadingState === loadingStateLoaded
            ),
            withLatestFrom(this.tableId$),
            tap(([{filters, sort, showFilters, offset}, id]) => {
                const stringifiedProps = JSON.stringify({filters, sort, showFilters, offset});
                window.localStorage.setItem(id, stringifiedProps);
            })
        )
    );
    // Debugging purpose
    private readonly loggerEffect = this.effect<any>(($) =>
        $.pipe(tap((state) => console.log('[TableStore]', state)))
    );

    constructor(private readonly translate: TranslateService) {
        super(defaultState);
        // this.loggerEffect(this.state$);

        this.loadStateEffect(this.state$.pipe(take(1)));
        this.saveStateEffect(this.state$);

        // Self-set default effects to Store State
        this.setTableEffect(this.setTableTriggers$);
        this.setFiltersEffect(this.table$.pipe(filter(isNotNullOrUndefined)));
        this.setDataRowEffect(this.table$.pipe(filter(isNotNullOrUndefined)));

        this.setOffsetIfPaginationDisabled(this.setTableTriggers$);
    }

    headerMapping(table: TableModel): TableHeader {
        const tableInput = table;
        tableInput.header.row.id = this.generateAndGetRowIdForHeader();
        const cells = this.cellMapping(
            tableInput.header.cells,
            tableInput.header.cells
        ) as TableTh[];

        const tableHeader = new TableHeader();
        tableHeader.cells = cells;
        tableHeader.row = tableInput.header.row;
        return tableHeader;
    }

    rowMapping(table: TableModel): TableRows[] {
        const dataRowMapped = table.dataRow.map((dataRow, i) => {
            dataRow.row.id = this.generateAndGetRowIdForDataRow(i);
            const cells = this.cellMapping(table.header.cells, dataRow.cells);
            dataRow.cells = cells;
            return dataRow;
        });

        return dataRowMapped;
    }

    cellMapping(cellThList: TableTh[], cells: TableCell[]): TableCell[] {
        const cellsMapped = cells.map((cell, i) => {
            cell.columnId = cell.columnId
                ? cell.columnId
                : this.generateAndGetCellColumnId(cellThList[i]);
            return cell;
        });
        return cellsMapped;
    }

    /**
     * Generate an Id for each cell.
     * Format : columnId
     */
    generateAndGetCellColumnId(cellTh: TableTh): string | undefined {
        return cellTh ? cellTh.columnId : undefined;
    }

    /**
     * Generate an Id for the header row. set to '0' cause: it's the first row of table
     */
    generateAndGetRowIdForHeader(): string {
        return HEADER_ROW_ID;
    }

    /**
     * Generate an Id for the data rows
     * @param i DataRow index
     */
    generateAndGetRowIdForDataRow(i: number): string {
        return i.toString();
    }

    /**
     * Check if the column is filterable and sortable
     * @param headerCell
     * @returns
     */
    readonly isFilterableSortable = (headerCell: TableCell) => {
        const columnId = headerCell.columnId;
        if (columnId) {
            return !['MULTI_SELECTION', 'ACTION', 'COLLAPSE_ICON'].includes(columnId);
        }
        const valueCell = headerCell.getFirst();
        return valueCell.tagType === 'span';
    };

    private readonly setFiltersEffect = this.effect<TableTemplateModel>(($) =>
        $.pipe(
            withLatestFrom(this.filters$),
            tap(([table, filters]) => {
                const header = table.header;
                const mapFilters: Map<string, string | undefined> = new Map(
                    (filters ?? []).map(({id, columnId, query}) => [
                        columnId ?? id,
                        query,
                    ]) || []
                );
                const columnFilters: Filter[] = header.cells
                    .map((c, index) => {
                        const dataType = toFilterType(table, index);
                        return c.columnId && c.show
                            ? {
                                type: FilterType.COLUMN,
                                id: `${index}-${c.columnId}`,
                                query: mapFilters.get(c.columnId) ?? '',
                                isFilterable: this.isFilterableSortable(c),
                                columnId: c.columnId,
                                dataType,
                                selectableValues: getSelectableValue(c, dataType),
                            }
                            : undefined;
                    })
                    .filter(isNotNullOrUndefined);
                const globalFilter: Filter = {
                    type: FilterType.GLOBAL,
                    id: FilterType.GLOBAL,
                    query: mapFilters.get(FilterType.GLOBAL) ?? '',
                    isFilterable: true,
                    dataType: 'STRING',
                };
                this.updateFilters([...columnFilters, globalFilter]);
            })
        )
    );

    /**
     * Get target value to be filtered / sorted
     * @param cells
     * @param sort
     * @returns
     */
    readonly getTarget = (cells: TableCell[], sort: Sort) => {
        const {id} = sort;
        const target = cells.find((r, i) => `${i}-${r.columnId}` === id);
        const displayedValue = target?.getFirst().displayedValue;
        if (!displayedValue) {
            return '';
        }
        return displayedValue;
    };

    // Mappers
    private tableMapping(table: TableModel) {
        const newTable = new TableTemplateModel();

        newTable.configuration = this.configurationMapping(table);
        newTable.header = this.headerMapping(table);
        newTable.dataRow = this.rowMapping(table);

        return newTable;
    }

    private configurationMapping(table: TableModel): TableConfiguration {
        const configuration = new TableConfiguration();
        configuration.displayMode = table.configuration.displayMode;
        configuration.cssClass = table.configuration.cssClass;
        configuration.displayMsgIfNoDataRow =
            table.configuration.displayMsgIfNoDataRow;
        configuration.nullValueDisplay = table.configuration.nullValueDisplay;
        configuration.positionMsgIfNoDataRow =
            table.configuration.positionMsgIfNoDataRow;
        configuration.whatShowIfNoData = table.configuration.whatShowIfNoData;
        configuration.headPosition = table.configuration.headPosition;
        configuration.selectRowMode = table.configuration.selectRowMode;
        const noDatMsg = table.configuration.noDataRowMsg;
        configuration.noDataRowMsg = noDatMsg
            ? noDatMsg
            : table.configuration.NO_DATA_ROW_MSG_DEFAULT;

        return configuration;
    }

    /**
     * Filter and sort the data rows
     * @param dataRow
     * @param filters
     * @param sort
     * @returns
     */
    private readonly filterDataRow = (
        dataRow: TableRows[],
        filters: Filter[] | undefined,
        sort: Sort | undefined
    ): TableRows[] => {
        if (!filters) {
            return dataRow;
        }

        let filteredDataRow = cloneDeep(dataRow);

        filters
            .filter((f) => f.isFilterable && (f.query ?? '').length > 0)
            .forEach((filter) => {
                filteredDataRow = filteredDataRow.filter((dr) => {
                    const query = filter.query;
                    if (isNullOrUndefined(query) || query.length === 0) {
                        return true;
                    }

                    // Global filter
                    if (filter.type === FilterType.GLOBAL) {
                        return dr.cells.some((c) =>

                            c
                                .getFirst()
                                ?.displayedValue?.toString()
                                .toLowerCase()
                                .includes(query.toLowerCase())
                        );

                    }

                    // Column filter
                    const target = dr.cells.find(
                        (c, index) => `${index}-${c.columnId}` === filter.id
                    );
                    if (isNullOrUndefined(target)) {
                        return true;
                    }

                    const value = target.getFirst();
                    if (
                        isNullOrUndefined(value) ||
                        isNullOrUndefined(value.displayedValue)
                    ) {
                        // Filter out only if query is set
                        return filter.query.length === 0;
                    }

                    const displayedValue = value.displayedValue;
                    if (filter.dataType === 'DATE') {
                        const parsedValue = JSON.parse(filter.query) as DateValueModel;
                        const {start, end} = parsedValue;
                        const endMidnight = moment(end)
                            .add(1, 'day')
                            .add(-1, 'second')
                            .toISOString();
                        if (isNullOrUndefined(start) && isNullOrUndefined(end)) {
                            return true;
                        }
                        return (
                            moment(displayedValue).diff(moment(start), 's') >= 0 &&
                            moment(displayedValue).diff(moment(endMidnight), 's') <= 0
                        );
                    }
                    if (filter.dataType === 'ENUM') {
                        // the query string reprensents in fact a pipe-separated array that has to be splat
                        const arrayQuery = query ? query.toUpperCase().split('|') : [];
                        return arrayQuery.find((q) =>
                            target.valueCells.find(
                                (v) =>
                                    (v.displayedValue &&
                                        v.displayedValue.toString().toLowerCase() ===
                                        q.toLowerCase()) ||
                                    (v.tooltip && v.tooltip.toLowerCase() === q.toLowerCase())
                            )
                        );
                    }
                    // Collect all based value to filter out
                    const basedValue = target.valueCells
                        .map(
                            ({displayedValue, tooltip}) =>
                                `${displayedValue ?? ''} ${tooltip ?? ''}`
                        )
                        .join(' ');

                    return basedValue.toLowerCase().includes(query.toLowerCase());
                });
            });
        if (!sort) {
            return filteredDataRow;
        }
        filteredDataRow.sort((a, b) => {
            const targetLeft = this.getTarget(a.cells, sort);
            const targetRight = this.getTarget(b.cells, sort);
            if (typeof targetLeft === 'string' && typeof targetRight === 'string') {
                return sort.direction === SortDirection.DESC
                    ? targetLeft.localeCompare(targetRight)
                    : targetRight.localeCompare(targetLeft);
            }
            return (targetLeft as number) - (targetRight as number);
        });
        return filteredDataRow;
    };

    // For rendering HTML template, we create an Observable that is constructed by different other ones (streams) and updated as desired
    readonly viewConfig$: Observable<ViewConfig> = this.select(
        this.table$.pipe(filter(isNotNullOrUndefined)),
        this.dataRows$.pipe(filter(isNotNullOrUndefined)),
        this.filters$.pipe(filter(isNotNullOrUndefined)),
        this.sort$,
        this.showFilters$,
        this.offset$,
        this.params$.pipe(filter(isNotNullOrUndefined)),
        this.loading$,
        (
            table,
            dataRow,
            filters,
            sort,
            showFilters,
            offset,
            {paginationEnabled},
            loading
        ) => {
            const filteredDataRow = this.filterDataRow(dataRow, filters, sort);
            const columnFilters = filters.filter((f) => f.type === FilterType.COLUMN);
            const globalFilter = filters.filter(
                (f) => f.type === FilterType.GLOBAL
            )[0];

            return {
                loading,
                configuration: table.configuration,
                header: table.header,
                dataRow: isNullOrUndefined(offset)
                    ? []
                    : filteredDataRow.slice(offset.low, offset.high),
                allDataRow: filteredDataRow,
                columnFilters,
                globalFilter,
                sort,
                isFiltered:
                    filters.filter((f) => f.isFilterable && (f.query ?? '').length > 0)
                        .length > 0,
                showFilters,
                showFiltersLabel: this.translate.instant(
                    !showFilters
                        ? 'action.toggle_filter.show_filters'
                        : 'action.toggle_filter.hide_filters'
                ),
                paginationEnabled,
                totalItems: filteredDataRow.length,
                mustDisplayNoDataMsgBefore: table.mustDisplayNoDataMsgBefore(),
                mustDisplayNoDataMsgAfter: table.mustDisplayNoDataMsgAfter(),
                getNbColumn: table.getNbColumn(),
            };
        }
    );

    private readonly toSortDirection = (
        id: string,
        sort: Sort | undefined
    ): SortDirection => {
        if (!sort) {
            const sortDirection = SortDirection.ASC;
            return sortDirection;
        }
        return sort.direction === SortDirection.ASC
            ? SortDirection.DESC
            : SortDirection.ASC;
    };

    readonly updateSortEffect = this.effect<string>(($) =>
        $.pipe(
            withLatestFrom(this.sort$),
            tap(([id, sort]) => {
                const newSort = {
                    id,
                    direction: this.toSortDirection(id, sort),
                };
                this.updateSort(newSort);
            })
        )
    );
}

/**
 * Get dataType from same column (index) of Header
 * @param table
 * @param index
 * @returns
 */
const toFilterType = (table: TableTemplateModel, index: number) => {
    return table.dataRow[0]?.cells?.length >= index
        ? table.dataRow[0]?.cells[index].getFirst()?.dataType || 'STRING'
        : 'STRING';
};

const getSelectableValue = (c: TableTh, dataType: string) => {
    return dataType === 'ENUM' ? c.getFirst().data || undefined : undefined;
};

const HEADER_ROW_ID = 'h';

export const TABLE_ID_PREFIX = 'TABLE-STORE';
