/* tslint:disable */

/**
 * Type kind of the charging profile
 */
type ChargePointChargingProfileKindType =
  'ABSOLUTE' |
  'RELATIVE' |
  'RECURRING';
module ChargePointChargingProfileKindType {
  export const ABSOLUTE: ChargePointChargingProfileKindType = 'ABSOLUTE';
  export const RELATIVE: ChargePointChargingProfileKindType = 'RELATIVE';
  export const RECURRING: ChargePointChargingProfileKindType = 'RECURRING';
  export function values(): ChargePointChargingProfileKindType[] {
    return [
      ABSOLUTE,
      RELATIVE,
      RECURRING
    ];
  }
}

export { ChargePointChargingProfileKindType }