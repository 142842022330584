import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RouteId } from 'src/app/enums/env/routes-id-enum';
import { BaseFeatureGuard } from 'src/app/models/BaseFeatureGuard';
import { RightService } from 'src/app/services/right.service';
import { RoutingService } from 'src/app/services/Routing.service';
import { User } from 'src/app/shared/models/user/user';
import { UserSessionService } from 'src/app/tinea-components/okta/services/session/user-session.service';
import { ApplicationFeature } from 'src/swagger-gen/web-secured/models';


@Injectable({
  providedIn: 'root'
})
export class StationGuard extends BaseFeatureGuard implements CanActivate {

  state: RouterStateSnapshot;
  route: ActivatedRouteSnapshot;
  isRefreshPage = true;

  constructor(public router: Router,
              public userSessionService: UserSessionService,
              protected rightService: RightService) {
    super(rightService);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.state = state;
      this.route = route;
      
      const u: User = this.userSessionService.getUser();
      const hasSiteManagement = u.hasFeatureSiteManagement();
      const hasSiteOperation = u.hasFeatureSiteOperation();

      let feature: ApplicationFeature;
      if (hasSiteManagement) {
        feature = ApplicationFeature.SITE_MANAGEMENT;
      } else if (hasSiteOperation) {
        feature = ApplicationFeature.SITE_OPERATION;
      }

      if (route.url.some(u=> u.path.includes(RoutingService.getPathRoute(RouteId.PRICE_UPDATE_REQUESTS)))) {
        feature = ApplicationFeature.PRICE;
      }

      return this.handler(feature);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }

}
