/* tslint:disable */

/**
 * Type recurrency kind of the charging profile
 */
type ChargePointChargingProfileRecurrencyKindType =
  'DAILY' |
  'WEEKLY';
module ChargePointChargingProfileRecurrencyKindType {
  export const DAILY: ChargePointChargingProfileRecurrencyKindType = 'DAILY';
  export const WEEKLY: ChargePointChargingProfileRecurrencyKindType = 'WEEKLY';
  export function values(): ChargePointChargingProfileRecurrencyKindType[] {
    return [
      DAILY,
      WEEKLY
    ];
  }
}

export { ChargePointChargingProfileRecurrencyKindType }