/* tslint:disable */

/**
 * The list of available OCPP versions :
 * * V16J : OCPP Json 1.6
 */
type OcppVersion =
  'V16J';
module OcppVersion {
  export const V16J: OcppVersion = 'V16J';
  export function values(): OcppVersion[] {
    return [
      V16J
    ];
  }
}

export { OcppVersion }