import { DateRangeCubeJs } from 'src/app/enums/cubejs/date-range';

export class DateRangeModel {

    name: string; // i18n
    value: DateRangeCubeJs;

    constructor(name: string, value: DateRangeCubeJs) {
        this.name = name;
        this.value = value;
    }
}
