import { TableRow } from './table-row';
import { TableTh } from './table-th';

export class TableHeader {
    row: TableRow;
    cells: TableTh[];

    constructor() {
        this.row = new TableRow();
        this.cells = [];
    }
}
