import {NgModule} from '@angular/core';
import {FileUploadComponent} from './file-upload.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {LoadingModule} from '../loading/loading.module';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [FileUploadComponent],
  imports: [
    CommonModule,
    TranslateModule,
    LoadingModule,
    TooltipModule
  ],
  exports: [FileUploadComponent]
})
export class FileUploadModule { }
