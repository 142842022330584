/* tslint:disable */

/**
 * The types of connection:
 *   * CONSUMPTION
 *   * PRODUCTION
 */
type EnedisConnectionType =
  'CONSUMPTION' |
  'PRODUCTION';
module EnedisConnectionType {
  export const CONSUMPTION: EnedisConnectionType = 'CONSUMPTION';
  export const PRODUCTION: EnedisConnectionType = 'PRODUCTION';
  export function values(): EnedisConnectionType[] {
    return [
      CONSUMPTION,
      PRODUCTION
    ];
  }
}

export { EnedisConnectionType }