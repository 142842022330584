/* tslint:disable */

/**
 * field to filter for ticket search:
 *   * OPENED
 *   * TRANSFERED
 *   * IN_PROGRESS
 *   * ESTIMATED
 *   * START_ESTIMATED
 *   * CLOSED
 */
type TicketState =
  'OPENED' |
  'TRANSFERED' |
  'IN_PROGRESS' |
  'ESTIMATED' |
  'START_ESTIMATED' |
  'CLOSED';
module TicketState {
  export const OPENED: TicketState = 'OPENED';
  export const TRANSFERED: TicketState = 'TRANSFERED';
  export const IN_PROGRESS: TicketState = 'IN_PROGRESS';
  export const ESTIMATED: TicketState = 'ESTIMATED';
  export const START_ESTIMATED: TicketState = 'START_ESTIMATED';
  export const CLOSED: TicketState = 'CLOSED';
  export function values(): TicketState[] {
    return [
      OPENED,
      TRANSFERED,
      IN_PROGRESS,
      ESTIMATED,
      START_ESTIMATED,
      CLOSED
    ];
  }
}

export { TicketState }