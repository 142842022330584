export enum TransactionEnergyColor {
    BIOGNC = '#3B7302',
    GNC = '#A4BF69',
    GNL = '#1A4C89',    
    GNCL = '#8ab9bf',
    H2 = '#FFA500',
    ELEC = '#d9b989',
    OTHER = '#F9E196',
    DEFAULT = '#d59bcd',

    BIOGNC_HOVER = 'rgba(59,115,2,0.7)',
    GNC_HOVER = 'rgba(164,191,105,0.7)',
    GNL_HOVER = 'rgba(24,72,127,0.7)',
    GNCL_HOVER = 'rgba(167,209,216,0.7)',
    H2_HOVER = 'rgba(255,187,65,0.7)',
    ELEC_HOVER = 'rgba(230,208,168,0.7)',
    OTHER_HOVER = 'rgba(251,233,205,0.7)',
    DEFAULT_HOVER = 'rgba(204,159,199,0.7)'
}