/* tslint:disable */

/**
 * Line Graph Type Echart:
 *   * SOLID : solid
 *   * DASHED : dashed
 *   * DOTTED: dotted
 */
type LineGraphType =
  'SOLID' |
  'DASHED' |
  'DOTTED';
module LineGraphType {
  export const SOLID: LineGraphType = 'SOLID';
  export const DASHED: LineGraphType = 'DASHED';
  export const DOTTED: LineGraphType = 'DOTTED';
  export function values(): LineGraphType[] {
    return [
      SOLID,
      DASHED,
      DOTTED
    ];
  }
}

export { LineGraphType }