/* tslint:disable */
type PreselectionDateType =
  'TODAY' |
  'LAST24HOURS' |
  'LASTWEEK' |
  'LASTMONTH' |
  'LAST3MONTHS' |
  'CUSTOM';
module PreselectionDateType {
  export const TODAY: PreselectionDateType = 'TODAY';
  export const LAST24HOURS: PreselectionDateType = 'LAST24HOURS';
  export const LASTWEEK: PreselectionDateType = 'LASTWEEK';
  export const LASTMONTH: PreselectionDateType = 'LASTMONTH';
  export const LAST3MONTHS: PreselectionDateType = 'LAST3MONTHS';
  export const CUSTOM: PreselectionDateType = 'CUSTOM';
  export function values(): PreselectionDateType[] {
    return [
      TODAY,
      LAST24HOURS,
      LASTWEEK,
      LASTMONTH,
      LAST3MONTHS,
      CUSTOM
    ];
  }
}

export { PreselectionDateType }