/* tslint:disable */

/**
 * The list of available charge point models :
 * * EVLINK: EVLINK chargePoint by Schneider
 * * NBW30: NBw 30 chargePoint by Power Electronics
 * * KEYWATT24_STATION: Keywatt 24 Station chargepoint by IES
 * * C500: chargePoint by Kempower
 * * AXON_EASY: chargePoint by ekoenergetyka
 * * JEMA: chargePoint by IRIZAR
 */
type ChargePointModel =
  'EVLINK' |
  'NBW30' |
  'KEYWATT24_STATION' |
  'C500' |
  'AXON_EASY' |
  'JEMA';
module ChargePointModel {
  export const EVLINK: ChargePointModel = 'EVLINK';
  export const NBW30: ChargePointModel = 'NBW30';
  export const KEYWATT24_STATION: ChargePointModel = 'KEYWATT24_STATION';
  export const C500: ChargePointModel = 'C500';
  export const AXON_EASY: ChargePointModel = 'AXON_EASY';
  export const JEMA: ChargePointModel = 'JEMA';
  export function values(): ChargePointModel[] {
    return [
      EVLINK,
      NBW30,
      KEYWATT24_STATION,
      C500,
      AXON_EASY,
      JEMA
    ];
  }
}

export { ChargePointModel }