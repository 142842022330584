import {BehaviorSubject} from 'rxjs';
import {CubeJsChartType} from 'src/app/enums/cubejs-chart-type';
import {DateRangeCubeJs} from 'src/app/enums/cubejs/date-range';
import {GranularityCubeJs} from 'src/app/enums/cubejs/granularity';
import {CubeJsService} from 'src/app/services/cubeJs.service';
import {UiLoadingModel} from 'src/app/tinea-components/loading/models/UiLoadingModel';
import {environment} from 'src/environments/environment';
import {GranularityModel} from './granularityModel';
import * as moment from 'moment';
import {CalendarValue, IDatePickerConfig} from 'ng2-date-picker';
import {DateRangeModel} from './DateRangeModel';
import {GraphType} from 'src/app/enums/cubejs/graph-type';
import {ConvertValueModel} from './ConvertValueModel';
import {EnergyType} from 'src/swagger-gen/web-secured/models';
import {coreEnvironment} from 'src/environments/core/core.environment';
import {XAxisLabelEnum} from '../../enums/cubejs/x-axis-label.enum';

export abstract class BaseCubeJsGraphModel {

    DEFAULT_CHART_TYPE = CubeJsChartType.DOUGHNUT;

    hasError = false;
    loadingModel: UiLoadingModel;

    granularityList: GranularityModel[];
    currentGranularity: GranularityCubeJs;
    currentDateRange: DateRangeCubeJs | string[];
    dateRangeList: DateRangeModel[];
    currentXAxis: XAxisLabelEnum = XAxisLabelEnum.DATE;
    xAxisLabelList: XAxisLabelEnum[];

    cubeQuery = new BehaviorSubject(null);
    chartType = new BehaviorSubject(null);
    pivotConfig = new BehaviorSubject(null);

    defaultQuery: any;
    defaultFilter: any;

    userWantsCustomDateRange: boolean;

    unit: BehaviorSubject<string>;
    graphType: BehaviorSubject<GraphType>;
    convertValue = new BehaviorSubject<ConvertValueModel[]>([]);

    hasGranularity = true;

    endDateFilter: moment.Moment;
    startDateFilter: moment.Moment;
    isValidDateRange: boolean;
    // date picker
    language: 'fr' | 'en' = 'fr';
    datePickerConfig: IDatePickerConfig = {
        disableKeypress: false,
        format: 'DD/MM/YYYY',
        monthFormat: 'MMMM, YYYY',
        firstDayOfWeek: 'mo',
        showTwentyFourHours: true,
        showSeconds: false,
        showGoToCurrent: true,
        showNearMonthDays: false,
        hideOnOutsideClick: true,
        enableMonthSelector: true,
        allowMultiSelect: false
    };
    endDatePickerConfig = this.datePickerConfig;
    startDatePickerConfig = this.datePickerConfig;

    constructor(protected cubeJsService: CubeJsService) {
        this.unit = new BehaviorSubject(' ');
    }

    static getPrefixEnergyByEnergyType(energy: EnergyType | null) {
        return coreEnvironment.mapEnergyTypeWithEnergyCube.find(e => e.energyType === energy)?.cubeJsKey || '';
    }

    onHasError(event: boolean): void {
        this.hasError = event;
    }

    setDefaultLoading(): void {
        const model = new UiLoadingModel();
        this.loadingModel = model;
    }

    changeChartType(chartType: CubeJsChartType): void {
        this.chartType.next(chartType);
    }

    /**
     *
     * @param dateRangeEvent from mat-select
     */
    onChangeDateRange(dateRange: DateRangeCubeJs) {
        if (dateRange) {
            this.setGranularityListByDateRange(dateRange);
            this.handlerOnChangeDateRange(dateRange);
            this.setDateRangeOfTimeDimension(dateRange);

            this.cubeQuery.next(this.defaultQuery);
        }
    }

    setGranularityListByDateRange(dateRange: DateRangeCubeJs) {
        switch (dateRange) {
            case DateRangeCubeJs.CUSTOM:
                this.granularityList = this.cubeJsService.getGranularityList(environment.core.granularityBlackList.customRange);
                break;
            case DateRangeCubeJs.TODAY:
            case DateRangeCubeJs.YESTERDAY:
                this.granularityList = this.cubeJsService.getGranularityList(environment.core.granularityBlackList.dayRange);
                break;
            case DateRangeCubeJs.THIS_WEEK:
            case DateRangeCubeJs.LAST_WEEK:
            case DateRangeCubeJs.LAST_7_DAYS:
                this.granularityList = this.cubeJsService.getGranularityList(environment.core.granularityBlackList.weekRange);
                break;
            case DateRangeCubeJs.THIS_MONTH:
            case DateRangeCubeJs.LAST_MONTH:
            case DateRangeCubeJs.LAST_30_DAYS:
                this.granularityList = this.cubeJsService.getGranularityList(environment.core.granularityBlackList.monthRange);
                break;
            case DateRangeCubeJs.THIS_QUARTER:
            case DateRangeCubeJs.LAST_QUARTER:
                this.granularityList = this.cubeJsService.getGranularityList(environment.core.granularityBlackList.quarterRange);
                break;
            case DateRangeCubeJs.THIS_YEAR:
            case DateRangeCubeJs.LAST_YEAR:
                this.granularityList = this.cubeJsService.getGranularityList(environment.core.granularityBlackList.yearRange);
                break;
            default:
                console.error('setGranularityListByDateRange: unknown DateRangeCubeJs');
                this.granularityList = this.cubeJsService.getGranularityList();
                break;
        }
    }

    handlerOnChangeDateRange(dateRange: DateRangeCubeJs): void {
        switch (dateRange) {
            case DateRangeCubeJs.CUSTOM:
                this.changeCurrentGranularity(this.cubeJsService.getGranularityList(environment.core.granularityBlackList.customRange));
                break;
            case DateRangeCubeJs.TODAY:
            case DateRangeCubeJs.YESTERDAY:
                this.changeCurrentGranularity(this.cubeJsService.getGranularityList(environment.core.granularityBlackList.dayRange));
                break;
            case DateRangeCubeJs.THIS_WEEK:
            case DateRangeCubeJs.LAST_WEEK:
            case DateRangeCubeJs.LAST_7_DAYS:
                this.changeCurrentGranularity(this.cubeJsService.getGranularityList(environment.core.granularityBlackList.weekRange));
                break;
            case DateRangeCubeJs.THIS_MONTH:
            case DateRangeCubeJs.LAST_MONTH:
            case DateRangeCubeJs.LAST_30_DAYS:
                this.changeCurrentGranularity(this.cubeJsService.getGranularityList(environment.core.granularityBlackList.monthRange));
                break;
            case DateRangeCubeJs.THIS_QUARTER:
            case DateRangeCubeJs.LAST_QUARTER:
                this.changeCurrentGranularity(this.cubeJsService.getGranularityList(environment.core.granularityBlackList.quarterRange));
                break;
            case DateRangeCubeJs.THIS_YEAR:
            case DateRangeCubeJs.LAST_YEAR:
                this.changeCurrentGranularity(this.cubeJsService.getGranularityList(environment.core.granularityBlackList.yearRange));
                break;
            default:
                console.error('setGranularityListByDateRange: unknown DateRangeCubeJs');
                this.granularityList = this.cubeJsService.getGranularityList();
                break;
        }

        this.userWantsCustomDateRangeHandler(dateRange);
    }

    changeCurrentGranularity(whiteList: GranularityModel[]) {
        if (this.hasGranularity) {
            const currentGranularityInWhiteList = whiteList.find(g => g.value === this.currentGranularity);
            if (currentGranularityInWhiteList === undefined) {
                this.currentGranularity = whiteList[0].value;
                this.onChangeGranularity(whiteList[0].value);
            }
        }
    }

    /**
     *
     * @param granularityEvent from mat-select
     */
    onChangeGranularity(granularity: GranularityCubeJs) {
        if (granularity) {
            this.defaultQuery.timeDimensions[0].granularity = granularity;

            this.cubeQuery.next(this.defaultQuery);
        }
    }

    userWantsCustomDateRangeHandler(dateRange: DateRangeCubeJs): void {
        if (dateRange === DateRangeCubeJs.CUSTOM) {
            this.userWantsCustomDateRange = true;
        } else {
            this.userWantsCustomDateRange = false;
        }
    }

    // DATE TIME PICKER
    setDateRangeOfTimeDimension(dateRange: DateRangeCubeJs | string[]): void {
        let newDateRange = dateRange;
        if (dateRange === DateRangeCubeJs.CUSTOM) {
            newDateRange = [this.startDateFilter.format('YYYY-MM-DD'), this.endDateFilter.format('YYYY-MM-DD')];
        }
        this.defaultQuery.timeDimensions[0].dateRange = newDateRange;
    }

    initDateRange(): void {
        this.endDateFilter = moment.default().utc().startOf('day');
        this.startDateFilter = this.endDateFilter.clone().subtract(0, 'days');
        if (this.currentDateRange === DateRangeCubeJs.CUSTOM) {
            this.setDateRangeOfTimeDimension(DateRangeCubeJs.CUSTOM);
        }
    }

    onChangeCustomDate(calendarValue: CalendarValue): void {
        if (calendarValue) {
            this.isValidDateRange = this.startDateFilter <= this.endDateFilter;
            if (this.isValidDateRange) {
                this.onChangeDateRange(DateRangeCubeJs.CUSTOM);
            }
        }
    }

    /**
     *
     * @param itemisationEvent from mat-select
     */
    onChangeItemisation(value: string | null, member: string | null, operator: string | null, type: 'default' | 'energy' = 'default') {
        if (type === 'default') {
            const filters = [];
            if (this.defaultFilter.length > 0) {
                this.defaultFilter.forEach(element => {
                    filters.push(element);
                });
            }
            if (value) {
                if (value !== 'all') {
                    filters.push({
                        member: member,
                        operator: operator,
                        values: [value]
                    });
                }
                this.defaultQuery.filters = filters;
            }
            this.cubeQuery.next(this.defaultQuery);
        }
        // filter by energy (custom handler)
        else if (type === 'energy') {
            const prefix = BaseCubeJsGraphModel.getPrefixEnergyByEnergyType(value as EnergyType);
            if (prefix === '') {
                console.error('energy not found in environment: onChangeItemisation()');
            }
            this.defaultQuery.measures = this.defaultQuery.measures.map((m: string) => prefix + m.substring(m.lastIndexOf('.')));
            this.defaultQuery.timeDimensions = this.defaultQuery.timeDimensions.map((t: any) => {
                return {
                    dimension: prefix + t.dimension.substring(t.dimension.lastIndexOf('.')),
                    dateRange: t.dateRange
                };
            });
            this.defaultQuery.order = this.defaultQuery.order.map((o: string[]) => {
                if (o[0].startsWith('Disponibilite')) {
                    return [
                        prefix + o[0].substring(o[0].lastIndexOf('.')),
                        o[1]
                    ];
                } else {
                    return o;
                }
            });
            this.cubeQuery.next(this.defaultQuery);
        }
    }
}
