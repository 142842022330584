/* tslint:disable */

/**
 * Indicates the position of y axis:
 *   * LEFT : left
 *   * RIGHT : right
 */
type YAxisPosition =
  'LEFT' |
  'RIGHT';
module YAxisPosition {
  export const LEFT: YAxisPosition = 'LEFT';
  export const RIGHT: YAxisPosition = 'RIGHT';
  export function values(): YAxisPosition[] {
    return [
      LEFT,
      RIGHT
    ];
  }
}

export { YAxisPosition }