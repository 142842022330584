/* tslint:disable */

/**
 * The reason why a forecast data has been excluded from import :
 *   * UNKNOWN_TAG_NAME : the tag name provided does not exist for this station
 *   * TAG_NAME_MATCH_SEVERAL : the tag name provided match more than one tag name for this station
 *   * UNSUPPORTED_VALUES : import only support integer, or decimal with a comma or dot
 *   * UNSUPPORTED_DATETIME : import only support ISO8601 format, or YYYY-MM-DD, or DD/MM/YYYY
 *   * REDUNDANT_DATETIME : there is more than one entry for one of the datetime provided for this tag name
 *   * OVERLAPPING_DATETIME : time period provided overlap with the time period of the existing data for this tag name
 *   * MIXED_DATETIME_FORMAT : this tag use several datetime format at the same time
 */
type ForecastReportExclusionReason =
  'UNKNOWN_TAG_NAME' |
  'TAG_NAME_MATCH_SEVERAL' |
  'UNSUPPORTED_VALUES' |
  'UNSUPPORTED_DATETIME' |
  'REDUNDANT_DATETIME' |
  'OVERLAPPING_DATETIME' |
  'MIXED_DATETIME_FORMAT';
module ForecastReportExclusionReason {
  export const UNKNOWN_TAG_NAME: ForecastReportExclusionReason = 'UNKNOWN_TAG_NAME';
  export const TAG_NAME_MATCH_SEVERAL: ForecastReportExclusionReason = 'TAG_NAME_MATCH_SEVERAL';
  export const UNSUPPORTED_VALUES: ForecastReportExclusionReason = 'UNSUPPORTED_VALUES';
  export const UNSUPPORTED_DATETIME: ForecastReportExclusionReason = 'UNSUPPORTED_DATETIME';
  export const REDUNDANT_DATETIME: ForecastReportExclusionReason = 'REDUNDANT_DATETIME';
  export const OVERLAPPING_DATETIME: ForecastReportExclusionReason = 'OVERLAPPING_DATETIME';
  export const MIXED_DATETIME_FORMAT: ForecastReportExclusionReason = 'MIXED_DATETIME_FORMAT';
  export function values(): ForecastReportExclusionReason[] {
    return [
      UNKNOWN_TAG_NAME,
      TAG_NAME_MATCH_SEVERAL,
      UNSUPPORTED_VALUES,
      UNSUPPORTED_DATETIME,
      REDUNDANT_DATETIME,
      OVERLAPPING_DATETIME,
      MIXED_DATETIME_FORMAT
    ];
  }
}

export { ForecastReportExclusionReason }