/* tslint:disable */

/**
 * The type of service:
 *   * IN_USE
 *   * IN_USE_WITH_RESTRICTIONS
 *   * STOPPED
 *   * UNDER_CONSTRUCTION
 *   * PLANNING_STAGE
 */
type ServiceType =
  'IN_USE' |
  'IN_USE_WITH_RESTRICTIONS' |
  'STOPPED' |
  'UNDER_CONSTRUCTION' |
  'PLANNING_STAGE';
module ServiceType {
  export const IN_USE: ServiceType = 'IN_USE';
  export const IN_USE_WITH_RESTRICTIONS: ServiceType = 'IN_USE_WITH_RESTRICTIONS';
  export const STOPPED: ServiceType = 'STOPPED';
  export const UNDER_CONSTRUCTION: ServiceType = 'UNDER_CONSTRUCTION';
  export const PLANNING_STAGE: ServiceType = 'PLANNING_STAGE';
  export function values(): ServiceType[] {
    return [
      IN_USE,
      IN_USE_WITH_RESTRICTIONS,
      STOPPED,
      UNDER_CONSTRUCTION,
      PLANNING_STAGE
    ];
  }
}

export { ServiceType }