import { TableTd } from './table-td';

export class TableRow {
  id: string;
  cssClass: string;
  obj: any; // object represents the row
  newObj: any;
  collapsable: boolean;
  content: TableTd[];
  editable: boolean;
  isEditMode: boolean;
  selectable: boolean; // if click on row => event
  isSelected: boolean; // selectable must be true
  isDisabled: boolean;
  constructor() {
      this.obj = null;
      this.newObj = null;
      this.cssClass = null;
      this.collapsable = false;
      this.content = null;
      this.editable = false;
      this.isEditMode = false;
      this.selectable = false;
      this.isSelected = false;
      this.isDisabled = false;
  }

  addCss(cssClass: string): void {
    if (!this.cssClass.includes(cssClass)) {
      const newCss = this.cssClass.concat(' ', cssClass);
      this.cssClass = newCss;
    }
  }

  removeCss(cssClass: string): void {
    if (this.cssClass.includes(cssClass)) {
      const newCss = this.cssClass.replace(cssClass, '');
      this.cssClass = newCss;
    }
  }
}
