/* tslint:disable */

/**
 * The connector type
 */
type ConnectorType =
  'IEC_62196_T1' |
  'IEC_62196_T2' |
  'IEC_62196_T2_COMBO' |
  'IEC_62196_T3C' |
  'CHADEMO';
module ConnectorType {
  export const IEC_62196_T1: ConnectorType = 'IEC_62196_T1';
  export const IEC_62196_T2: ConnectorType = 'IEC_62196_T2';
  export const IEC_62196_T2_COMBO: ConnectorType = 'IEC_62196_T2_COMBO';
  export const IEC_62196_T3C: ConnectorType = 'IEC_62196_T3C';
  export const CHADEMO: ConnectorType = 'CHADEMO';
  export function values(): ConnectorType[] {
    return [
      IEC_62196_T1,
      IEC_62196_T2,
      IEC_62196_T2_COMBO,
      IEC_62196_T3C,
      CHADEMO
    ];
  }
}

export { ConnectorType }