/* tslint:disable */

/**
 * The mode of security:
 *   * NONE
 *   * SIGN
 *   * SIGN_ENCRYPT
 */
type SecurityMode =
  'NONE' |
  'SIGN' |
  'SIGN_ENCRYPT';
module SecurityMode {
  export const NONE: SecurityMode = 'NONE';
  export const SIGN: SecurityMode = 'SIGN';
  export const SIGN_ENCRYPT: SecurityMode = 'SIGN_ENCRYPT';
  export function values(): SecurityMode[] {
    return [
      NONE,
      SIGN,
      SIGN_ENCRYPT
    ];
  }
}

export { SecurityMode }