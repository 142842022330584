/* tslint:disable */

/**
 * The call statut:
 *   * DEFAULT
 *   * NORMAL
 */
type CallStatus =
  'DEFAULT' |
  'NORMAL';
module CallStatus {
  export const DEFAULT: CallStatus = 'DEFAULT';
  export const NORMAL: CallStatus = 'NORMAL';
  export function values(): CallStatus[] {
    return [
      DEFAULT,
      NORMAL
    ];
  }
}

export { CallStatus }