/* tslint:disable */

/**
 * The call type:
 *   * INTERPHONE
 *   * TELEPHONE
 *   * SMS
 *   * OTHER
 */
type TypeAppel =
  'INTERPHONE' |
  'TELEPHONE' |
  'SMS' |
  'OTHER';
module TypeAppel {
  export const INTERPHONE: TypeAppel = 'INTERPHONE';
  export const TELEPHONE: TypeAppel = 'TELEPHONE';
  export const SMS: TypeAppel = 'SMS';
  export const OTHER: TypeAppel = 'OTHER';
  export function values(): TypeAppel[] {
    return [
      INTERPHONE,
      TELEPHONE,
      SMS,
      OTHER
    ];
  }
}

export { TypeAppel }