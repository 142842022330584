/* tslint:disable */

/**
 * The application features:
 *   * SITE_OPERATION
 *   * SITE_MANAGEMENT
 *   * SUBSCRIPTION
 *   * TRANSACTION
 *   * AUTH_SUPPORT
 *   * VEHICLE
 *   * TICKET
 *   * USER
 *   * OCCUPANCY
 *   * PRICE
 */
type ApplicationFeature =
  'SITE_OPERATION' |
  'SITE_MANAGEMENT' |
  'SUBSCRIPTION' |
  'TRANSACTION' |
  'AUTH_SUPPORT' |
  'VEHICLE' |
  'TICKET' |
  'USER' |
  'OCCUPANCY' |
  'PRICE';
module ApplicationFeature {
  export const SITE_OPERATION: ApplicationFeature = 'SITE_OPERATION';
  export const SITE_MANAGEMENT: ApplicationFeature = 'SITE_MANAGEMENT';
  export const SUBSCRIPTION: ApplicationFeature = 'SUBSCRIPTION';
  export const TRANSACTION: ApplicationFeature = 'TRANSACTION';
  export const AUTH_SUPPORT: ApplicationFeature = 'AUTH_SUPPORT';
  export const VEHICLE: ApplicationFeature = 'VEHICLE';
  export const TICKET: ApplicationFeature = 'TICKET';
  export const USER: ApplicationFeature = 'USER';
  export const OCCUPANCY: ApplicationFeature = 'OCCUPANCY';
  export const PRICE: ApplicationFeature = 'PRICE';
  export function values(): ApplicationFeature[] {
    return [
      SITE_OPERATION,
      SITE_MANAGEMENT,
      SUBSCRIPTION,
      TRANSACTION,
      AUTH_SUPPORT,
      VEHICLE,
      TICKET,
      USER,
      OCCUPANCY,
      PRICE
    ];
  }
}

export { ApplicationFeature }