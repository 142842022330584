/* tslint:disable */

/**
 * field to filter for alarm search:
 *   * ALL : All fields
 *   * CHARGEPOINT_ID : OCPP ChargePoint Id
 *   * TRANSACTION_ID : OCPP Transaction Id
 */
type ChargeSearchFilter =
  'ALL' |
  'CHARGEPOINT_ID' |
  'TRANSACTION_ID';
module ChargeSearchFilter {
  export const ALL: ChargeSearchFilter = 'ALL';
  export const CHARGEPOINT_ID: ChargeSearchFilter = 'CHARGEPOINT_ID';
  export const TRANSACTION_ID: ChargeSearchFilter = 'TRANSACTION_ID';
  export function values(): ChargeSearchFilter[] {
    return [
      ALL,
      CHARGEPOINT_ID,
      TRANSACTION_ID
    ];
  }
}

export { ChargeSearchFilter }