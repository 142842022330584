import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

import { RouteId } from 'src/app/enums/env/routes-id-enum';
import { TabModel } from 'src/app/models/tabModel';
import { environment } from 'src/environments/environment';
import {ActivatedRoute} from '@angular/router';
import {RightService} from '../right.service';


@Injectable({
  providedIn: 'root',
})
export class GlobalUtil {
  static REGEX_INTEGER = '^[0-9]*$';
  readonly REGEX_FLOAT_OR_INTEGER = '^0$|^[0-9]+.[0-9]+$|^[0-9]+$';
  static REGLEX_EMAIL = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';
  private httpClient: HttpClient;

  constructor(http: HttpClient,
              public loggerService: NGXLogger,
              public translate: TranslateService,
              private readonly rightService: RightService,
              ) {
    this.httpClient = http;
  }

  valueIsNumber(value: string | number): boolean {
    if (!isNaN(Number(value))) {
      return true;
    } else {
      return false;
    }
  }

  convertStringToBoolean(value: string | number): boolean {
    if (value === 'true' || value === '1') {
      return true;
    } else {
      return false;
    }
  }

  valueIsInEnum(value: string | number | boolean, anyEnum: any): boolean {
    if (Object.values(anyEnum).includes(value)) {
      return true;
    } else {
      return false;
    }
  }

  valueIsBoolean(value: any): boolean {
    return value === true || value === false ? true : false;
  }

  /**
   * Dupplicate object without reference
   */
  copyObject(object: any): any {
    return JSON.parse(JSON.stringify(object));
  }

  /**
   * Make a deep copy of object array
   * @param arr array
   * @returns array
   */
  copyObjArray(arr: any[]): any[] {
    const newArr = [];
    arr.forEach((e) => {
      newArr.push(Object.assign({}, e));
    });
    return newArr;
  }

  uniqueObjectArray(arr: Object[]): Object[] {
    return arr.filter(
      (v, i, a) =>
        a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
    );
  }

  uniqueObjectFilterById(arr: Object[], id: string): Object[] {
    return arr.filter((v, i, a) => a.findIndex((t) => t[id] === v[id]) === i);
  }

  valueIsIntegerOrFloat(value: string): boolean {
    return GlobalUtil.checkRegex(value, this.REGEX_FLOAT_OR_INTEGER);
  }

  static checkRegex(value: string, regex: string): boolean {
    if (value.toString().match(regex)) {
      return true;
    } else {
      return false;
    }
  }

  convertNumberWithDecimal(value: string, decimal: number) {
    return Number(Number(value).toFixed(decimal));
  }

  async getSvgFromPath(svgPath: string): Promise<string> {
    return new Promise<string | undefined>((resolve) => {

      const httpOptions: any = {
        headers: {
          Accept: 'image/svg+xml'
        },
        responseType: 'text',
      };

      this.httpClient.get<any>(svgPath, httpOptions).toPromise()
      .then((resp: any) => {
        const formattedSvg = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(resp);
        resolve(formattedSvg);
      })
      .catch((error: any) => {
        console.warn('can\'t load', error);
        resolve(null);
      })
      ;
    });
  }

  redirectToGoogleMapWithSearch(query: string, openInNewTab: boolean): void {
    const path: string = 'https://www.google.com/maps/search/?api=1&query=' + query;

    if (openInNewTab) {
      window.open(path);
    } else {
      window.location.href = path;
    }
  }

  getBackLinkForDetail(routeId: RouteId): string {
    return '/' + environment.core.routes.find(route => route.id === environment.core.routes.find(r => r.id === routeId).detailBackLink).route;
  }

  getTabListModel(routeId: RouteId, mustSort: boolean): TabModel[] {
    const list: TabModel[] = [];

    environment.core.routes.find(r => r.id === routeId)?.tabs.forEach(t => {
      if (t.enabled) {
        const tab = new TabModel();
        tab.id = t.id;
        tab.enabled = t.enabled;
        tab.name = t.name;
        tab.link = t.route;
        tab.order = t.order;
        tab.params = t.params || null;
        tab.feature = t.feature;
        if (!tab.feature || (tab.feature && this.rightService.canAccess(tab.feature))) {
          list.push(tab);
        }
      }
    });

    if (mustSort) {
      list.sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0);
    }

    return list;
  }

    /**
   * Toggle item in/out of primative array
   * @param arr primative array
   * @param item item
   */
     toggleArrayItem(arr: any[], item: any) {
      const i = arr.indexOf(item);
      if (i === -1) {
        arr.push(item);
      } else {
        arr.splice(i, 1);
      }
    }
}
