// TChartType from cubeJs
export enum CubeJsChartType {
    AREA = 'area',
    LINE = 'line',
    BAR = 'bar',
    PIE = 'pie',
    NUMBER = 'number',
    TABLE = 'table',
    DOUGHNUT = 'doughnut'
}
