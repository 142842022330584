import { GranularityCubeJs } from 'src/app/enums/cubejs/granularity';

export class GranularityModel {

    name: string; // i18n
    value: GranularityCubeJs;

    constructor(name: string, value: GranularityCubeJs) {
        this.name = name;
        this.value = value;
    }
}
