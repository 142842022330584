import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { FullyLoggedInStateType } from '../../enum/FullyLoggedInStateType';
import { LoggedInService } from '../../services/loggedIn.service';
import { environment } from 'src/environments/environment';
import { AppGuardType } from '../../enum/AppGuardType';

@Component({
  selector: 'app-logged-in-call-back',
  templateUrl: './logged-in-call-back.component.html',
  styleUrls: ['./logged-in-call-back.component.css'],
})
export class LoggedInCallBackComponent implements OnInit {
  isAuthenticated: boolean;

  isFullyLoggedIn: FullyLoggedInStateType;

  fullyLoggedInStateType = FullyLoggedInStateType;

  constructor(
    public router: Router,
    public loggedInService: LoggedInService,
    public authService: AuthService,
  ) {
    this.fullyLoggedInStateListener();
  }

  async ngOnInit() {
    // Get the authentication state for immediate use
    this.isAuthenticated = await this.authService.isAuthenticated();
    if (
      environment.authAppGuard !== AppGuardType.NOT_AUTHENTICATED_JUST_LOGGEDIN
    ) {
      if (this.isAuthenticated) {
        this.loggedInService.loggedIn();
      } else {
        this.loggedInService.redirectAfterFullyLoggedIn();
      }
    } else {
      this.loggedInService.loggedIn();
    }
  }

  fullyLoggedInStateListener() {
    this.loggedInService.fullyLoggedInState$.subscribe(
      (isFullyLoggedIn: FullyLoggedInStateType) => {
        this.isFullyLoggedIn = isFullyLoggedIn;
        if (this.isFullyLoggedIn === FullyLoggedInStateType.SUCCESS) {
          this.loggedInService.redirectAfterFullyLoggedIn();
        } else if (
          this.isFullyLoggedIn === FullyLoggedInStateType.NO_FULLY_LOGGED_IN
        ) {
        } else if (this.isFullyLoggedIn === FullyLoggedInStateType.PENDING) {
          this.isFullyLoggedIn = isFullyLoggedIn;
        }
      }
    );
  }

  retry() {
    this.loggedInService.loggedIn();
  }

  reconnect() {
    this.authService.logout();
  }
}
