import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { DpDatePickerModule } from "ng2-date-picker";
import { SafeHtmlPipe } from "src/app/shared/pipes/safeHTML/safe-html.pipe";
import { IconModule } from "../icon/icon.module";
import { SelectModule } from "../select/select.module";
import { TableComponent } from "./component/table/table.component";

import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { TooltipModule } from "primeng/tooltip";
import { SplitButtonModule } from "../../shared/components/split-button/split-button.module";
import { FilterDateComponent } from "./component/filter-date/filter-date.component";
import { FilterEnumComponent } from "./component/filter-enum/filter-enum.component";
import { FilterTextComponent } from "./component/filter-text/filter-text.component";
import { PaginationComponent } from "./component/pagination/pagination.component";

import { ForModule } from "@rx-angular/template/for";
import { IfModule } from "@rx-angular/template/if";
import { LetModule } from "@rx-angular/template/let";

@NgModule({
  declarations: [
    TableComponent,
    FilterTextComponent,
    FilterDateComponent,
    FilterEnumComponent,
    PaginationComponent,
  ],
  imports: [
    SafeHtmlPipe,
    TranslateModule,
    MatTooltipModule,
    CommonModule,
    IconModule,
    SelectModule,
    DpDatePickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatIconModule,
    SplitButtonModule,
    MatMenuModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    TooltipModule,
    LetModule,
    IfModule,
    ForModule,
  ],
  exports: [TableComponent],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "fr-FR" }],
})
export class TableModule {}
