/* tslint:disable */

/**
 * The call validity:
 *   * CALL DATE TOO OLD
 *   * VALID CALL
 */
type CallNotificationValidity =
  'CALL_DATE_TOO_OLD' |
  'VALID_CALL';
module CallNotificationValidity {
  export const CALL_DATE_TOO_OLD: CallNotificationValidity = 'CALL_DATE_TOO_OLD';
  export const VALID_CALL: CallNotificationValidity = 'VALID_CALL';
  export function values(): CallNotificationValidity[] {
    return [
      CALL_DATE_TOO_OLD,
      VALID_CALL
    ];
  }
}

export { CallNotificationValidity }