export enum iconClass {
    DASHBOARD = 'fas fa-tachometer-alt',
    MAP = 'fas fa-map',
    ALARM = 'fas fa-bell',
    ACQUISITION_LOG = "fas fa-book",
    TICKETS = 'fas fa-sticky-note',
    REGIONS = 'fas fa-plug',
    STATIONS = 'fas fa-warehouse',
    GRAPHS = 'fas fa-chart-bar',
    CHARGES = 'fas fa-battery-half',
    CUSTOMER_ACCOUNTS = 'fas fa-address-card',
    BADGES = 'far fa-id-badge',
    USERS = 'fas fa-user-alt',
    VEHICLES = 'fas fa-car',
    ADMINISTRATION = 'fas fa-tools',
    EVENTS = 'fas fa-file-alt',
    LOG_OUT = 'fas fa-unlock',
    EMS = 'fas fa-stethoscope',
    LAB = 'fas fa-flask',
    BAR = 'fas fa-signal',
    PIE = 'fas fa-chart-pie',
    BUILDING = 'fas fa-building',
    CLASS = 'fas fa-layer-group',
    CLOSE = 'fas fa-window-close',
    AVAILABLE = 'fas fa-battery-full',
    PARTIALLY_AVAILABLE = 'fas fa-battery-half',
    UNAVAILABLE = 'fas fa-battery-empty',
    EXPORT = "fas fa-file-download icon-download",
    GROUP = "fas fa-columns icon-group",
    SEARCH = "fas fa-search",
    SORT = "fas fa-sort",
    REFRESH = "fas fa-sync-alt",
    NOTIFICATIONS = 'fas fa-message',
    CLOCK = 'fas fa-solid fa-clock',
    CALENDAR = "fas fa-solid fa-calendar-days"
}
