import { Injectable } from "@angular/core";
import { ApplicationFeature, UserType } from "src/swagger-gen/web-secured/models";
import { UserSessionService } from "../tinea-components/okta/services/session/user-session.service";

@Injectable({
    providedIn: 'root',
})
export class RightService {

    currentAuthorization!: ApplicationFeature;

    constructor(private userSessionService: UserSessionService) {
    }

    private getUser() {
        return this.userSessionService.getUser();
    }



    // --------------------------------------------------------------------- //
    //                          can access "feature"                         //
    // --------------------------------------------------------------------- //
    public canAccess(applicationFeature: ApplicationFeature): boolean {
        let canAccess = false;
        const user = this.getUser();
        if (user) {
            switch (user.type) {
                case UserType.ADMIN:
                        canAccess = true;
                    break;
                case UserType.OPERATOR:
                    canAccess = user.hasFeature(applicationFeature);
                    break;
            }
        }
        return canAccess;
    }

    // Checkf if the user connected can access to Administration pages
    public canAccessAdministration(): boolean {
        const user = this.getUser();
        if (user.type === UserType.ADMIN) {
            return true;
        } else {
            return false;
        }
    }

    // Checkf if the user connected can access to Vehicles pages
    public canAccessVehicle(): boolean {
        let canAccess = false;
        const user = this.getUser();
        if (user) {
            switch (user.type) {
                case UserType.ADMIN:
                    canAccess = true;
                    break;
                case UserType.OPERATOR:
                    canAccess = user.hasFeatureVehicle();
                    break;
            }
        }
        return canAccess;
    }

    // Checkf if the user connected can access to Tickets pages
    public canAccessTickets(): boolean {
        let canAccess = false;
        const user = this.getUser();
        if (user) {
            switch (user.type) {
                case UserType.ADMIN:
                    canAccess = true;
                    break;
                case UserType.OPERATOR:
                    canAccess = user.hasFeatureTicket();
                    break;
            }
        }
        return canAccess;
    }

    // Checkf if the user connected can access to Subscriptions pages
    public canAccessSubscriptions(): boolean {
        let canAccess = false;
        const user = this.getUser();
        if (user) {
            switch (user.type) {
                case UserType.ADMIN:
                    canAccess = true;
                    break;
                case UserType.OPERATOR:
                    canAccess = user.hasFeatureSubscription();
                    break;
            }
        }
        return canAccess;
    }

    // Checkf if the user connected can access to Transactions
    public canAccessTransactions(): boolean {
        let canAccess = false;
        const user = this.getUser();
        if (user) {
            switch (user.type) {
                case UserType.ADMIN:
                    canAccess = true;
                    break;
                case UserType.OPERATOR:
                    canAccess = user.hasFeatureTransaction();
                    break;
            }
        }
        return canAccess;
    }

    // Checkf if the user connected can access to Users pages
    public canAccessUsers(): boolean {
        let canAccess = false;
        const user = this.getUser();
        if (user) {
            switch (user.type) {
                case UserType.ADMIN:
                    canAccess = true;
                    break;
                case UserType.OPERATOR:
                    canAccess = user.hasFeatureUser();
                    break;
            }
        }

        return canAccess;
    }

    // Checkf if the user connected can access to Badges pages
    public canAccessBadges(): boolean {
        let canAccess = false;
        const user = this.getUser();
        if (user) {
            switch (user.type) {
                case UserType.ADMIN:
                    canAccess = true;
                    break;
                case UserType.OPERATOR:
                    canAccess = user.hasFeatureBadge();
                    break;
            }
        }
        return canAccess;
    }

    // Checkf if the user connected can access to SITE_OPERATION or SITE_MANAGEMENT pages
    public canAccessSiteOperationManagement(): boolean {
        let canAccess = false;
        const user = this.getUser();
        if (user) {
            switch (user.type) {
                case UserType.ADMIN:
                    canAccess = true;
                case UserType.OPERATOR:
                    if (user.authorizations) {
                        user.authorizations.forEach(authorization => {
                            if (authorization.feature === ApplicationFeature.SITE_OPERATION || authorization.feature === ApplicationFeature.SITE_MANAGEMENT) {
                                canAccess = true;
                            }
                        });
                    }
                    break;
            }
        }
        return canAccess;
    }

    public canAccessSiteManagement(): boolean {
        let canAccess = false;
        const user = this.userSessionService.getUser();
        if (user) {
            switch (user.type) {
                case UserType.ADMIN:
                    canAccess = true;
                    break;
                case UserType.OPERATOR:
                    canAccess = user.hasFeatureSiteManagement();
                    break;
            }
        }
        return canAccess;
    }

    public canAccessSiteOperation(): boolean {
        let canAccess = false;
        const user = this.userSessionService.getUser();
        if (user) {
            switch (user.type) {
                case UserType.ADMIN:
                    canAccess = true;
                    break;
                case UserType.OPERATOR:
                    canAccess = user.hasFeatureSiteOperation();
                    break;
            }
        }
        return canAccess;
    }

    // Checkf if the user connected can access to Transaction pages
    public canAccessTransaction(): boolean {
        let canAccess = false;
        const user = this.userSessionService.getUser();
        if (user) {
            switch (user.type) {
                case UserType.ADMIN:
                    canAccess = true;
                    break;
                case UserType.OPERATOR:
                    canAccess = user.hasFeatureTransaction();
                    break;
            }
        }
        return canAccess;
    }

    // Check if the user connected can access to Notifications pages
    public canAccessNotifications(): boolean {
        const user = this.getUser();
        return user.type === UserType.ADMIN;
    }

    public canAccessPrices(): boolean {
        let canAccess = false;
        const user = this.getUser();
        if (user) {
            switch (user.type) {
                case UserType.ADMIN:
                    canAccess = true;
                    break;
                case UserType.OPERATOR:
                    canAccess = user.hasFeature(ApplicationFeature.PRICE);
                    break;
            }
        }
        return canAccess;
    }

    // --------------------------------------------------------------------- //
    //                              get "feature"                            //
    // --------------------------------------------------------------------- //
    public setNewMainRoute(val: ApplicationFeature): void {
        this.currentAuthorization = val;
    }

    // --------------------------------------------------------------------- //
    //                              is read only                             //
    // --------------------------------------------------------------------- //
    isReadOnlyVehicle(): boolean {
        const user = this.userSessionService.getUser();
        if (user.isAdmin()) {
            return false;
        }
        return user.isReadOnlyVehicle();
    }

    isReadOnlyBadge(): boolean {
        const user = this.userSessionService.getUser();
        if (user.isAdmin()) {
            return false;
        }
        return user.isReadOnlyBadge();
    }

    isReadOnlyUser(): boolean {
        const user = this.userSessionService.getUser();
        if (user.isAdmin()) {
            return false;
        }
        return user.isReadOnlyUser();
    }

    isReadOnlySubscription(): boolean {
        const user = this.userSessionService.getUser();
        if (user.isAdmin()) {
            return false;
        }
        return user.isReadOnlySubscription();
    }

    isReadOnlyTransaction(): boolean {
        const user = this.userSessionService.getUser();
        if (user.isAdmin()) {
            return false;
        }
        return user.isReadOnlyTransaction();
    }

    isReadOnlySiteManagement(): boolean {
        const user = this.userSessionService.getUser();
        if (user.isAdmin()) {
            return false;
        }
        return user.isReadOnlySiteManagement();
    }

    isReadOnlySiteOperation(): boolean {
        const user = this.userSessionService.getUser();
        if (user.isAdmin()) {
            return false;
        }
        return user.isReadOnlySiteOperation();
    }

    isReadOnlyPrice(): boolean {
        const user = this.userSessionService.getUser();
        if (user.isAdmin()) {
            return false;
        }
        return user.isReadOnlyPrice();
    }

    isDisabled(val: ApplicationFeature): boolean {
        let disabled = true;
        if (val === ApplicationFeature.VEHICLE) {
            if (this.canAccessVehicle() && !this.isReadOnlyVehicle()) {
                disabled = false;
            }
        }
        else if (val === ApplicationFeature.AUTH_SUPPORT) {
            if (this.canAccessBadges() && !this.isReadOnlyBadge()) {
                disabled = false;
            }
        }
        else if (val === ApplicationFeature.USER) {
            if (this.canAccessUsers() && !this.isReadOnlyUser()) {
                disabled = false;
            }
        }
        else if (val === ApplicationFeature.SUBSCRIPTION) {
            if (this.canAccessSubscriptions() && !this.isReadOnlySubscription()) {
                disabled = false;
            }
        }
        else if (val === ApplicationFeature.TRANSACTION) {
            if (this.canAccessTransactions() && !this.isReadOnlyTransaction()) {
                disabled = false;
            }
        }
        else if (val === ApplicationFeature.SITE_MANAGEMENT) {
            if (this.canAccessSiteManagement() && !this.isReadOnlySiteManagement()) {
                disabled = false;
            }
        }
        else if (val === ApplicationFeature.SITE_OPERATION) {
            if (this.canAccessSiteManagement() && !this.isReadOnlySiteManagement()) {
                disabled = false;
            }
            else if (this.canAccessSiteOperation() && !this.isReadOnlySiteOperation()) {
                disabled = false;
            }
        }

        return disabled;
    }
}
