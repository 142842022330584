import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerModule } from 'ngx-logger';
import { LOGGER_CONFIG } from 'src/app/shared/configuration/loggerConfig';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AdministrationPageComponent } from './modules/administration/administration-page/administration-page.component';
import { HomeComponent } from './modules/home/home.component';
import { LeftNavigationComponent } from './modules/left-navigation/left-navigation.component';
import { Globals } from './shared/configuration/globals';
import { SharedModule } from './shared/shared.module';
import { DisplayErrorsMessageModule } from './tinea-components/display-errors-message/display-errors-message.module';
import { CommonBaseModule } from './tinea-components/okta/common-base.module';
import { OktaModule } from './tinea-components/okta/okta/okta.module';
import { PagingModule } from './tinea-components/paging/paging.module';
import { TableModule } from './tinea-components/table/table.module';
import { QuillModule } from 'ngx-quill';
import { MaterialModule } from 'src/app/shared/material.module';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import { TooltipModule } from 'primeng/tooltip';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LeftNavigationComponent,
    AdministrationPageComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatSnackBarModule,
    SharedModule,
    TranslateModule,
    OktaModule,
    GoogleMapsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LoggerModule.forRoot(LOGGER_CONFIG),
    CoreModule,
    CommonBaseModule,
    TableModule,
    PagingModule,
    DisplayErrorsMessageModule,
    FormsModule,
    AppRoutingModule, // this one should ALWAYS be the last!
    BrowserModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    QuillModule.forRoot(),
    MaterialModule,
    TooltipModule
  ],
  providers: [
    /*  Globals: have to be provided only here at the top level,
    as it's a singleton which values can be changed by another service */
    Globals,
    Title,
    DatePipe
  ],
  bootstrap: [AppComponent],
  exports: [
    BrowserAnimationsModule,
    BrowserModule,
  ],
})
export class AppModule {
  // for i18n datepipes
  constructor() {
    registerLocaleData(localeFr, 'fr');
  }
}
