/* tslint:disable */

/**
 * Indicates the type of time interval to consider to aggregate the values:
 *   * SECOND : second
 *   * MINUTE : minute
 *   * HOUR: hour
 *   * DAY: day
 */
type DataTimeStepType =
  'SECOND' |
  'MINUTE' |
  'HOUR' |
  'DAY';
module DataTimeStepType {
  export const SECOND: DataTimeStepType = 'SECOND';
  export const MINUTE: DataTimeStepType = 'MINUTE';
  export const HOUR: DataTimeStepType = 'HOUR';
  export const DAY: DataTimeStepType = 'DAY';
  export function values(): DataTimeStepType[] {
    return [
      SECOND,
      MINUTE,
      HOUR,
      DAY
    ];
  }
}

export { DataTimeStepType }