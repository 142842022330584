/* tslint:disable */

/**
 * field to filter for tag group search by type
 */
type TagGroupSearchFilter =
  'TAG_GROUP_TYPE_LABEL';
module TagGroupSearchFilter {
  export const TAG_GROUP_TYPE_LABEL: TagGroupSearchFilter = 'TAG_GROUP_TYPE_LABEL';
  export function values(): TagGroupSearchFilter[] {
    return [
      TAG_GROUP_TYPE_LABEL
    ];
  }
}

export { TagGroupSearchFilter }