import { SubscriptionSummary } from "src/swagger-gen/web-secured/models";

export class UserAllowedSubscriptionModel {

    uid: string;
    name: string;

    constructor(res: SubscriptionSummary) {
        this.uid = res.uid;
        this.name = res.name || null;
    }

    public static getListFromApi(res: SubscriptionSummary[]): UserAllowedSubscriptionModel[] {
        const list: UserAllowedSubscriptionModel[] = [];
        if (res) {
            res.forEach(r => {
                list.push(new UserAllowedSubscriptionModel(r));
            });
        }

        return list;
    }
}
