import { TableConfiguration } from './../input/table-configuration';
import { TableHeader } from './../input/table-header';
import { TableRows } from './../input/table-rows';

export class TableTemplateModel {
    configuration: TableConfiguration;
    header: TableHeader;
    dataRow: TableRows[];

    constructor() {
        this.configuration = new TableConfiguration();
        this.header = new TableHeader();
        this.dataRow = [];
    }

    public hasDataRow(): boolean {
        return this.dataRow.length > 0;
    }

    canBeDisplayed(): boolean {
        if (!this.hasDataRow() && this.configuration.whatShowIfNoData === 'HIDE_TABLE') {
            return false;
        }
        return true;
    }

    mustDisplayNoDataMsgBefore(): boolean {
        return (!this.hasDataRow() && this.configuration.isDisplayedBefore()) ? true : false;
    }

    mustDisplayNoDataMsgInBody(): boolean {
        return (!this.hasDataRow() && this.configuration.isDisplayedInBody()) ? true : false;
    }

    mustDisplayNoDataMsgAfter(): boolean {
        return (!this.hasDataRow() && this.configuration.isDisplayedAfter()) ? true : false;
    }

    getNbColumn(): number {
        return this.header.cells.length;
    }

    unselectAllRows(): void {
      this.dataRow.map(r => r.row.isSelected = false);
    }
}
