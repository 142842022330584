/* tslint:disable */

/**
 * The list of available security type :
 * * NONE : no security
 */
type OcppSecurity =
  'NONE';
module OcppSecurity {
  export const NONE: OcppSecurity = 'NONE';
  export function values(): OcppSecurity[] {
    return [
      NONE
    ];
  }
}

export { OcppSecurity }