export enum AvailabilityColor {
    AVAILABLE = '#3bb537',
    PARTIALLY_AVAILABLE = '#fabc2c',
    UNAVAILABLE = '#ee4549',
    UNKNOWN = '#c1c1c1',
    UNDEFINED = '#000',

    AVAILABLE_HOVER = '#60c25d',
    PARTIALLY_HOVER = '#f9c754',
    UNAVAILABLE_HOVER = '#ef686b',
    UNKNOWN_HOVER = '#cbcbcb',
    UNDEFINED_HOVER = '#000',
}
