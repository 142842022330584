import { NgModule, Optional, SkipSelf, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OktaModule } from './okta/okta.module';
import { AuthService } from './auth/auth.service';
import { LoggedInService } from './services/loggedIn.service';
import { CommonBaseRoutingModule } from './common-base-routing.module';
import { LoggedInCallBackComponent } from './components/logged-in-call-back/logged-in-call-back.component';
// specific to website
import { LoggedInAuthentService } from './services/loggedInAuthentservice';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConsumeDataTokenInterceptor } from './interceptor/consume-data-token.interceptor';
import { VersionInterceptor } from './interceptor/version.interceptor';
import { environment } from 'src/environments/environment';
import { AuthentWithCustomOktaService } from './auth/services/authent-with-custom-okta.service';
import { OktaCustomCallBackComponent } from './components/okta-custom-call-back/okta-custom-call-back.component';
import { LoadingModule } from '../loading/loading.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

// export function getAuthServiceClass() {
//   console.log('Authservice used: ', environment.authClass); // DEBUG
//   if (environment.authClass === 'AuthApimTineaService') {
//     return AuthApimTineaService;
//   } else if (environment.authClass === 'CommonApiWithOktaService') {
//     return CommonApiWithOktaService;
//   } else if (environment.authClass === 'CommonApiWithCustomOktaService') {
//     return CommonApiWithCustomOktaService;
//   } else {
//     console.error('getAuthServiceClass(): Auth class don\'t exists');
//   }
// }

@NgModule({
  declarations: [LoggedInCallBackComponent, OktaCustomCallBackComponent],
  imports: [
    CommonModule,
    OktaModule,
    TranslateModule,
    CommonBaseRoutingModule,
    LoadingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConsumeDataTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VersionInterceptor,
      multi: true
    },
    {provide: AuthService, useClass: AuthentWithCustomOktaService },
    {provide: LoggedInService, useClass: LoggedInAuthentService }
  ]
})
export class CommonBaseModule {

  constructor(@Optional() @SkipSelf() parentModule: CommonBaseModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded.');
    }
  }

}
