import {NgModule} from '@angular/core';
import {TogglePasswordDirective} from './toggle-password.directive';

@NgModule({
  declarations: [
    TogglePasswordDirective
  ],
  exports: [
    TogglePasswordDirective
  ],
})
export class TogglePasswordModule {}
