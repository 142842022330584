/* tslint:disable */

/**
 * The Agent event type :
 * * CONNECTION : the event is a connection
 * * DISCONNECTION : the event is a disconnection
 * * DATA : the event is a data transfert
 * * ERROR : the event is an error
 */
type AgentEventType =
  'CONNECTION' |
  'DISCONNECTION' |
  'DATA' |
  'ERROR';
module AgentEventType {
  export const CONNECTION: AgentEventType = 'CONNECTION';
  export const DISCONNECTION: AgentEventType = 'DISCONNECTION';
  export const DATA: AgentEventType = 'DATA';
  export const ERROR: AgentEventType = 'ERROR';
  export function values(): AgentEventType[] {
    return [
      CONNECTION,
      DISCONNECTION,
      DATA,
      ERROR
    ];
  }
}

export { AgentEventType }