import { Params } from '@angular/router';
import { RouteId } from '../enums/env/routes-id-enum';
import {ApplicationFeature} from '../../swagger-gen/web-secured/models/application-feature';

export class TabModel {
    id: RouteId;
    link: string;
    name: string; // i18n key
    enabled: boolean;
    order: number;
    params: Params;
    feature: ApplicationFeature | null;
    constructor() {
        this.link = null;
        this.name = null;
        this.enabled = true;
        this.order = null;
        this.params = null;
        this.id = null;
        this.feature = null;
    }
}
