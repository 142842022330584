import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { RouteId } from "../enums/env/routes-id-enum";
import { RouteEnv } from "../interfaces/env/RouteEnv";
import { MainRoute } from "../models/MainRoute";
import { RightService } from "./right.service";

@Injectable({
  providedIn: 'root',
})
export class RoutingService {

  constructor(private rightService: RightService) {
  }

  public static getRoute(routeId: RouteId): MainRoute {
    return new MainRoute(RoutingService.searchRoute(environment.core.routes, routeId), routeId);
  }

  public getRoutesLeftMenu(): MainRoute[] {
    const listRoute = [];
    environment.core.routes.forEach(r => {
      switch(r.id) {
        case RouteId.DASHBOARD:
          const canAccessDashboard: boolean = this.rightService.canAccessSiteOperationManagement();
          if(r.enabled && canAccessDashboard) {
            listRoute.push(r);
          }
          break;
        case RouteId.DASHBOARD_GNL:
          const canAccessDashboardGNL: boolean = this.rightService.canAccessSiteOperationManagement();
          if(r.enabled && canAccessDashboardGNL) {
            listRoute.push(r);
          }
          break;
        case RouteId.MAP:
          const canAccessMap: boolean = this.rightService.canAccessAdministration();
          if(r.enabled) {
            listRoute.push(r);
          }
          break;
        case RouteId.EMS:
          if(r.enabled) {
            listRoute.push(r);
          }
          break;
        case RouteId.REGIONS:
          if(r.enabled) {
            listRoute.push(r);
          }
          break;
        case RouteId.ALARMS:
          const canAccessAlarms: boolean = this.rightService.canAccessSiteOperationManagement();
          if(r.enabled && canAccessAlarms) {
            listRoute.push(r);
          }
          break;
        case RouteId.ACQUISITION_LOG:
          const canAccessAcquisitionLog: boolean = this.rightService.canAccessSiteOperationManagement();
          if(r.enabled && canAccessAcquisitionLog) {
            listRoute.push(r);
          }
          break;
        case RouteId.TICKETS_ROOT:
          const canAccessTickets: boolean = this.rightService.canAccessTickets();
          if(r.enabled && canAccessTickets) {
            listRoute.push(r);
          }
          break;
        case RouteId.STATIONS:
          const canAccessStations: boolean = this.rightService.canAccessSiteOperationManagement();
          if(r.enabled && canAccessStations) {
            listRoute.push(r);
          }
          break;
        case RouteId.GRAPHS:
          const canAccessGraph: boolean = this.rightService.canAccessSiteOperationManagement();
          if(r.enabled && canAccessGraph) {
            listRoute.push(r);
          }
          break;
        case RouteId.CUSTOMER_ACCOUNTS:
          const canAccessSubscriptions: boolean = this.rightService.canAccessSubscriptions();
          if(r.enabled && canAccessSubscriptions) {
            listRoute.push(r);
          }
          break;
        case RouteId.BADGES:
          const canAccessBadges: boolean = this.rightService.canAccessBadges();
          if(r.enabled && canAccessBadges) {
            listRoute.push(r);
          }
          break;
        case RouteId.USERS:
          const canAccessUsers: boolean = this.rightService.canAccessUsers();
          if(r.enabled && canAccessUsers) {
            listRoute.push(r);
          }
          break;
        case RouteId.VEHICLES:
          const canAccessVehicle: boolean = this.rightService.canAccessVehicle();
          if(r.enabled && canAccessVehicle) {
            listRoute.push(r);
          }
          break;
        case RouteId.EXPORT:
          if(r.enabled) {
            listRoute.push(r);
          }
          break;
        case RouteId.NOTIFICATIONS:
          const canAccessNotifications: boolean = this.rightService.canAccessNotifications();
          if (r.enabled && canAccessNotifications) {
            listRoute.push(r);
          }
          break;
        case RouteId.ADMINISTRATION:
          const canAccessAdministration: boolean = this.rightService.canAccessAdministration();
          if(r.enabled && canAccessAdministration) {
            listRoute.push(r);
          }
          break;
        case RouteId.ADMINISTRATION_DETAILS:
          if(r.enabled) {
            listRoute.push(r);
          }
          break;
      }
    });

    return listRoute;
  }

  public static getPathRoute(routeId: RouteId): string {
    return RoutingService.getRoute(routeId).path;
  }

  private static searchRoute(routesEnv: RouteEnv[], routeId: RouteId): RouteEnv {
    for (const route of routesEnv) {
      if (route.id === routeId) {
        return route;
      }

      if (route.tabs) {
        const found = this.searchRoute(route.tabs, routeId);
        if (found) {
          return found;
        }
      }
    }

    return null;
  }

  public static getStationRoute(): MainRoute {
    return RoutingService.getRoute(RouteId.STATIONS);
  }
}
