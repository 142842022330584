import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OktaAuthGuard } from '@okta/okta-angular';
import { User } from 'src/app/shared/models/user/user';
import { UserSessionService } from 'src/app/tinea-components/okta/services/session/user-session.service';
import { UserType } from 'src/swagger-gen/web-secured/models';
import { RightService } from 'src/app/services/right.service';


@Injectable({
  providedIn: 'root'
})
export class GraphGuard implements CanActivate {

  state: RouterStateSnapshot;
  route: ActivatedRouteSnapshot;
  isRefreshPage = true;

  constructor(public router: Router,
    private rightService: RightService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.state = state;
      this.route = route;
    
      return this.rightService.canAccessSiteOperationManagement();

  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }

}
