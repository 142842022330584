/* tslint:disable */

/**
 * List of events that can occur related to a subscription:
 *   * AUTH_SUPPORT_ASSIGNED : An auth support was assigned to a subscription
 *   * SUBSCRIPTION_ENABLED : The subscription was enabled
 *   * TRANSACTION_REPORT : Transactions reports can be configured to be sent to contacts having this event enabled
 */
type SubscriptionContactEvents =
  'AUTH_SUPPORT' |
  'SUBSCRIPTION_ENABLED' |
  'TRANSACTION_REPORT' |
  'STATION';
module SubscriptionContactEvents {
  export const AUTH_SUPPORT: SubscriptionContactEvents = 'AUTH_SUPPORT';
  export const SUBSCRIPTION_ENABLED: SubscriptionContactEvents = 'SUBSCRIPTION_ENABLED';
  export const TRANSACTION_REPORT: SubscriptionContactEvents = 'TRANSACTION_REPORT';
  export const STATION: SubscriptionContactEvents = 'STATION';
  export function values(): SubscriptionContactEvents[] {
    return [
      AUTH_SUPPORT,
      SUBSCRIPTION_ENABLED,
      TRANSACTION_REPORT,
      STATION
    ];
  }
}

export { SubscriptionContactEvents }