/* tslint:disable */

/**
 * The connector state
 */
type ConnectorState =
  'AVAILABLE' |
  'IN_USE' |
  'RESERVED' |
  'OUT_OF_ORDER' |
  'UNKNOWN';
module ConnectorState {
  export const AVAILABLE: ConnectorState = 'AVAILABLE';
  export const IN_USE: ConnectorState = 'IN_USE';
  export const RESERVED: ConnectorState = 'RESERVED';
  export const OUT_OF_ORDER: ConnectorState = 'OUT_OF_ORDER';
  export const UNKNOWN: ConnectorState = 'UNKNOWN';
  export function values(): ConnectorState[] {
    return [
      AVAILABLE,
      IN_USE,
      RESERVED,
      OUT_OF_ORDER,
      UNKNOWN
    ];
  }
}

export { ConnectorState }