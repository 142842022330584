import { ApplicationFeature } from "src/swagger-gen/web-secured/models";
import { RightService } from "../services/right.service";

export abstract class BaseFeatureGuard {

    constructor(protected rightService: RightService) {
      }

    handler(authorization: ApplicationFeature): boolean {
        this.rightService.setNewMainRoute(authorization);

        switch (authorization) {
          case ApplicationFeature.VEHICLE:
            return this.rightService.canAccessVehicle(); 
          case ApplicationFeature.AUTH_SUPPORT:
            return this.rightService.canAccessBadges();   
          case ApplicationFeature.TICKET:
            return this.rightService.canAccessTickets();              
          case ApplicationFeature.USER:
            return this.rightService.canAccessUsers();                
          case ApplicationFeature.SUBSCRIPTION:
            return this.rightService.canAccessSubscriptions();       
          case ApplicationFeature.SITE_MANAGEMENT:     
          case ApplicationFeature.SITE_OPERATION:
            return this.rightService.canAccessSiteOperationManagement();
          default:
            return true;
        }
      }
}
