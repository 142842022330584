/* tslint:disable */

/**
 * days of the week:
 *   * MONDAY
 *   * TUESDAY
 *   * WEDNESDAY
 *   * THURSDAY
 *   * FRIDAY
 *   * SATURDAY
 *   * SUNDAY
 */
type DayOfWeek =
  'MONDAY' |
  'TUESDAY' |
  'WEDNESDAY' |
  'THURSDAY' |
  'FRIDAY' |
  'SATURDAY' |
  'SUNDAY';
module DayOfWeek {
  export const MONDAY: DayOfWeek = 'MONDAY';
  export const TUESDAY: DayOfWeek = 'TUESDAY';
  export const WEDNESDAY: DayOfWeek = 'WEDNESDAY';
  export const THURSDAY: DayOfWeek = 'THURSDAY';
  export const FRIDAY: DayOfWeek = 'FRIDAY';
  export const SATURDAY: DayOfWeek = 'SATURDAY';
  export const SUNDAY: DayOfWeek = 'SUNDAY';
  export function values(): DayOfWeek[] {
    return [
      MONDAY,
      TUESDAY,
      WEDNESDAY,
      THURSDAY,
      FRIDAY,
      SATURDAY,
      SUNDAY
    ];
  }
}

export { DayOfWeek }