/* tslint:disable */

/**
 * category of notes:
 *   * WARNING
 *   * ERROR
 *   * INFORMATION
 *   * WEATHER
 */
type InformationMessageCategory =
  'WARNING' |
  'ERROR' |
  'INFORMATION' |
  'WEATHER';
module InformationMessageCategory {
  export const WARNING: InformationMessageCategory = 'WARNING';
  export const ERROR: InformationMessageCategory = 'ERROR';
  export const INFORMATION: InformationMessageCategory = 'INFORMATION';
  export const WEATHER: InformationMessageCategory = 'WEATHER';
  export function values(): InformationMessageCategory[] {
    return [
      WARNING,
      ERROR,
      INFORMATION,
      WEATHER
    ];
  }
}

export { InformationMessageCategory }