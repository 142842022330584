import { StationState } from "src/swagger-gen/web-secured/models";
import { TicketFilterModel } from "./TicketFilterModel";

export class KpiModel extends TicketFilterModel {
    icon: string;
    value: number;
    label: string;
    displayValue: string;
    className: string;
    constructor(value: number, label: string, icon: string,state?: StationState,filter: boolean = false, clicked: boolean = false, className?: string) {
        super(filter,clicked,state);
        this.value = value;
        this.label = label;
        this.icon = icon;
        this.displayValue = this.value + ' ' + label;
        this.className = className;
    }


}
