import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { OktaCallbackComponent } from '@okta/okta-angular';

import { RouteId } from './enums/env/routes-id-enum';
import { AdministrationGuard } from './modules/administration/guards/administration-guard';
import { AlarmGuard } from './modules/alarms/guards/alarm-guard';
import { BadgeGuard } from './modules/badges/guards/badge-guard';
import { DashboardGNLGuard } from './modules/dashboard-gnl/guards/dashboard-gnl-guard';
import { DashboardGuard } from './modules/dashboard/guards/dashboard-guard';
import { GraphGuard } from './modules/graphs/guards/graph-guard';
import { HomeComponent } from './modules/home/home.component';
import { StationGuard } from './modules/stations/guards/station-guard';
import { CustomerAccountsGuard } from './modules/customer-accounts/guards/customer-accounts-guard.service';
import { TicketGuard } from './modules/tickets/guards/ticket-guard';
import { VehicleGuard } from './modules/vehicles/guards/vehicle-guard';
import { RoutingService } from './services/Routing.service';
import { ErrorComponent } from './shared/components/error/error.component';
import { AppGuard } from './tinea-components/okta/auth/guards/app-guard';


const sharedRoutes: Routes = [
  { path: 'implicit/callback', component: OktaCallbackComponent },
];

const desktopRoutes: Routes = [
  ...sharedRoutes,
  // default route
  {
    path: '',
    canActivate: [AppGuard],
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: RoutingService.getPathRoute(RouteId.DASHBOARD),
    canActivate: [AppGuard, DashboardGuard],
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: RoutingService.getPathRoute(RouteId.DASHBOARD_GNL),
    canActivate: [AppGuard, DashboardGNLGuard],
    loadChildren: () => import('./modules/dashboard-gnl/dashboard-gnl.module').then(m => m.DashboardGNLModule)
  },
  {
    path: RoutingService.getPathRoute(RouteId.TICKETS),
    canActivate: [AppGuard, TicketGuard],
    loadChildren: () => import('./modules/tickets/tickets.module').then(m => m.TicketsModule)
  },
  {
    path: RoutingService.getPathRoute(RouteId.MAP),
    canActivate: [AppGuard],
    loadChildren: () => import('./modules/map/map.module').then(m => m.MapModule)
  },
  {
    path: RoutingService.getPathRoute(RouteId.ALARMS),
    canActivate: [AppGuard, AlarmGuard],
    loadChildren: () => import('./modules/alarms/alarms.module').then(m => m.AlarmsModule)
  },
  {
    path: RoutingService.getPathRoute(RouteId.EMS),
    canActivate: [AppGuard],
    loadChildren: () => import('./modules/ems/ems.module').then(m => m.EmsModule)
  },
  {
    path: RoutingService.getPathRoute(RouteId.STATIONS),
    canActivate: [AppGuard, StationGuard],
    loadChildren: () => import('./modules/stations/stations.module').then(m => m.StationsModule)
  },
  {
    path: RoutingService.getPathRoute(RouteId.GRAPHS),
    canActivate: [AppGuard, GraphGuard],
    loadChildren: () => import('./modules/graphs/graphs.module').then(m => m.GraphsModule)
  },
  {
    path: RoutingService.getPathRoute(RouteId.CUSTOMER_ACCOUNTS),
    canActivate: [AppGuard, CustomerAccountsGuard],
    loadChildren: () => import('./modules/customer-accounts/customer-accounts.module').then(m => m.CustomerAccountsModule)
  },
  {
    path: RoutingService.getPathRoute(RouteId.BADGES),
    canActivate: [AppGuard, BadgeGuard],
    data: {
      routeId: RouteId.BADGES,
    },
    loadChildren: () => import('./modules/badges/badges.module').then(m => m.BadgesModule)
  },
  {
    path: RoutingService.getPathRoute(RouteId.VEHICLES),
    canActivate: [AppGuard, VehicleGuard],
    loadChildren: () => import('./modules/vehicles/vehicles.module').then(m => m.VehiclesModule)
  },
  {
    path: RoutingService.getPathRoute(RouteId.NOTIFICATIONS),
    canActivate: [AppGuard],
    loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule)
  },
  {
    path: RoutingService.getPathRoute(RouteId.ADMINISTRATION),
    canActivate: [AppGuard, AdministrationGuard],
    loadChildren: () => import('./modules/administration/administration.module').then(m => m.AdministrationModule)
  },
  {
    path: 'acquit-ticket/:uid',
    canActivate: [AppGuard, TicketGuard],
    loadChildren: () => import('./modules/acquit-ticket/acquit-ticket.module').then(m => m.AcquitTicketModule)
  },
  {
    path: 'error',
    canActivate: [AppGuard],
    component: ErrorComponent,
    pathMatch: 'full',
  },
  // a wildcard route to intercept invalid URLs
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(
      desktopRoutes, // Desktop by default
      {
        preloadingStrategy: PreloadAllModules,
        enableTracing: false // <-- shows routing events in console, debugging purposes only
      }
    ),
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
