/* tslint:disable */

/**
 * The type of auth support:
 *   * RFID
 *   * HID
 *   * LICENCE_PLATE
 *   * QR_CODE
 *   * UNKNOWN
 */
type AuthSupportType =
  'RFID' |
  'HID' |
  'LICENCE_PLATE' |
  'QR_CODE' |
  'UNKNOWN';
module AuthSupportType {
  export const RFID: AuthSupportType = 'RFID';
  export const HID: AuthSupportType = 'HID';
  export const LICENCE_PLATE: AuthSupportType = 'LICENCE_PLATE';
  export const QR_CODE: AuthSupportType = 'QR_CODE';
  export const UNKNOWN: AuthSupportType = 'UNKNOWN';
  export function values(): AuthSupportType[] {
    return [
      RFID,
      HID,
      LICENCE_PLATE,
      QR_CODE,
      UNKNOWN
    ];
  }
}

export { AuthSupportType }