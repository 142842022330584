<span *ngIf="loadingModel.isLoading; else graph" [model]="loadingModel" class="ui-loading"></span>
<ng-template #graph>
    <div *ngIf="isQueryPresent && !loading" class="query-renderer-wrap" style="height: 100%">
        <div *ngIf="noData; else data"
             class="query-renderer-no-data">{{ 'component.queryRenderer.noData' | translate }}
        </div>
        <ng-template #data>

            <div
                    *ngIf="chartType !== 'table' && chartType !== 'number'"
                    class="chart-container"
            >
                <canvas
                        *ngIf="chartType === 'line'"
                        [datasets]="chartData"
                        [labels]="chartLabels"
                        [options]="noFillChartOptions"
                        baseChart
                        chartType="line"
                        legend="true"
                        type="line"
                >
                </canvas>

                <canvas
                        *ngIf="chartType === 'area'"
                        [datasets]="chartData"
                        [labels]="chartLabels"
                        [options]="chartOptions"
                        baseChart
                        chartType="line"
                        legend="true"
                >
                </canvas>

                <canvas
                        *ngIf="chartType === 'bar'"
                        [class]="itemHovered"
                        [datasets]="chartData"
                        [labels]="chartLabels"
                        [options]="chartOptions"
                        baseChart
                        chartType="bar"
                        legend="true"
                >
                </canvas>

                <canvas
                        *ngIf="chartType === 'pie'"
                        [data]="chartCircularData"
                        [options]="chartOptions"
                        [type]="chartType"
                        baseChart
                        legend="true">
                </canvas>
                <canvas
                        *ngIf="chartType === 'doughnut'"
                        [data]="chartCircularData"
                        [options]="chartOptions"
                        [type]="chartType"
                        baseChart
                        legend="false"
                >
                </canvas>
            </div>

            <table *ngIf="chartType === 'table'"
                   [dataSource]="tableData"
                   mat-table
                   style="width: 100%"
            >
                <ng-container *ngFor="let column of displayedColumns; let index = index" [matColumnDef]="column">
                    <th *matHeaderCellDef mat-header-cell>{{ columnTitles[index] }}</th>
                    <td *matCellDef="let element" mat-cell>{{ element[column] }}</td>
                </ng-container>

                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
            </table>

            <div *ngIf="chartType === 'number'" class="numeric-container">
                <span *ngFor="let value of numericValues">{{ value | number }}</span>
            </div>
        </ng-template>
    </div>
</ng-template>
  