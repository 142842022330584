/* tslint:disable */

/**
 * The state of the auth support:
 *   * ACTIVE
 *   * BLOCKED
 *   * REMOVED
 */
type AuthSupportState =
  'ACTIVE' |
  'BLOCKED' |
  'REMOVED';
module AuthSupportState {
  export const ACTIVE: AuthSupportState = 'ACTIVE';
  export const BLOCKED: AuthSupportState = 'BLOCKED';
  export const REMOVED: AuthSupportState = 'REMOVED';
  export function values(): AuthSupportState[] {
    return [
      ACTIVE,
      BLOCKED,
      REMOVED
    ];
  }
}

export { AuthSupportState }