/* tslint:disable */

/**
 * field to filter for the configured notes search:
 *   * NAME : the note's name
 */
type ConfiguredMessageSearchFilter =
  'NAME';
module ConfiguredMessageSearchFilter {
  export const NAME: ConfiguredMessageSearchFilter = 'NAME';
  export function values(): ConfiguredMessageSearchFilter[] {
    return [
      NAME
    ];
  }
}

export { ConfiguredMessageSearchFilter }