import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UiLoadingModel } from '../../models/UiLoadingModel';

@Component({
  selector: '.ui-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingComponent implements OnInit {

  @Input() model: UiLoadingModel;
  constructor() { }

  ngOnInit(): void {
  }

}
