/* tslint:disable */

/**
 * The export data type:
 *   * ANALOGIC: Export analogic data
 *   * EVENT: Export event data
 */
type ExportDataType =
  'ANALOGIC' |
  'EVENT';
module ExportDataType {
  export const ANALOGIC: ExportDataType = 'ANALOGIC';
  export const EVENT: ExportDataType = 'EVENT';
  export function values(): ExportDataType[] {
    return [
      ANALOGIC,
      EVENT
    ];
  }
}

export { ExportDataType }