/* tslint:disable */

/**
 * field to filter for alert search
 */
type AlertSearchFilter =
  'STATION_NAME' |
  'STATION_CODE';
module AlertSearchFilter {
  export const STATION_NAME: AlertSearchFilter = 'STATION_NAME';
  export const STATION_CODE: AlertSearchFilter = 'STATION_CODE';
  export function values(): AlertSearchFilter[] {
    return [
      STATION_NAME,
      STATION_CODE
    ];
  }
}

export { AlertSearchFilter }