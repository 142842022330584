/* tslint:disable */

/**
 * field to filter for partner operator search:
 *   * ALL : All fields
 *   * NAME
 */
type PartnerOperatorSearchFilter =
  'ALL' |
  'NAME';
module PartnerOperatorSearchFilter {
  export const ALL: PartnerOperatorSearchFilter = 'ALL';
  export const NAME: PartnerOperatorSearchFilter = 'NAME';
  export function values(): PartnerOperatorSearchFilter[] {
    return [
      ALL,
      NAME
    ];
  }
}

export { PartnerOperatorSearchFilter }