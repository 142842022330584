/* tslint:disable */

/**
 * Indicates the state of the subscription:
 *   * ENABLED : The subscription is fully ENABLED
 *   * PLANNED_ENABLED : The subscription is DISABLED but is planned to become ENABLED at a specified date
 *   * DISABLED: The subscription is fully DISABLED
 *   * PLANNED_DISABLED: The subscription is ENABLED but is planned to become DISABLED at a specified date
 */
type SubscriptionState =
  'ENABLED' |
  'PLANNED_ENABLED' |
  'DISABLED' |
  'PLANNED_DISABLED';
module SubscriptionState {
  export const ENABLED: SubscriptionState = 'ENABLED';
  export const PLANNED_ENABLED: SubscriptionState = 'PLANNED_ENABLED';
  export const DISABLED: SubscriptionState = 'DISABLED';
  export const PLANNED_DISABLED: SubscriptionState = 'PLANNED_DISABLED';
  export function values(): SubscriptionState[] {
    return [
      ENABLED,
      PLANNED_ENABLED,
      DISABLED,
      PLANNED_DISABLED
    ];
  }
}

export { SubscriptionState }