/* tslint:disable */

/**
 * health state
 */
type StationHealthState =
  'GOOD' |
  'BAD' |
  'UNKNOWN';
module StationHealthState {
  export const GOOD: StationHealthState = 'GOOD';
  export const BAD: StationHealthState = 'BAD';
  export const UNKNOWN: StationHealthState = 'UNKNOWN';
  export function values(): StationHealthState[] {
    return [
      GOOD,
      BAD,
      UNKNOWN
    ];
  }
}

export { StationHealthState }