import { NgModule } from '@angular/core';
import {FiltersTableComponent} from './filters-table.component';
import {CommonModule} from '@angular/common';
import {DateTimePickerStaticModule} from '../../../tinea-components/dateTime-picker/dateTime-picker.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatLegacyProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {LoadingModule} from '../../../tinea-components/loading/loading.module';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    FiltersTableComponent,
  ],
  imports: [
    CommonModule,
    DateTimePickerStaticModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatLegacyProgressSpinnerModule,
    LoadingModule,
    FormsModule,
    DropdownModule
  ],
  exports: [
    FiltersTableComponent,
  ],
})
export class FiltersTableModule {}
