/* tslint:disable */

/**
 * The user types:
 *   * ADMIN : an administrator
 *   * OPERATOR : an operator
 *   * CLIENT : a client
 *   * PUBLIC : a public user
 */
type UserType =
  'ADMIN' |
  'OPERATOR' |
  'CLIENT' |
  'PUBLIC';
module UserType {
  export const ADMIN: UserType = 'ADMIN';
  export const OPERATOR: UserType = 'OPERATOR';
  export const CLIENT: UserType = 'CLIENT';
  export const PUBLIC: UserType = 'PUBLIC';
  export function values(): UserType[] {
    return [
      ADMIN,
      OPERATOR,
      CLIENT,
      PUBLIC
    ];
  }
}

export { UserType }