/* tslint:disable */

/**
 * The meter value tag available
 */
type ChargePointMeterValuesTag =
  'VALUE' |
  'CURRENT.EXPORT' |
  'CURRENT.IMPORT' |
  'CURRENT.OFFERED' |
  'ENERGY.ACTIVE.EXPORT.REGISTER' |
  'ENERGY.ACTIVE.IMPORT.REGISTER' |
  'ENERGY.REACTIVE.EXPORT.REGISTER' |
  'ENERGY.REACTIVE.IMPORT.REGISTER' |
  'ENERGY.ACTIVE.EXPORT.INTERVAL' |
  'ENERGY.ACTIVE.IMPORT.INTERVAL' |
  'ENERGY.REACTIVE.EXPORT.INTERVAL' |
  'ENERGY.REACTIVE.IMPORT.INTERVAL' |
  'FREQUENCY' |
  'POWER.ACTIVE.EXPORT' |
  'POWER.ACTIVE.IMPORT' |
  'POWER.FACTOR' |
  'POWER.OFFERED' |
  'POWER.REACTIVE.EXPORT' |
  'POWER.REACTIVE.IMPORT' |
  'RPM' |
  'SOC' |
  'TEMPERATURE' |
  'VOLTAGE';
module ChargePointMeterValuesTag {
  export const VALUE: ChargePointMeterValuesTag = 'VALUE';
  export const CURRENT_EXPORT: ChargePointMeterValuesTag = 'CURRENT.EXPORT';
  export const CURRENT_IMPORT: ChargePointMeterValuesTag = 'CURRENT.IMPORT';
  export const CURRENT_OFFERED: ChargePointMeterValuesTag = 'CURRENT.OFFERED';
  export const ENERGY_ACTIVE_EXPORT_REGISTER: ChargePointMeterValuesTag = 'ENERGY.ACTIVE.EXPORT.REGISTER';
  export const ENERGY_ACTIVE_IMPORT_REGISTER: ChargePointMeterValuesTag = 'ENERGY.ACTIVE.IMPORT.REGISTER';
  export const ENERGY_REACTIVE_EXPORT_REGISTER: ChargePointMeterValuesTag = 'ENERGY.REACTIVE.EXPORT.REGISTER';
  export const ENERGY_REACTIVE_IMPORT_REGISTER: ChargePointMeterValuesTag = 'ENERGY.REACTIVE.IMPORT.REGISTER';
  export const ENERGY_ACTIVE_EXPORT_INTERVAL: ChargePointMeterValuesTag = 'ENERGY.ACTIVE.EXPORT.INTERVAL';
  export const ENERGY_ACTIVE_IMPORT_INTERVAL: ChargePointMeterValuesTag = 'ENERGY.ACTIVE.IMPORT.INTERVAL';
  export const ENERGY_REACTIVE_EXPORT_INTERVAL: ChargePointMeterValuesTag = 'ENERGY.REACTIVE.EXPORT.INTERVAL';
  export const ENERGY_REACTIVE_IMPORT_INTERVAL: ChargePointMeterValuesTag = 'ENERGY.REACTIVE.IMPORT.INTERVAL';
  export const FREQUENCY: ChargePointMeterValuesTag = 'FREQUENCY';
  export const POWER_ACTIVE_EXPORT: ChargePointMeterValuesTag = 'POWER.ACTIVE.EXPORT';
  export const POWER_ACTIVE_IMPORT: ChargePointMeterValuesTag = 'POWER.ACTIVE.IMPORT';
  export const POWER_FACTOR: ChargePointMeterValuesTag = 'POWER.FACTOR';
  export const POWER_OFFERED: ChargePointMeterValuesTag = 'POWER.OFFERED';
  export const POWER_REACTIVE_EXPORT: ChargePointMeterValuesTag = 'POWER.REACTIVE.EXPORT';
  export const POWER_REACTIVE_IMPORT: ChargePointMeterValuesTag = 'POWER.REACTIVE.IMPORT';
  export const RPM: ChargePointMeterValuesTag = 'RPM';
  export const SOC: ChargePointMeterValuesTag = 'SOC';
  export const TEMPERATURE: ChargePointMeterValuesTag = 'TEMPERATURE';
  export const VOLTAGE: ChargePointMeterValuesTag = 'VOLTAGE';
  export function values(): ChargePointMeterValuesTag[] {
    return [
      VALUE,
      CURRENT_EXPORT,
      CURRENT_IMPORT,
      CURRENT_OFFERED,
      ENERGY_ACTIVE_EXPORT_REGISTER,
      ENERGY_ACTIVE_IMPORT_REGISTER,
      ENERGY_REACTIVE_EXPORT_REGISTER,
      ENERGY_REACTIVE_IMPORT_REGISTER,
      ENERGY_ACTIVE_EXPORT_INTERVAL,
      ENERGY_ACTIVE_IMPORT_INTERVAL,
      ENERGY_REACTIVE_EXPORT_INTERVAL,
      ENERGY_REACTIVE_IMPORT_INTERVAL,
      FREQUENCY,
      POWER_ACTIVE_EXPORT,
      POWER_ACTIVE_IMPORT,
      POWER_FACTOR,
      POWER_OFFERED,
      POWER_REACTIVE_EXPORT,
      POWER_REACTIVE_IMPORT,
      RPM,
      SOC,
      TEMPERATURE,
      VOLTAGE
    ];
  }
}

export { ChargePointMeterValuesTag }