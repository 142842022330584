/* tslint:disable */

/**
 * The Agent event type :
 * * CPO: Charge Point Operator Role.
 * * EMSP: eMobility Service Provider Role.
 * * HUB: Hub role.
 * * NAP: National Access Point Role (national Database with all Location information of a country).
 * * NSP: Navigation Service Provider Role, role like an eMSP (probably only interested in Location information).
 * * OTHER: Other role.
 * * SCSP: Smart Charging Service Provider Role.
 */
type OcpiRole =
  'CPO' |
  'EMSP' |
  'HUB' |
  'NAP' |
  'NSP' |
  'OTHER' |
  'SCSP';
module OcpiRole {
  export const CPO: OcpiRole = 'CPO';
  export const EMSP: OcpiRole = 'EMSP';
  export const HUB: OcpiRole = 'HUB';
  export const NAP: OcpiRole = 'NAP';
  export const NSP: OcpiRole = 'NSP';
  export const OTHER: OcpiRole = 'OTHER';
  export const SCSP: OcpiRole = 'SCSP';
  export function values(): OcpiRole[] {
    return [
      CPO,
      EMSP,
      HUB,
      NAP,
      NSP,
      OTHER,
      SCSP
    ];
  }
}

export { OcpiRole }