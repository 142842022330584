/* tslint:disable */

/**
 * field to filter for alarm search:
 *   * ALL : All fields
 *   * SUBTYPE : the event subtype
 *   * CONNECTION_NAME : the event connection name
 */
type AgentEventSearchFilter =
  'ALL' |
  'SUBTYPE' |
  'CONNECTION_NAME';
module AgentEventSearchFilter {
  export const ALL: AgentEventSearchFilter = 'ALL';
  export const SUBTYPE: AgentEventSearchFilter = 'SUBTYPE';
  export const CONNECTION_NAME: AgentEventSearchFilter = 'CONNECTION_NAME';
  export function values(): AgentEventSearchFilter[] {
    return [
      ALL,
      SUBTYPE,
      CONNECTION_NAME
    ];
  }
}

export { AgentEventSearchFilter }