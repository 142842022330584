/* tslint:disable */

/**
 * field to filter for auth support search:
 *   * ALL : All fields
 *   * TYPE
 *   * PROVIDER
 *   * VALUE
 */
type AuthSupportSearchFilter =
  'ALL' |
  'TYPE' |
  'PROVIDER' |
  'VALUE';
module AuthSupportSearchFilter {
  export const ALL: AuthSupportSearchFilter = 'ALL';
  export const TYPE: AuthSupportSearchFilter = 'TYPE';
  export const PROVIDER: AuthSupportSearchFilter = 'PROVIDER';
  export const VALUE: AuthSupportSearchFilter = 'VALUE';
  export function values(): AuthSupportSearchFilter[] {
    return [
      ALL,
      TYPE,
      PROVIDER,
      VALUE
    ];
  }
}

export { AuthSupportSearchFilter }