import { Injectable } from '@angular/core';
import { chunk, indexOf } from 'lodash';
import { ImmerComponentStore } from 'ngrx-immer/component-store';
import {
  combineLatestWith,
  debounceTime,
  distinct,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { isNotNullOrUndefined } from 'src/app/services/utils/filter-typeguard.util';

const loadingStateIdle = 'idle';
const loadingStateLoaded = 'loaded';
const loadingState = [loadingStateIdle, loadingStateLoaded] as const;
type LoadingStateType = (typeof loadingState)[number];

interface LoadingStateItem {
  loadingState: LoadingStateType;
}

/**
 * Define State
 * loadingStateItem: Local storage saving purpose
 * params: input params passed from Parent Component
 * selectedPage
 * pageSized
 * indexChunkedPages: for computing current range of displaying pages
 */
export type State = {
  loadingStateItem: LoadingStateItem;
  params: PaginationParams | undefined;
  selectedPage: number;
  pageSize: number | undefined;
  indexChunkedPages: number;
};

export type PaginationParams = {
  totalItems: number;
};

const defaultState: State = {
  loadingStateItem: { loadingState: loadingStateIdle },
  params: undefined,
  selectedPage: 1,
  pageSize: undefined,
  indexChunkedPages: 0,
};

export type ViewConfig = {
  totalItems: number;
  from: number;
  to: number;
  pageSize: number;
  selectedPage: number;
  totalPages: number;
  pages: number[];
  options: number[];
  angleLeftDisabled: boolean;
  angleRightDisabled: boolean;
  hasBeforePages: boolean;
  hasAfterPages: boolean;
  indexChunkedPages: number;
};

@Injectable()
export class PaginationStore extends ImmerComponentStore<State> {
  constructor() {
    super(defaultState);
    // this.loggerEffect(this.state$);

    this.loadStateEffect(this.state$.pipe(take(1)));
    this.saveStateEffect(this.state$);
  }

  /**
   * Selectors (observables) that can be subscribed 
   */
  private readonly params$ = this.select((state) => state.params);
  private readonly selectedPage$ = this.select((state) => state.selectedPage);
  private readonly pageSize$ = this.select((state) => state.pageSize);
  private readonly indexChunkedPages$ = this.select(
    (state) => state.indexChunkedPages
  );

  private readonly urlPath$ = of(window.location.pathname);
  // We create a tableId based on current url for saving purpose
  private readonly paginationId$ = this.select(
    this.urlPath$,
    (path) => `${PAGINATION_ID_PREFIX}::${path}`
  );

  readonly offset$ = this.select(
    this.selectedPage$.pipe(
      filter(isNotNullOrUndefined),
      distinctUntilChanged()
    ),
    this.pageSize$.pipe(filter(isNotNullOrUndefined), distinctUntilChanged()),
    this.params$.pipe(filter(isNotNullOrUndefined), distinctUntilChanged()),
    (selectedPage, pageSize, { totalItems }) => {
      const low = (selectedPage - 1) * pageSize;
      const high = selectedPage * pageSize - 1;
      return {
        low,
        high: high > totalItems ? totalItems : high,
      };
    }
  );

  private readonly chunkedPages$ = this.select(
    this.params$.pipe(filter(isNotNullOrUndefined)),
    this.pageSize$.pipe(filter(isNotNullOrUndefined)),
    ({ totalItems }, pageSize) => {
      const totalPages = Math.ceil(totalItems / pageSize);
      let allPages: number[] = [];
      for (let i = 1; i <= totalPages; i++) {
        allPages = [...allPages, i];
      }
      return chunk(allPages, CHUNK_SIZE);
    }
  );

  // For rendering HTML template
  readonly viewConfig$: Observable<ViewConfig> = this.select(
    this.params$.pipe(filter(isNotNullOrUndefined)),
    this.selectedPage$.pipe(filter(isNotNullOrUndefined)),
    this.pageSize$.pipe(filter(isNotNullOrUndefined)),
    this.offset$,
    this.indexChunkedPages$,
    this.chunkedPages$,
    (
      { totalItems },
      selectedPage,
      pageSize,
      offset,
      indexChunkedPages,
      chunkedPages
    ) => {
      const totalPages = Math.ceil(totalItems / pageSize);
      return {
        totalItems,
        selectedPage,
        from: offset.low + 1,
        to: offset.high + 1 >= totalItems ? totalItems : offset.high + 1,
        pageSize,
        totalPages,
        pages: chunkedPages[indexChunkedPages],
        hasBeforePages: indexChunkedPages > 0,
        hasAfterPages: indexChunkedPages < chunkedPages.length - 1,
        options: [15, 25, 50],
        angleLeftDisabled: selectedPage === 1,
        angleRightDisabled: selectedPage === totalPages,
        indexChunkedPages,
      };
    }
  );

  /**
   * Updaters
   * We don't do any side effects here, only for updating states
   */
  readonly updateParams = this.updater<PaginationParams>((state, value) => {
    state.params = value;
  });
  readonly updatePageSize = this.updater<number>((state, value) => {
    state.pageSize = value;
  });
  readonly updateSelectedPage = this.updater<number>((state, value) => {
    state.selectedPage = value;
  });
  readonly updateIndexChunkedPages = this.updater<number>((state, value) => {
    state.indexChunkedPages = value;
  });

  /**
   * Effects
   * Actions that change states
   */
  readonly updateParamsEffect = this.effect<PaginationParams>(($) =>
    $.pipe(
      debounceTime(500), // preventing unstable input params
      tap((table: PaginationParams) => {
        this.updateSelectedPage(1);
        this.updateIndexChunkedPages(0);
        this.updateParams(table);
      })
    )
  );

  readonly updateSelectedPageEffect = this.effect<number>(($) =>
    $.pipe(
      withLatestFrom(this.chunkedPages$),
      tap(([selectedPage, chunkedPages]) => {
        this.updateSelectedPage(selectedPage);
        chunkedPages.forEach((page, i) => {
          if (page.includes(selectedPage)) {
            this.updateIndexChunkedPages(i);
          }
        });
      })
    )
  );
  readonly updatePageSizeEffect = this.effect<number>(($) =>
    $.pipe(
      tap((pageSize: number) => {
        this.updatePageSize(pageSize);
        this.updateSelectedPage(1);
        this.updateIndexChunkedPages(0);
      })
    )
  );

  // Get saved pageSize if there are
  private readonly loadStateEffect = this.effect<State>((state$) =>
    state$.pipe(
      filter(
        ({ loadingStateItem }) =>
          loadingStateItem.loadingState === loadingStateIdle
      ),
      combineLatestWith(this.paginationId$.pipe(take(1))),
      switchMap(([, id]) =>
        of(window.localStorage.getItem(id)).pipe(
          tap((props) => {
            if (isNotNullOrUndefined(props)) {
              const parsedProps = JSON.parse(props);
              this.setState({
                ...defaultState,
                ...parsedProps,
                loadingStateItem: { loadingState: loadingStateLoaded },
              });
            } else {
              this.patchState({
                pageSize: 15, // default pageSize
                loadingStateItem: { loadingState: loadingStateLoaded },
              });
            }
          })
        )
      )
    )
  );

  // Save continuously pageSize when state changes
  private readonly saveStateEffect = this.effect<State>((state$) =>
    state$.pipe(
      filter(
        ({ loadingStateItem }) =>
          loadingStateItem.loadingState === loadingStateLoaded
      ),
      distinct((state) => `${state.pageSize}`),
      withLatestFrom(this.paginationId$),
      tap(([{ pageSize }, id]) => {
        const stringifiedProps = JSON.stringify({ pageSize });
        window.localStorage.setItem(id, stringifiedProps);
      })
    )
  );

  private readonly loggerEffect = this.effect<any>(($) =>
    $.pipe(tap((state) => console.log('[PaginationStore]', state)))
  );
}

// By default we display max 10 pages, if more we display "..."
const CHUNK_SIZE = 10;

export const PAGINATION_ID_PREFIX = 'PAGINATION-STORE';