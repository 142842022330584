import { IconType } from 'src/app/tinea-components/icon/enum/icon-type';

export class UiIcon {

    type: IconType;
    value: string;              // eg: './foo/bar.png' | 'icon-zap'
    alt?: string;               // custom alt for the image
    cssClass?: string;          // custom css class
    dataId?: string;            // custom id to identify element
    disabled: boolean;
    title: string;
    dataAttr: string;
    style: any;
    
    constructor() {
        this.disabled = false;
        this.alt = '';
        this.title = null;
        this.dataAttr = null;
    }
}
