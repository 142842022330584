<div class="loading__ctn">
  <span *ngIf="model.isLoading" class="wrap-spinner">
    <img *ngIf="model.icon; else defaultLoading" [src]="model.icon.value" [alt]="model.icon.alt"
      [class]="model.icon.cssClass">
    <ng-template #defaultLoading>
      <div class="jumping-dots">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </ng-template>
    <span *ngIf="model.text" [class]="model.cssClassText">{{model.text.value}}</span>
  </span>
</div>