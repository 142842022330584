/* tslint:disable */

/**
 * Type purpose of the charging profile
 */
type ChargePointChargingProfilePurposeType =
  'CHARGE_POINT_MAX_PROFILE' |
  'TX_DEFAULT_PROFILE' |
  'TX_PROFILE';
module ChargePointChargingProfilePurposeType {
  export const CHARGE_POINT_MAX_PROFILE: ChargePointChargingProfilePurposeType = 'CHARGE_POINT_MAX_PROFILE';
  export const TX_DEFAULT_PROFILE: ChargePointChargingProfilePurposeType = 'TX_DEFAULT_PROFILE';
  export const TX_PROFILE: ChargePointChargingProfilePurposeType = 'TX_PROFILE';
  export function values(): ChargePointChargingProfilePurposeType[] {
    return [
      CHARGE_POINT_MAX_PROFILE,
      TX_DEFAULT_PROFILE,
      TX_PROFILE
    ];
  }
}

export { ChargePointChargingProfilePurposeType }