/* tslint:disable */

/**
 * The synchronization state of the auth support:
 *   * SYNCHRONIZED
 *   * TO_BE_SYNCHRONIZED
 */
type AuthSupportSynchroState =
  'SYNCHRONIZED' |
  'TO_BE_SYNCHRONIZED';
module AuthSupportSynchroState {
  export const SYNCHRONIZED: AuthSupportSynchroState = 'SYNCHRONIZED';
  export const TO_BE_SYNCHRONIZED: AuthSupportSynchroState = 'TO_BE_SYNCHRONIZED';
  export function values(): AuthSupportSynchroState[] {
    return [
      SYNCHRONIZED,
      TO_BE_SYNCHRONIZED
    ];
  }
}

export { AuthSupportSynchroState }