import { StationState } from "src/swagger-gen/web-secured/models";

export class TicketFilterModel {
    filter: boolean; // permet de savoir si c'est filtrable, pour ensuite gerer le html
    state?: StationState;
    clicked: boolean;
    constructor(filter: boolean,clicked: boolean, state?: StationState, ) {
        this.filter = filter;
        this.clicked = clicked;
        if(state) {
            this.state = state;
        }
    }
    getClickVal() : boolean {
        return this.clicked;
    }
    setClicked(btnClicked: boolean) {
        this.clicked = btnClicked;
    }
}
