import {Injectable} from '@angular/core';
import {AvailabilityColor} from '../enums/availability-color.enum';
import {DateRangeCubeJs} from '../enums/cubejs/date-range';
import {GranularityCubeJs} from '../enums/cubejs/granularity';
import {GraphType} from '../enums/cubejs/graph-type';
import {MeasureKeyType} from '../enums/cubejs/measure-key.enum.ts';
import {DateRangeModel} from '../models/cubejs/DateRangeModel';
import {GranularityModel} from '../models/cubejs/granularityModel';
import {TransactionEnergyKeyType} from '../enums/cubejs/transaction-energy-key.enum';
import {TransactionEnergyColor} from '../enums/transaction-energy-color.enum';
import {XAxisLabelEnum} from '../enums/cubejs/x-axis-label.enum';
import {ConnectivityStation} from '../enums/cubejs/connectivity-station';

@Injectable({
    providedIn: 'root'
})
export class CubeJsService {

    readonly I18N_GRANULARITY_SECOND = 'granularity.second';
    readonly I18N_GRANULARITY_MINUTE = 'granularity.minute';
    readonly I18N_GRANULARITY_HOUR = 'granularity.hour';
    readonly I18N_GRANULARITY_DAY = 'granularity.day';
    readonly I18N_GRANULARITY_WEEK = 'granularity.week';
    readonly I18N_GRANULARITY_MONTH = 'granularity.month';
    readonly I18N_GRANULARITY_QUARTER = 'granularity.quarter';
    readonly I18N_GRANULARITY_YEAR = 'granularity.year';


    readonly I18N_DATE_RANGE_TODAY = 'timeGroup.today';
    readonly I18N_DATE_RANGE_YESTERDAY = 'timeGroup.yesterday';
    readonly I18N_DATE_RANGE_THIS_WEEK = 'timeGroup.thisWeek';
    readonly I18N_DATE_RANGE_THIS_MONTH = 'timeGroup.thisMonth';
    readonly I18N_DATE_RANGE_THIS_QUARTER = 'timeGroup.thisQuarter';
    readonly I18N_DATE_RANGE_THIS_YEAR = 'timeGroup.thisYear';
    readonly I18N_DATE_RANGE_LAST_7_DAYS = 'timeGroup.7days';
    readonly I18N_DATE_RANGE_LAST_30_DAYS = 'timeGroup.30days';
    readonly I18N_DATE_RANGE_LAST_WEEK = 'timeGroup.lastWeek';
    readonly I18N_DATE_RANGE_LAST_MONTH = 'timeGroup.lastMonth';
    readonly I18N_DATE_RANGE_LAST_QUARTER = 'timeGroup.lastQuarter';
    readonly I18N_DATE_RANGE_LAST_YEAR = 'timeGroup.lastYear';
    readonly I18N_DATE_RANGE_CUSTOM = 'timeGroup.custom';

    readonly DISPONIBILITE_AVAILABLE_PC_COLOR = AvailabilityColor.AVAILABLE;
    readonly DISPONIBILITE_PARTIALLY_PC_COLOR = AvailabilityColor.PARTIALLY_AVAILABLE;
    readonly DISPONIBILITE_UNVAILABLE_PC_COLOR = AvailabilityColor.UNAVAILABLE;
    readonly DISPONIBILITE_UNKNOWN_PC_COLOR = AvailabilityColor.UNKNOWN;
    readonly DISPONIBILITE_UNDEFINED_PC_COLOR = AvailabilityColor.UNDEFINED;

    readonly DISPONIBILITE_AVAILABLE_PC_COLOR_HOVER = AvailabilityColor.AVAILABLE_HOVER;
    readonly DISPONIBILITE_PARTIALLY_PC_COLOR_HOVER = AvailabilityColor.PARTIALLY_HOVER;
    readonly DISPONIBILITE_UNVAILABLE_PC_COLOR_HOVER = AvailabilityColor.UNAVAILABLE_HOVER;
    readonly DISPONIBILITE_UNKNOWN_PC_COLOR_HOVER = AvailabilityColor.UNKNOWN_HOVER;
    readonly DISPONIBILITE_UNDEFINED_PC_COLOR_HOVER = AvailabilityColor.UNDEFINED_HOVER;

    readonly BIOGNC_COLOR = TransactionEnergyColor.BIOGNC;
    readonly GNC_COLOR = TransactionEnergyColor.GNC;
    readonly GNCL_COLOR = TransactionEnergyColor.GNCL;
    readonly H2_COLOR = TransactionEnergyColor.H2;
    readonly ELEC_COLOR = TransactionEnergyColor.ELEC;
    readonly GNL_COLOR = TransactionEnergyColor.GNL;
    readonly OTHER_COLOR = TransactionEnergyColor.OTHER;
    readonly DEFAULT_COLOR = TransactionEnergyColor.DEFAULT;
    readonly BIOGNC_HOVER_COLOR = TransactionEnergyColor.BIOGNC_HOVER;
    readonly GNC_HOVER_COLOR = TransactionEnergyColor.GNC_HOVER;
    readonly GNCL_HOVER_COLOR = TransactionEnergyColor.GNCL_HOVER;
    readonly H2_HOVER_COLOR = TransactionEnergyColor.H2_HOVER;
    readonly ELEC_HOVER_COLOR = TransactionEnergyColor.ELEC_HOVER;
    readonly GNL_HOVER_COLOR = TransactionEnergyColor.GNL_HOVER;
    readonly OTHER_HOVER_COLOR = TransactionEnergyColor.OTHER_HOVER;
    readonly DEFAULT_HOVER_COLOR = TransactionEnergyColor.DEFAULT_HOVER;

    readonly CONNECTED_COLOR = AvailabilityColor.AVAILABLE;
    readonly DISCONNECTED_COLOR = AvailabilityColor.UNAVAILABLE;
    readonly CONNECTED_HOVER_COLOR = AvailabilityColor.AVAILABLE_HOVER;
    readonly DISCONNECTED_HOVER_COLOR = AvailabilityColor.UNAVAILABLE_HOVER;

    constructor() {

    }

    getGranularityList(blackList?: GranularityCubeJs[]): GranularityModel[] {
        const list: GranularityModel[] = [];
        Object.values(GranularityCubeJs).forEach(g => {
            if (!blackList || (blackList.length > 0 && !blackList.includes(g))) {
                list.push(this.getGranularity(g));
            }

        });
        return list;
    }

    getGranularity(value: GranularityCubeJs): GranularityModel {
        let name = '';

        switch (value) {
            case GranularityCubeJs.SECOND:
                name = this.I18N_GRANULARITY_SECOND;
                break;
            case GranularityCubeJs.MINUTE:
                name = this.I18N_GRANULARITY_MINUTE;
                break;
            case GranularityCubeJs.HOUR:
                name = this.I18N_GRANULARITY_HOUR;
                break;
            case GranularityCubeJs.DAY:
                name = this.I18N_GRANULARITY_DAY;
                break;
            case GranularityCubeJs.WEEK:
                name = this.I18N_GRANULARITY_WEEK;
                break;
            case GranularityCubeJs.MONTH:
                name = this.I18N_GRANULARITY_MONTH;
                break;
            case GranularityCubeJs.QUARTER:
                name = this.I18N_GRANULARITY_QUARTER;
                break;
            case GranularityCubeJs.YEAR:
                name = this.I18N_GRANULARITY_YEAR;
                break;
            default:
                console.error('Unknown granularity', value);
                break;
        }

        return new GranularityModel(name, value);
    }


    getDateRangeList(): DateRangeModel[] {
        const list: DateRangeModel[] = [];
        Object.values(DateRangeCubeJs).forEach(d => list.push(this.getDateRange(d)));
        return list;
    }

    getXAxisLabeList(): XAxisLabelEnum[] {
        let list: XAxisLabelEnum[] = Object.values(XAxisLabelEnum);
        return list;
    }

    getDateRange(value: DateRangeCubeJs): DateRangeModel {
        let name = '';

        switch (value) {
            case DateRangeCubeJs.TODAY:
                name = this.I18N_DATE_RANGE_TODAY;
                break;
            case DateRangeCubeJs.YESTERDAY:
                name = this.I18N_DATE_RANGE_YESTERDAY;
                break;
            case DateRangeCubeJs.THIS_WEEK:
                name = this.I18N_DATE_RANGE_THIS_WEEK;
                break;
            case DateRangeCubeJs.THIS_MONTH:
                name = this.I18N_DATE_RANGE_THIS_MONTH;
                break;
            case DateRangeCubeJs.THIS_YEAR:
                name = this.I18N_DATE_RANGE_THIS_YEAR;
                break;
            case DateRangeCubeJs.THIS_QUARTER:
                name = this.I18N_DATE_RANGE_THIS_QUARTER;
                break;
            case DateRangeCubeJs.LAST_7_DAYS:
                name = this.I18N_DATE_RANGE_LAST_7_DAYS;
                break;
            case DateRangeCubeJs.LAST_30_DAYS:
                name = this.I18N_DATE_RANGE_LAST_30_DAYS;
                break;
            case DateRangeCubeJs.LAST_WEEK:
                name = this.I18N_DATE_RANGE_LAST_WEEK;
                break;
            case DateRangeCubeJs.LAST_MONTH:
                name = this.I18N_DATE_RANGE_LAST_MONTH;
                break;
            case DateRangeCubeJs.LAST_QUARTER:
                name = this.I18N_DATE_RANGE_LAST_QUARTER;
                break;
            case DateRangeCubeJs.LAST_YEAR:
                name = this.I18N_DATE_RANGE_LAST_YEAR;
                break;
            case DateRangeCubeJs.CUSTOM:
                name = this.I18N_DATE_RANGE_CUSTOM;
                break;
            default:
                console.error('Unknown date range');
                break;
        }

        return new DateRangeModel(name, value);
    }

    getColorLabelByDisponibilityMeasureKey(measure: MeasureKeyType): string {
        let color = '';

        switch (measure.substring(measure.lastIndexOf('.') + 1)) {
            case MeasureKeyType.DISPONIBILITE_AVAILABLE_PC:
                color = this.DISPONIBILITE_AVAILABLE_PC_COLOR;
                break;
            case MeasureKeyType.DISPONIBILITE_PARTIALLY_PC:
                color = this.DISPONIBILITE_PARTIALLY_PC_COLOR;
                break;
            case MeasureKeyType.DISPONIBILITE_UNKNOWN_PC:
                color = this.DISPONIBILITE_UNKNOWN_PC_COLOR;
                break;
            case MeasureKeyType.DISPONIBILITE_UNVAILABLE_PC:
                color = this.DISPONIBILITE_UNVAILABLE_PC_COLOR;
                break;
            default:
                color = this.DISPONIBILITE_UNDEFINED_PC_COLOR;
                break;
        }

        return color;
    }

    getColorLabelByConnectivityMeasureKey(measure: string, graphType: GraphType): string {
        const cubeKey = graphType === GraphType.CONNECTIVITY_STATION ? 'StationHealth.' : 'LafonConnectionLog.';
        let splitValue = measure.split(cubeKey)[1];
        return this.getDefineConnectivityStationColor(splitValue);
    }

    getColorHoverLabelByConnectivityMeasureKey(measure: string, graphType: GraphType): string {
        const cubeKey = graphType === GraphType.CONNECTIVITY_STATION ? 'StationHealth.' : 'LafonConnectionLog.';
        let splitValue = measure.split(cubeKey)[1];
        return this.getDefineConnectivityStationHoverColor(splitValue);
    }

    getDefineConnectivityStationColor(value: string) {
        switch (value.toLowerCase()) {
            case ConnectivityStation.CONNECTED :
                return this.CONNECTED_COLOR;
            default:
                return this.DISCONNECTED_COLOR;
        }
    }

    getDefineConnectivityStationHoverColor(value: string) {
        switch (value.toLowerCase()) {
            case ConnectivityStation.CONNECTED :
                return this.CONNECTED_HOVER_COLOR;
            default:
                return this.DISCONNECTED_HOVER_COLOR;
        }
    }

    getColorLabelByTransactionMeasureKey(measure: string): string {
        let splitValue = measure.split('count')[1] ?? measure.split('volume')[1];
        return this.getDefineTransactionColor(splitValue);
    }

    getColorHoverLabelByTransactionMeasureKey(measure: string): string {
        let splitValue = measure.split('count')[1] ?? measure.split('volume')[1];
        return this.getDefineTransactionHoverColor(splitValue);
    }

    getDefineTransactionColor(value: string): string {
        switch (value.toLowerCase()) {
            case TransactionEnergyKeyType.BIOGNC:
                return this.BIOGNC_COLOR;
                break;
            case TransactionEnergyKeyType.ELEC:
                return this.ELEC_COLOR;
                break;
            case TransactionEnergyKeyType.GNC:
                return this.GNC_COLOR;
                break;
            case TransactionEnergyKeyType.H2:
                return this.H2_COLOR;
                break;
            case TransactionEnergyKeyType.GNL:
                return this.GNL_COLOR;
                break;
            case TransactionEnergyKeyType.GNCL:
                return this.GNCL_COLOR;
                break;
            case TransactionEnergyKeyType.GNC_L:
                return this.GNCL_COLOR;
                break;
            case TransactionEnergyKeyType.OTHER:
                return this.OTHER_COLOR;
                break;
            default:
                return this.DEFAULT_COLOR;
                break;
        }
    }

    getDefineTransactionHoverColor(value: string): string {
        switch (value.toLowerCase()) {
            case TransactionEnergyKeyType.BIOGNC:
                return this.BIOGNC_HOVER_COLOR;
                break;
            case TransactionEnergyKeyType.ELEC:
                return this.ELEC_HOVER_COLOR;
                break;
            case TransactionEnergyKeyType.GNC:
                return this.GNC_HOVER_COLOR;
                break;
            case TransactionEnergyKeyType.H2:
                return this.H2_HOVER_COLOR;
                break;
            case TransactionEnergyKeyType.GNL:
                return this.GNL_HOVER_COLOR;
                break;
            case TransactionEnergyKeyType.GNCL:
                return this.GNCL_HOVER_COLOR;
                break;
//            case 'gnc-l':
            case TransactionEnergyKeyType.GNC_L:
                return this.GNCL_HOVER_COLOR;
                break;
            case TransactionEnergyKeyType.OTHER:
                return this.OTHER_HOVER_COLOR;
                break;
            default:
                return this.DEFAULT_HOVER_COLOR;
                break;
        }
    }

    getColorLabelByCircularGraphType(energies: any[]): string[] {
        return energies.map((energy) => this.getDefineTransactionColor(energy));
    }

    getColorHoverLabelByCircularGraphType(energies: any[]): string[] {
        return energies.map((energy) => this.getDefineTransactionHoverColor(energy));
    }

    getColorHoverLabelByMeasureKey(measure: MeasureKeyType): string {
        let color = '';
        switch (measure.substring(measure.lastIndexOf('.') + 1)) {
            case MeasureKeyType.DISPONIBILITE_AVAILABLE_PC:
                color = this.DISPONIBILITE_AVAILABLE_PC_COLOR_HOVER;
                break;
            case MeasureKeyType.DISPONIBILITE_PARTIALLY_PC:
                color = this.DISPONIBILITE_PARTIALLY_PC_COLOR_HOVER;
                break;
            case MeasureKeyType.DISPONIBILITE_UNKNOWN_PC:
                color = this.DISPONIBILITE_UNKNOWN_PC_COLOR_HOVER;
                break;
            case MeasureKeyType.DISPONIBILITE_UNVAILABLE_PC:
                color = this.DISPONIBILITE_UNVAILABLE_PC_COLOR_HOVER;
                break;
            default:
                color = this.DISPONIBILITE_UNDEFINED_PC_COLOR_HOVER;
                break;
        }
        return color;
    }

    getColorLabelByGraphType(graphType: GraphType, measureDispo?: MeasureKeyType, measureTransaction?: string): string {
        switch (graphType) {
            case GraphType.STATION_AVAILABILITY:
                return this.getColorLabelByDisponibilityMeasureKey(measureDispo);
            case GraphType.TRANSACTION:
                return this.getColorLabelByTransactionMeasureKey(measureTransaction);
            case GraphType.CONNECTIVITY_STATION:
                return this.getColorLabelByConnectivityMeasureKey(measureTransaction, graphType);
            case GraphType.CONNECTIVITY_FTP:
                return this.getColorLabelByConnectivityMeasureKey(measureTransaction, graphType);
            case GraphType.STATION_ALARM:
            default:
                break;
        }
    }

    getHoverColorLabelByGraphType(graphType: GraphType, measureDispo?: MeasureKeyType, measureTransaction?: string): string {
        switch (graphType) {
            case GraphType.STATION_AVAILABILITY:
                return this.getColorHoverLabelByMeasureKey(measureDispo);
            case GraphType.TRANSACTION:
                return this.getColorHoverLabelByTransactionMeasureKey(measureTransaction);
            case GraphType.CONNECTIVITY_STATION:
                return this.getColorHoverLabelByConnectivityMeasureKey(measureTransaction, graphType);
            case GraphType.CONNECTIVITY_FTP:
                return this.getColorHoverLabelByConnectivityMeasureKey(measureTransaction, graphType);
            case GraphType.STATION_ALARM:
            default:
                break;
        }
    }

    hasDefinedColors(graphType: GraphType): boolean {
        switch (graphType) {
            case GraphType.STATION_AVAILABILITY:
                return true;
            case GraphType.TRANSACTION:
                return true;
            case GraphType.CONNECTIVITY_STATION:
                return true;
            case GraphType.CONNECTIVITY_FTP:
                return true;
            case GraphType.STATION_ALARM:
                return false;
            default:
                break;
        }
    }

    hasDefinedHoverColors(graphType: GraphType): boolean {
        switch (graphType) {
            case GraphType.STATION_AVAILABILITY:
                return true;
            case GraphType.TRANSACTION:
                return true;
            case GraphType.CONNECTIVITY_STATION:
                return true;
            case GraphType.STATION_ALARM:
                return false;
            default:
                break;
        }
    }
}
