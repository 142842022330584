export enum TransactionEnergyKeyType {
    GNC = 'gnc',
    BIOGNC = 'biognc',
    GNCL = 'gncl',
    GNC_L = 'gnc-l',
    H2 = 'h2',
    ELEC = 'elec',
    GNL = 'gnl',
    OTHER = 'other'
}
