import { NetworkSummary } from "src/swagger-gen/web-secured/models";

export class UserAllowedNetworkModel {

    uid: string;
    name: string;

    constructor(res: NetworkSummary) {
        this.uid = res.uid;
        this.name = res.name || null;
    }

    public static getListFromApi(res: NetworkSummary[]): UserAllowedNetworkModel[] {
        const list: UserAllowedNetworkModel[] = [];
        if (res) {
            res.forEach(r => {
                list.push(new UserAllowedNetworkModel(r));
            });
        }

        return list;
    }
}
