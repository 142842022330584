/* tslint:disable */

/**
 * state of notification:
 *   * ENABLED
 *   * DISABLED
 */
type ConfiguredMessageState =
  'ENABLED' |
  'DISABLED';
module ConfiguredMessageState {
  export const ENABLED: ConfiguredMessageState = 'ENABLED';
  export const DISABLED: ConfiguredMessageState = 'DISABLED';
  export function values(): ConfiguredMessageState[] {
    return [
      ENABLED,
      DISABLED
    ];
  }
}

export { ConfiguredMessageState }