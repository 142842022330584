/* tslint:disable */

/**
 * The role types:
 *   * TECHNICAL : role for technical users
 *   * MANAGEMENT : role for management users
 *   * CLIENT : role for management on the client's side
 */
type RoleType =
  'TECHNICAL' |
  'MANAGEMENT' |
  'CLIENT';
module RoleType {
  export const TECHNICAL: RoleType = 'TECHNICAL';
  export const MANAGEMENT: RoleType = 'MANAGEMENT';
  export const CLIENT: RoleType = 'CLIENT';
  export function values(): RoleType[] {
    return [
      TECHNICAL,
      MANAGEMENT,
      CLIENT
    ];
  }
}

export { RoleType }